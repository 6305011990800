import React, { ReactElement, useEffect, useRef, useState, VFC } from 'react';
import { uid } from 'react-uid';
import { QtyAllocation, sortLetterTypeList } from 'order/common/context/order/dtos/Order';
import { QtyAllocation as GeneratedQtyAllocation } from 'generated';
import { OrderOverviewRow, OrderOverviewWidget } from '../../../../common/components/molecule/OrderOverview';
import { useTranslation } from 'i18n';
import { LetterTypes } from 'order/common/components/QtyAllocation/dtos/LetterTypes';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { determineLetterTypeByFormat } from 'order/common/components/QtyAllocation/helper/determineLetterTypeByFormat';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import tableStyles from '../../../../../orderSearch/components/table/AMTable.module.css';
import classNames from 'classnames';
import { useBoolean } from '../../../../../../common/hooks/useBoolean';
import { AllocatedOrder } from '../../../../../common/context/order/dtos/AllocatedOrder';
import Toggle from '../../../../../../common/components/Toggle';
import { Spinner } from '../../../../../common/components/Spinner';
import styles from './ShipmentQuantityFeeReductionFourColumn.module.css';
import { useGenericRecommendations } from '../../../../../../common/hooks/useGenericRecommendations';
import { calLetterDiscountsWithTypeShipmentFeeReduction } from '../../../../../common/exports/utils/calLetterDiscountsShipmentFeeReduction';

export type ShipmentQuantityFeeReductionFourColumnProps = {
  qtyAlloc: QtyAllocation[];
  loaded?: boolean;
  allocationsLoading: boolean;
  allocationsOrders: AllocatedOrder[];
};
let detailedView: boolean;
export const ShipmentQuantityFeeReductionFourColumn: VFC<ShipmentQuantityFeeReductionFourColumnProps> = ({
  qtyAlloc,
  loaded,
  allocationsLoading,
  allocationsOrders
}) => {
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step4ShipmentFeeReduction' });
  const [calculated, setCalculated] = useState(false);
  const [childRows, setChildRows] = useState<ReactElement[]>([]);
  const totalPartialServiceDiscount = useRef(0);
  const totalIdDiscount = useRef(0);
  const totalTermDiscount = useRef(0);
  const totalSum = useRef(0);
  const lang = useAuthContext().user.language;
  const { allocationsCount, allocationsTotal } = useOrderContext();
  const [isDetailedView, setDetailedView] = useBoolean(false);
  const { recommendations, load, add } = useGenericRecommendations('reduction-table-expanded');

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (recommendations[0]) {
      setDetailedView.on();
      detailedView = true;
    } else {
      setDetailedView.off();
      detailedView = false;
    }
  }, [recommendations.toString()]);

  useEffect(() => {
    if (loaded && !isDetailedView) {
      const calcQty = {
        [LetterTypes.Standard]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 },
        [LetterTypes.Compact]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 },
        [LetterTypes.Large]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 },
        [LetterTypes.Maxi]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 },
        [LetterTypes.Postcard]: { partialServiceDiscount: 0, idDiscount: 0, termDiscount: 0, sum: 0 }
      };

      for (const q of qtyAlloc) {
        if (determineLetterTypeByFormat(q.format)) {
          if (!q.containsIds && !q.flexOption) {
            calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].partialServiceDiscount =
              calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].partialServiceDiscount + q.quantity;
            calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum =
              calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum + q.quantity;
          }
          if (q.containsIds && q.flexOption) {
            calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].idDiscount =
              calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].idDiscount + q.quantity;
            calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum =
              calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum + q.quantity;
          }
          if (q.containsIds && !q.flexOption) {
            calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].termDiscount =
              calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].termDiscount + q.quantity;
            calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum =
              calcQty[determineLetterTypeByFormat(q.format) as LetterTypes].sum + q.quantity;
          }
        }
      }
      const rows: ReactElement[] = [];
      let internalTotalPartialServiceDiscount = 0;
      let internalTotalIdDiscount = 0;
      let internalTotalTermDiscount = 0;
      let internalTotalSum = 0;
      Object.entries(calcQty).forEach(([key, value], id) => {
        internalTotalPartialServiceDiscount = internalTotalPartialServiceDiscount + value.partialServiceDiscount;
        internalTotalIdDiscount = internalTotalIdDiscount + value.idDiscount;
        internalTotalTermDiscount = internalTotalTermDiscount + value.termDiscount;
        internalTotalSum = internalTotalSum + value.sum;
        const generatedID = uid(`${id}-${'shipmentFeeReductionChildRows'}`);
        if (value.partialServiceDiscount || value.idDiscount || value.termDiscount)
          rows.push(
            <tr key={generatedID} style={{ lineHeight: '24px' }}>
              <td className={styles.borderRight}>{oc(key)}</td>
              <td className={classNames(styles.borderRight, styles.textRight)}>{value.partialServiceDiscount.toLocaleString(lang)}</td>
              <td className={classNames(styles.borderRight, styles.textRight)}>{value.idDiscount.toLocaleString(lang)}</td>
              <td className={classNames(styles.borderRight, styles.textRight)}>{value.termDiscount.toLocaleString(lang)}</td>
              <td className={styles.textRight}>{value.sum.toLocaleString(lang)}</td>
            </tr>
          );
      });
      totalPartialServiceDiscount.current = internalTotalPartialServiceDiscount;
      totalIdDiscount.current = internalTotalIdDiscount;
      totalTermDiscount.current = internalTotalTermDiscount;
      totalSum.current = internalTotalSum;
      setChildRows(rows);
      setCalculated(true);
    }
  }, [loaded, qtyAlloc, isDetailedView]);
  useEffect(() => {
    if (!allocationsLoading && isDetailedView) {
      const qtyAllocations = qtyAlloc?.flatMap((o) => {
        const matchedProd = allocationsOrders?.find((ea) => ea?.data?.orderNumber === o?.orderId);
        const orderPostageType = matchedProd?.data?.postage?.type;
        const prodNum = matchedProd?.data.qtyAllocation?.find((qt) => qt.refShipmentId === o.refShipmentId)?.productNumber;
        const desc = matchedProd?.data?.letterBaseProducts?.find((a) => o.refShipmentId.toString() == a.baseProduct?.referenceShipmentId?.toString())
          ?.baseProduct?.description;
        return { ...o, postageType: orderPostageType, productNumber: prodNum, description: desc } as unknown as GeneratedQtyAllocation;
      });
      const discounts = calLetterDiscountsWithTypeShipmentFeeReduction(qtyAllocations);
      let discountsToArray = Object.entries(discounts);
      const sortLetterBaseProducts = sortLetterTypeList.map((item) => item.toLowerCase());
      discountsToArray = discountsToArray.sort(
        (a, b) => sortLetterBaseProducts.indexOf(a[0].toLowerCase()) - sortLetterBaseProducts.indexOf(b[0].toLowerCase())
      );
      const rows: ReactElement[] = [];
      discountsToArray.forEach((disc: any, id) => {
        if (disc[1].total !== 0 && disc[0] !== 'totals') {
          const generatedID = uid(`${id}-${'shipmentFeeReductionChildRows'}`);
          rows.push(
            <tr key={generatedID} style={{ lineHeight: '24px' }}>
              <td colSpan={2} className={styles.borderRight}>
                {disc[0]}
              </td>
              <td colSpan={2} className={classNames(styles.borderRight, styles.textRight)}>
                <tr style={{ display: 'flex' }}>
                  <td className={styles.alignDvColumns}>{disc[1].dvFFQty.toLocaleString(lang)}</td>
                  <td className={styles.alignAfmColumns}>{disc[1].afmFFQty.toLocaleString(lang)}</td>
                </tr>
              </td>
              <td colSpan={2} className={classNames(styles.borderRight, styles.textRight)}>
                <tr style={{ display: 'flex' }}>
                  <td className={styles.alignDvColumns}>{disc[1].dvTTQty.toLocaleString(lang)}</td>
                  <td className={styles.alignAfmColumns}>{disc[1].afmTTQty.toLocaleString(lang)}</td>
                </tr>
              </td>
              <td colSpan={2} className={classNames(styles.borderRight, styles.textRight)}>
                <tr style={{ display: 'flex' }}>
                  <td className={styles.alignDvColumns}>{disc[1].dvTFQty.toLocaleString(lang)}</td>
                  <td className={styles.alignAfmColumns}>{disc[1].afmTFQty.toLocaleString(lang)}</td>
                </tr>
              </td>
              <td colSpan={2} className={styles.textRight}>
                {disc[1].total.toLocaleString(lang)}
              </td>
            </tr>
          );
        } else if (disc[0] === 'totals' && disc[1].total !== 0) {
          const generatedID = uid(`${id}-${'shipmentFeeReductionChildRows'}`);
          rows.push(
            <tr key={generatedID} style={{ lineHeight: '24px' }}>
              <th colSpan={2} className={classNames(styles.borderRight, styles.borderBottom)}>
                {oc(disc[0])}
              </th>
              <th colSpan={2} className={classNames(styles.borderRight, styles.borderBottom, styles.textRight)}>
                <tr style={{ display: 'flex' }}>
                  <td className={styles.alignDvColumns}>{disc[1].dvFFQty.toLocaleString(lang)}</td>
                  <td className={styles.alignAfmColumns}>{disc[1].afmFFQty.toLocaleString(lang)}</td>
                </tr>
              </th>
              <th colSpan={2} className={classNames(styles.borderRight, styles.borderBottom, styles.textRight)}>
                <tr style={{ display: 'flex' }}>
                  <td className={styles.alignDvColumns}>{disc[1].dvTTQty.toLocaleString(lang)}</td>
                  <td className={styles.alignAfmColumns}>{disc[1].afmTTQty.toLocaleString(lang)}</td>
                </tr>
              </th>
              <th colSpan={2} className={classNames(styles.borderRight, styles.borderBottom, styles.textRight)}>
                <tr style={{ display: 'flex' }}>
                  <td className={styles.alignDvColumns}>{disc[1].dvTFQty.toLocaleString(lang)}</td>
                  <td className={styles.alignAfmColumns}>{disc[1].afmTFQty.toLocaleString(lang)}</td>
                </tr>
              </th>
              <th colSpan={2} className={classNames(styles.borderBottom, styles.textRight)}>
                {disc[1].total.toLocaleString(lang)}
              </th>
            </tr>
          );
        }
      });
      setChildRows(rows);
      setCalculated(true);
    }
  }, [allocationsLoading, isDetailedView]);

  return (
    <OrderOverviewRow>
      <OrderOverviewWidget width={3} title={oc('SQPartialServiceDiscount')}>
        <section style={{ padding: '15px' }}>
          <span>Detailansicht</span>
          <Toggle
            style={{ margin: '0 15px', verticalAlign: 'bottom' }}
            data-testid="toggle"
            variant="xs"
            onChange={(e) => {
              add(!detailedView);
            }}
            checked={isDetailedView}
          />
        </section>

        {loaded && calculated ? (
          <table className={'dhlTable table '}>
            <thead className={classNames('dhlTable-sort-header ' + tableStyles.headcellalign)}>
              {isDetailedView ? (
                <tr style={{ lineHeight: '24px' }}>
                  <th colSpan={2} className={styles.borderRight}>
                    {oc('product')}
                  </th>
                  <th colSpan={2} className={classNames(styles.borderRight, styles.textRight)} style={{ padding: '15px 10px 0 0' }}>
                    <th style={{ padding: '10px', display: 'block' }}>{oc('SQPartialDiscountWithoutFrankingID')}</th>
                    <tr className={styles.borderTop}>
                      <th className={classNames(styles.textRight, styles.alignDvHeader)}>DV-Freimachung</th>
                      <th className={styles.textRight}>Frankiermaschinen</th>
                    </tr>
                  </th>
                  <th colSpan={2} className={classNames(styles.borderRight, styles.textRight)} style={{ padding: '15px 10px 0 0' }}>
                    <th style={{ padding: '10px', display: 'block' }}>{oc('SQFrankingIDAndFlexDiscount')}</th>
                    <tr className={styles.borderTop}>
                      <th className={classNames(styles.textRight, styles.alignDvHeader)}>DV-Freimachung</th>
                      <th className={styles.textRight}>Frankiermaschinen</th>
                    </tr>
                  </th>
                  <th colSpan={2} className={classNames(styles.borderRight, styles.textRight)} style={{ padding: '15px 10px 0 0' }}>
                    <th style={{ padding: '10px', display: 'block' }}>{oc('SQPartialDiscountFastWithFrankingID')}</th>
                    <tr className={styles.borderTop}>
                      <th className={classNames(styles.textRight, styles.alignDvHeader)}>DV-Freimachung</th>
                      <th className={styles.textRight}>Frankiermaschinen</th>
                    </tr>
                  </th>
                  <th colSpan={2} style={{ textAlign: 'right' }}>
                    {oc('totalShipmentQtyOfPartialDiscount')}
                  </th>
                </tr>
              ) : (
                <tr style={{ lineHeight: '24px' }}>
                  <th className={styles.borderRight}>{oc('format')}</th>
                  <th className={classNames(styles.borderRight, styles.textRight)}>
                    {oc('SQPartialDiscountWithoutFrankingID')
                      .split('\n')
                      .map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                  </th>
                  <th className={classNames(styles.borderRight, styles.textRight)}>
                    {oc('SQFrankingIDAndFlexDiscount')
                      .split('\n')
                      .map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                  </th>
                  <th className={classNames(styles.borderRight, styles.textRight)}>
                    {' '}
                    {oc('SQPartialDiscountFastWithFrankingID')
                      .split('\n')
                      .map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                  </th>
                  <th className={styles.textRight}>{oc('totalShipmentQtyOfPartialDiscount')}</th>
                </tr>
              )}
            </thead>
            <tbody>
              {isDetailedView && allocationsLoading ? (
                <div style={{ padding: '50% 400%' }}>
                  <Spinner className={styles.spinner} testid="spinnerTestId" />
                </div>
              ) : (
                childRows
              )}
            </tbody>
            <tfoot>
              {isDetailedView ? (
                ''
              ) : (
                <tr className={styles.borderBottom} style={{ lineHeight: '24px' }}>
                  <th className={styles.borderRight}>{oc('total')}</th>
                  <th className={classNames(styles.borderRight, styles.textRight)}>{totalPartialServiceDiscount.current.toLocaleString(lang)}</th>
                  <th className={classNames(styles.borderRight, styles.textRight)}>{totalIdDiscount.current.toLocaleString(lang)}</th>
                  <th className={classNames(styles.borderRight, styles.textRight)}>{totalTermDiscount.current.toLocaleString(lang)}</th>
                  <th className={styles.textRight}>{totalSum.current.toLocaleString(lang)}</th>
                </tr>
              )}
            </tfoot>
          </table>
        ) : (
          <div style={{ textAlign: 'center' }}>
            {oc('calculating')}
            <div>{`${Math.ceil((allocationsCount / allocationsTotal) * 100)}% ${oc('loaded')}`}</div>
          </div>
        )}
      </OrderOverviewWidget>
    </OrderOverviewRow>
  );
};
