/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  DHLDialogNonModal,
  DHLHelpButton,
  DHLMessages,
  DHLModal,
  DHLPagetemplate,
  DHLProgressBar,
  logger,
  trackPageCall,
  useResourcesAndValidations
} from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ResponseplusOrderStore } from "../../../stores/ResponseplusOrderStore";
import { ProfileSelectBox } from "../../common/ProfileSelectBox";
import { ResponseplusEmptyPage } from "./EmptyPage/ResponseplusEmptyPage";
import { OrderDataActionBar } from "./Page1-OrderData/OrderDataActionBar";
import { OrderPage1ContentData } from "./Page1-OrderData/OrderPage1ContentData";
import { OrderDetailsActionBar } from "./Page2-OrderDetails/OrderDetailsActionBar";
import { OrderPage2ContentDetails } from "./Page2-OrderDetails/OrderPage2ContentDetails";
import { OrderConfirmActionbar } from "./Page3-OrderConfirm/OrderConfirmActionbar";
import { OrderConfirmPage } from "./Page3-OrderConfirm/OrderConfirmPage";
import "./ResponseplusOrder.scss";
import { ResponseplusRightContent } from "./ResponseplusRightContent";

export type ResponseplusProps = {
  responseplusOrderStore: ResponseplusOrderStore;
  redirectBack: () => void;
}

const LOG_MODULE = "ResponseplusOrder";

export const ResponseplusOrder = observer(({responseplusOrderStore: dataStore, redirectBack}: ResponseplusProps) => {
  const {resourceDataStore, validationRuleDataStore} = dataStore.baseStore;
  const isMultipleEkp: boolean = dataStore.profileSelectStore.availableProfiles.length > 1;
  const noResponseplusProfiles = dataStore.profileSelectStore.profilesLoaded && dataStore.profileSelectStore.customerProfiles.length === 0;

  useEffect(() => {
    if (!resourceDataStore.isLoading) {
      dataStore.loadData().catch(err => logger.log(LOG_MODULE, err));
    }
  }, [dataStore, resourceDataStore.isLoading]);

  useEffect(() => {
    dataStore.selectProfile();
  }, [dataStore, dataStore.profileSelectStore.selectedProfile]);

  useResourcesAndValidations(resourceDataStore, validationRuleDataStore);

  useEffect(() => trackPageCall("Brief:Responseplus:Responseplus beauftragen"), []);

  if (dataStore.isLoading) {
    return null;
  }

  const helpButton = <DHLHelpButton
      url={resourceDataStore.getHelp(dataStore.getHelpFromActivePageIndex(isMultipleEkp))}
      tooltipText={resourceDataStore.getTooltip("helpButton")}
      type={"pagetemplateWithHeaderbutton"}
  />;

  if (noResponseplusProfiles) {
    return (
        <DHLPagetemplate
            headlineText={resourceDataStore.getLabel("order.headline")}
            headerButtons={helpButton}
            dhlMessage={
              <DHLMessages
                  msgStore={dataStore.baseStore.messageDataStore}
                  name="messages" />
            }>
          <></>
        </DHLPagetemplate>
    );
  }

  const rightContent = <ResponseplusRightContent responseplusOrderStore={dataStore} />;

  const page1Actionbar = <OrderDataActionBar dataStore={dataStore} />;
  const page2Actionbar = <OrderDetailsActionBar dataStore={dataStore} />;
  const page3Actionbar = <OrderConfirmActionbar orderStore={dataStore} />;

  function renderActionbarForPage(page: number) {
    switch (page) {
      case 0:
        return page1Actionbar;
      case 1:
        return page2Actionbar;
      case 2:
        return page3Actionbar;
      default:
        return <></>;
    }
  }

  function renderContentForPage(page: number) {
    const emptyPage = (
        <ResponseplusEmptyPage
            loading={dataStore.orderLoading || dataStore.customerOrderIdListLoading || dataStore.contractIdValidationInProgress}
            localizationPack={resourceDataStore.localizationPack} />
    );
    if (
        !dataStore.profileSelectStore.selectedProfile ||
        !dataStore.contractIdValid ||
        dataStore.contractIdValidationInProgress ||
        dataStore.orderLoading ||
        dataStore.customerOrderIdListLoading
    ) {
      return emptyPage;
    }
    switch (page) {
      case 0:
        return <OrderPage1ContentData rightContent={rightContent} orderStore={dataStore} />;
      case 1:
        return <OrderPage2ContentDetails rightContent={rightContent} orderStore={dataStore} />;
      case 2:
        return <OrderConfirmPage orderStore={dataStore} />;
      default:
        return emptyPage;
    }
  }

  const responseplusOrderProgressBar =
      <DHLProgressBar
          steps={[
            {label: resourceDataStore.getLabel("order.step.page1-orderData")},
            {label: resourceDataStore.getLabel("order.step.page2-orderDetails")},
            {label: resourceDataStore.getLabel("order.step.page3-orderConfirm")}
          ]}
          activeIndex={dataStore.activePageIndex}
      />;

  const profileSelectBox = (
      <ProfileSelectBox
          profileSelectStore={dataStore.profileSelectStore}
          resourceDataStore={resourceDataStore} />
  );

  return (
      <DHLPagetemplate
          headlineText={resourceDataStore.getLabel("order.headline")}
          sublineText={resourceDataStore.getLabel("order.sublineText")}
          headerButtons={helpButton}
          topLeftItems={profileSelectBox}
          collapsibleSubline
          className={"responseplus-order-page"}
          sublineHideText={resourceDataStore.getLabel("order.sublineHide")}
          dhlMessage={
            <DHLMessages
                msgStore={dataStore.baseStore.messageDataStore}
                name="messages" />
          }
          progressBar={responseplusOrderProgressBar}>
        <DHLDialogNonModal actionbar={renderActionbarForPage(dataStore.activePageIndex)}>
          {renderContentForPage(dataStore.activePageIndex)}
          <DHLModal
              name="confirm-cancel"
              show={dataStore.showCancelDialog}
              title={resourceDataStore.getLabel("order.confirmCancel.title")}
              message={resourceDataStore.getLabel("order.confirmCancel.msg")}
              okButton={resourceDataStore.getButton("order.confirmCancel.yes")}
              cancelButton={resourceDataStore.getButton("order.confirmCancel.no")}
              onClickOk={() => {
                dataStore.confirmCancelOrder();
                redirectBack();
              }
              }
              onClickClose={() => dataStore.closeCancelOrder()}
              onClickCancel={() => dataStore.closeCancelOrder()}
          />
        </DHLDialogNonModal>
      </DHLPagetemplate>
  );
});