/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
import {
  DHLButton,
  DHLFormGroup,
  DHLHeadline,
  DHLIcon,
  DHLTextOutput,
  ResourceDataStore
} from "@gkuis/gkp-base-widgets";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ResponseplusOrderDetailStore } from "../../../../stores/ResponseplusOrderDetailStore";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";
import { getTextOrDefaultLabel, getValue } from "./values";

const STATE_CANCELLED = 203;

export type OrderContactPersonPanelProps = {
  orderStore: ResponseplusOrderStore | ResponseplusOrderDetailStore;
}

export const OrderContactPersonPanel = observer(({orderStore}: OrderContactPersonPanelProps) => {
  const {resourceDataStore} = orderStore.baseStore;

  const contactActionsAllowed = orderStore instanceof ResponseplusOrderDetailStore &&
    orderStore.order?.state !== STATE_CANCELLED;

  const getOnAdd = () => {
    if (!contactActionsAllowed || orderStore.baseStore.secondContactIsActive) {
      return undefined;
    }
    return () => runInAction(() => {
      orderStore.baseStore.resetSecondContact();
      (orderStore as ResponseplusOrderDetailStore).showContactDialog = true;
      (orderStore as ResponseplusOrderDetailStore).contactDialogType = "addContact";
      (orderStore as ResponseplusOrderDetailStore).editingSecondContact = true;
    });
  };

  const getOnEditFirstContact = () => {
    if (!contactActionsAllowed) {
      return undefined;
    }
    return () => runInAction(() => {
      (orderStore as ResponseplusOrderDetailStore).showContactDialog = true;
      (orderStore as ResponseplusOrderDetailStore).contactDialogType = "editContact";
      (orderStore as ResponseplusOrderDetailStore).editingSecondContact = false;
    });
  };

  const getOnDeleteFirstContact = () => {
    if (!contactActionsAllowed || !orderStore.baseStore.secondContactIsActive) {
      return undefined;
    }
    return () => (orderStore as ResponseplusOrderDetailStore).deleteFirstAndFillSecondContact();
  };

  const getOnEditSecondContact = () => {
    if (!contactActionsAllowed) {
      return undefined;
    }
    return () => runInAction(() => {
      (orderStore as ResponseplusOrderDetailStore).showContactDialog = true;
      (orderStore as ResponseplusOrderDetailStore).contactDialogType = "editContact";
      (orderStore as ResponseplusOrderDetailStore).editingSecondContact = true;
    });
  };

  const getOnDeleteSecondContact = () => {
    if (!contactActionsAllowed || !orderStore.baseStore.secondContactIsActive) {
      return undefined;
    }
    return () => (orderStore as ResponseplusOrderDetailStore).deleteSecondContact();
  };

  const renderFirstContactPersonPanel = () => {
    return (
      <ContactPersonPanel
        resourceDataStore={resourceDataStore}
        firstName={getValue(orderStore.baseStore.nameFormField)}
        lastName={getValue(orderStore.baseStore.surNameFormField)}
        phone={getTextOrDefaultLabel(resourceDataStore, getValue(orderStore.baseStore.phoneFormField))}
        email={getValue(orderStore.baseStore.emailFormField)}
        onAdd={getOnAdd()}
        onEdit={getOnEditFirstContact()}
        onDelete={getOnDeleteFirstContact()} />
    );
  };

  const renderSecondContactPersonPanel = () => {
    if (!orderStore.baseStore.secondContactIsActive) {
      return null;
    }
    return (
      <ContactPersonPanel
        resourceDataStore={resourceDataStore}
        firstName={getValue(orderStore.baseStore.secondNameFormField)}
        lastName={getValue(orderStore.baseStore.secondSureNameFormField)}
        phone={getTextOrDefaultLabel(resourceDataStore, getValue(orderStore.baseStore.secondPhoneFormField))}
        email={getValue(orderStore.baseStore.secondEmailFormField)}
        onAdd={undefined}
        onEdit={getOnEditSecondContact()}
        onDelete={getOnDeleteSecondContact()} />
    );
  };
  return (
    <>
      {renderFirstContactPersonPanel()}
      {renderSecondContactPersonPanel()}
    </>
  );
});

type ContactPersonPanelProps = {
  resourceDataStore: ResourceDataStore
  firstName: string
  lastName: string
  phone: string
  email: string
  onAdd: (() => void) | undefined
  onEdit: (() => void) | undefined
  onDelete: (() => void) | undefined
}

const ContactPersonPanel = ({
                              resourceDataStore,
                              firstName,
                              lastName,
                              phone,
                              email,
                              onAdd,
                              onEdit,
                              onDelete,
                            }: ContactPersonPanelProps) => {
  const renderAddContact = () => {
    return onAdd && (
      <DHLButton
        icon={"plus"}
        label={resourceDataStore.getButton("order.page2-orderDetails.addContact")}
        onClick={onAdd}
        type={"ghost"}
        size={"xs"}
        iconPosition={"icon-first"}
        name={"addContactButton"} />
    );
  };

  const renderContactEditIcon = () => {
    return onEdit && (
      <DHLIcon
        name={"editIcon"}
        icon={"edit"}
        className={"deleteIcon"}
        onClick={onEdit} />
    );
  };

  const renderContactDeleteIcon = () => {
    return onDelete && (
      <DHLIcon
        name={"deleteIcon"}
        icon={"delete"}
        onClick={onDelete}
        className={"deleteIcon"} />
    );
  };

  const phoneLabel = resourceDataStore.getLabel("order.page3-orderconfirm.contactPerson.phone");
  const emailLabel = resourceDataStore.getLabel("order.page3-orderconfirm.contactPerson.email");
  const nameText = `${firstName} ${lastName}`;
  const phoneText = `${phoneLabel} ${phone}`;
  const emailText = `${emailLabel} ${email}`;

  return (
    <DHLFormGroup>
      <DHLFormGroup>
        <div className={"contactPersonWithDeleteIcon"}>
          <DHLHeadline type={"h6"}>
            {resourceDataStore.getLabel("order.page3-orderconfirm.contactPerson")}
          </DHLHeadline>
          {renderContactEditIcon()}
          {renderContactDeleteIcon()}
        </div>
      </DHLFormGroup>
      <DHLFormGroup>
        <DHLTextOutput value={nameText} />
        <DHLTextOutput value={phoneText} />
        <DHLTextOutput value={emailText} />
        {renderAddContact()}
      </DHLFormGroup>
    </DHLFormGroup>
  );
};