import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import { transformResponse } from './utils/helpers';

export interface Call {
  url: string;
  method: Method;
  headers?: Record<string, unknown>;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  token?: string;
  extUserId?: string;
  controller?: AbortController;
}

export async function sendRequest<T>(call: Call): Promise<AxiosResponse> {
  let url = call.url;

  if (!call.token) {
    return Promise.reject(new Error('Invalid token.'));
  }

  if (call.params) {
    const { params } = call;
    let i = 0;

    url = `${url}?`;
    for (const key in params) {
      if (params[key]) {
        if (i > 0) {
          url = `${url}&`;
        }
        url = `${url}${key}=${params[key]}`;
        i++;
      }
    }
  }

  let headers: { [key: string]: string } = {
    ...call.headers,
    Pragma: 'no-cache',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  };

  if (call.token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${call.token}`,
      ...(call.extUserId && { 'X-ExtUserId': call.extUserId })
    };
  }

  let options: AxiosRequestConfig = {
    url,
    method: call.method as Method,
    // timeout: 1000,
    headers,
    responseType: 'json',
    ...(call.controller && { signal: call.controller.signal })
  };

  if (call.data) {
    options = {
      ...options,
      data: call.data
    };
  }

  options = {
    ...options
    // transformResponse
  };

  try {
    return await axios(options);
  } catch (e: any) {
    const error: any = new Error(`Error during request: ${e}`);

    error.response = e.response;
    throw error;
  }
}
