import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';

import { useOrderContext } from '../../../context/order/OrderContext';
import { OrderReference } from '../../../context/order/dtos/OrderReference';
import { determineLetterTypeByFormatCount } from '../../QtyAllocation/helper/determineLetterTypeByFormat';

interface Props {
  value: string;
  column: ColumnConfig;
  row: AllocatedOrder;
  meta?: CellMetaData;
}

export const CustomPartialServiceProductsCell: React.VFC<Props> = (props): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });
  const { orderReferences, allocationsOrders } = useOrderContext();
  const [orderReference, setOrderReference] = useState<OrderReference | null>(null);

  useLayoutEffect(() => {
    const ref = orderReferences.find((o) => o.orderId === props.row.data.orderNumber);

    if (ref) {
      setOrderReference(ref);
    }
  }, [orderReferences]);

  const getByOrderID = allocationsOrders.find((a) => a.data.orderNumber === orderReference?.orderId);

  const getFormatNames = getByOrderID?.data.letterBaseProducts?.map((a) => a.baseProduct?.description?.toString());
  const processedFormats = new Set<string>();

  return (
    <div>
      {orderReference?.qtyAllocation.map((q, i) => {
        if (!q.fromOriginal && !processedFormats.has(q.format)) {
          processedFormats.add(q.format);

          const letterType = determineLetterTypeByFormatCount(q.format, getFormatNames);
          return <div key={`partial-service-product-${q.productNumber}-${i}`}>{letterType}</div>;
        }
      })}
    </div>
  );
};

export const customPartialServiceProductsRenderer = (value: string, column: ColumnConfig, row: AllocatedOrder): JSX.Element | null => {
  return <CustomPartialServiceProductsCell value={value} row={row} column={column} />;
};
