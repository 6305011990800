import { OrderTreeItem } from '../../../services/OrderService';
import { ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { SortOrder } from 'common/components/Table/dtos/SortOrder';
import { extractObjectData } from './extractObjectData';
import i18n from 'i18n';
import { procedureCache, ProcedureTDO } from '../../../../../common/services/ProcedureService';
import { OrderSearchKey } from '../../search/dtos/OrderSearchKey';
import { deDateFormatter } from 'common/utils/helpers';

export function getCurrentSlice<T>(currentData: T[], currentPage: number, currentPageSize: number): T[] {
  let currentIndex = -1;
  let pageIndex = 0;
  if (currentPageSize === 0) {
    return currentData;
  }
  return currentData.filter((v) => {
    const increment = countAutoExpanded((v as any).children) + 1;
    if (currentIndex + increment >= currentPageSize) {
      pageIndex++;
      currentIndex = -1;
    }
    currentIndex += increment;
    return currentPage === pageIndex + 1;
  });
}

export function countAutoExpanded<T>(rows: OrderTreeItem<T>[] | undefined): number {
  return rows?.reduce((acc, { autoExpanded, children }) => acc + Number(!!autoExpanded) + countAutoExpanded(children), 0) || 0;
}

function visibleHeaders(columns: ColumnConfig[]): ColumnConfig[] {
  return columns.slice(0, -1).filter(({ hidden }) => !hidden);
}

function moveHeader(columns: ColumnConfig[], fromIndex: number, moveLeft: boolean): ColumnConfig[] {
  // check if column can be moved/sits on the edge
  if ((fromIndex <= 0 && moveLeft) || (fromIndex >= visibleHeaders(columns).length - 1 && !moveLeft)) {
    return columns;
  }

  // loop to jump over hidden columns
  let toIndex = fromIndex;
  do {
    toIndex += moveLeft ? -1 : 1;
  } while (columns[toIndex]?.hidden);

  const element = columns.splice(fromIndex, 1);
  columns.splice(toIndex, 0, element[0]);
  return columns;
}

function findColumnIndex(columns: ColumnConfig[], name: string): number {
  return columns.findIndex((c) => c.name === name);
}

export function moveColumnLeft(columns: ColumnConfig[], name: string): ColumnConfig[] {
  const index = findColumnIndex(columns, name);
  return moveHeader(columns, index, true);
}

export function moveColumnRight(columns: ColumnConfig[], name: string): ColumnConfig[] {
  const index = findColumnIndex(columns, name);
  return moveHeader(columns, index, false);
}

export function rowContainsFilter<T>(property: string, value: string, row: { data?: any; children?: any[] }): boolean {
  let matches = false;

  if (row.data && property in row.data) {
    let extractedValue = extractObjectData(row.data, property);

    if (property === 'procedure') {
      const procedureArray: ProcedureTDO[] = Array.from(procedureCache.values());
      const procedure = procedureArray.find((p) => p.name === extractedValue)?.id || '';
      if (procedure.includes(value)) {
        matches = true;
      }
    }

    if (property === 'state') {
      extractedValue = i18n.t('orderStatus.status.' + extractedValue) || extractedValue;
    }

    if (property === 'orderType') {
      extractedValue = i18n.t('orderTable.rowElement.orderType.' + extractedValue) || extractedValue;
    }
    if (property === 'frankierung') {
      extractedValue = i18n.t('orderFilter.postageType.display.' + extractedValue) || extractedValue;
    }
    if (property === 'product') {
      extractedValue = i18n.t('orderFilter.productList.types.' + extractedValue) || extractedValue;
    }
    if (property === 'packagingType') { 
      extractedValue = row?.data?.packagingType ? i18n.t('products.' + extractedValue) : extractedValue;        
    }
    
    if (extractedValue && extractedValue.toString().toLowerCase().includes(value.toLowerCase())) {
      matches = true;
    }
  }
  if (property === 'orderValue') {
    const extractedValue = row?.data?.totalAmountValue;
    if (extractedValue?.toString()?.toLowerCase()?.includes(value.toLowerCase())) {
      matches = true;
    }
  }
  if (property === 'deliveryDate') {
    const extractedValue = row?.data?.firstInductionDate;
    const deDate = deDateFormatter.format(new Date(extractedValue)).toString();
    if (extractedValue?.toString()?.toLowerCase()?.includes(value.toLowerCase())) {
      matches = true;
    } else if (deDate?.toLowerCase()?.includes(value.toLowerCase())) {
      matches = true;
    }
  }
  if (property === 'sender') {
    const sender = row?.data?.originator;
    const extractedValue = sender?.customerId + ' ' + sender?.name;
    if (extractedValue?.toString()?.toLowerCase()?.includes(value.toLowerCase())) {
      matches = true;
    }
  }
  if (property === 'deliverer') {
    const deliverer = row?.data.submitter;
    const extractedValue = deliverer?.customerId + ' ' + deliverer?.name;
    if (extractedValue?.toString()?.toLowerCase().includes(value.toLowerCase())) {
      matches = true;
    }
  }
  if (property === 'payer') {
    const payer = row?.data.payer;
    const extractedValue = payer?.customerId + ' ' + payer?.name;
    if (extractedValue.toString().toLowerCase().includes(value.toLowerCase())) {
      matches = true;
    }
  }
  if (property === 'producer') {
    const producer = row?.data.producer;
    const extractedValue = producer?.customerId + ' ' + producer?.name;
    if (extractedValue.toString().toLowerCase().includes(value.toLowerCase())) {
      matches = true;
    }
  }
  if (property === 'machineCodeOrFrankingIdPrefix') {
    const postage = row?.data?.machineCode || row?.data?.frankingIdPrefix;
    if (postage && postage.toString().toLowerCase().includes(value.toLowerCase())) {
      matches = true;
    }
  }
  if (property === 'beneficiary') {
    const beneficiary = row?.data.beneficiary;
    const extractedValue = beneficiary?.customerId + ' ' + beneficiary?.name;
    if (extractedValue.toString().toLowerCase().includes(value.toLowerCase())) {
      matches = true;
    }
  }
  if (property === 'state') {
    return matches;
  }
  if (matches) {
    return true;
  }
  return !!row.children?.some((childRow) => rowContainsFilter(property, value, childRow));
}

export function sortRows<T>(key: string | null, rows: T[], order: SortOrder | null, i = 0): T[] {
  if (key) {
    let searchKey = key;

    if (key === 'deliveryDate') {
      searchKey = 'firstInductionDate';
    }
    if (key === 'orderValue') {
      searchKey = 'totalAmountValue';
    }
    if (key === 'sender') {
      searchKey = 'originator';
    }
    if (key === 'deliverer') {
      searchKey = 'submitter';
    }
    const isCustomerSortable =
      searchKey === (OrderSearchKey.Originator || OrderSearchKey.Submitter || OrderSearchKey.Producer || OrderSearchKey.Beneficiary).toLowerCase();
    const sortedRows = rows.sort((a, b) => {
      const aValue = ((a as any).data as any)?.[searchKey];
      const bValue = ((b as any).data as any)?.[searchKey];
      if (order === SortOrder.DESC) {
        if (searchKey === 'frankierung') {
          return i18n.t('orderFilter.postageType.display.' + aValue) < i18n.t('orderFilter.postageType.display.' + bValue) ? 1 : -1;
        }
        if (searchKey === 'packagingType') {
          if (!aValue) return 1;
          if (!bValue) return -1;
          return i18n.t('products.' + aValue) < i18n.t('products.' + bValue) ? 1 : -1;
        }
        if (searchKey === 'state') {
          if (!aValue) return 1;
          if (!bValue) return -1;
          return i18n.t('orderStatus.status.' + aValue) < i18n.t('orderStatus.status.' + bValue) ? 1 : -1;
        }

        if (isCustomerSortable) {
          return aValue?.name < bValue?.name ? 1 : -1;
        }
        if (searchKey === 'paymentClearingNumber' || searchKey === 'payerProcedure' || searchKey === 'payerParticipation') {
          return parseInt(aValue) < parseInt(bValue) ? 1 : -1;
        }

        // Shipment table sorting functions
        if (searchKey === 'totalNumberOfFrankingId') {
          if (((a as any).data as any)?.quantity && ((b as any).data as any)?.quantity) {
            return +((a as any).data as any)?.quantity > +((b as any).data as any)?.quantity ? -1 : 1;
          }
          const aCount = ((a as any).data as any)?.to - ((a as any).data as any)?.from + 1;
          const bCount = ((b as any).data as any)?.to - ((b as any).data as any)?.from + 1;
          return aCount > bCount ? -1 : 1;
        }
        if (searchKey === 'shipmentFrom') {
          return ((a as any).data as any)?.from > ((b as any).data as any)?.from ? -1 : 1;
        }
        if (searchKey === 'shipmentTo') {
          return ((a as any).data as any)?.to > ((b as any).data as any)?.to ? -1 : 1;
        }
        if (searchKey === 'zipCode' || searchKey === 'zipCodeRight') {
          return ((a as any).data as any)?.zip > ((b as any).data as any)?.zip ? -1 : 1;
        }
        if (searchKey === 'shipmentQty') {
          return +((a as any).data as any)?.quantity > +((b as any).data as any)?.quantity ? -1 : 1;
        }
        if (searchKey === 'frankingIdPrefix' || searchKey === 'machineCode') {
          return ((a as any).data as any)?.frankingIdPrefix > ((b as any).data as any)?.frankingIdPrefix ? -1 : 1;
        }
        // Pallets overview table sorting functions
        if (searchKey === 'palletNumber') {
          return +((a as any).data as any)?.palletNumber > +((b as any).data as any)?.palletNumber ? -1 : 1;
        }
        if (searchKey === 'nve') {
          return BigInt(((a as any).data as any)?.nve) > BigInt(+((b as any).data as any)?.nve) ? -1 : 1;
        }
        if (searchKey === 'zipCodeRight') {
          return ((a as any).data as any)?.zipCode > ((b as any).data as any)?.zipCode ? -1 : 1;
        }
        if (searchKey === 'totalGrossWeight') {
          return ((a as any).data as any)?.totalGrossWeight > ((b as any).data as any)?.totalGrossWeight ? -1 : 1;
        }
        if (searchKey === 'totalNetWeightForPallet') {
          return ((a as any).data as any)?.totalNetWeight > ((b as any).data as any)?.totalNetWeight ? -1 : 1;
        }

        return aValue < bValue ? 1 : -1;
      }
      if (order === SortOrder.ASC) {
        if (searchKey === 'frankierung') {
          return i18n.t('orderFilter.postageType.display.' + aValue) > i18n.t('orderFilter.postageType.display.' + bValue) ? 1 : -1;
        }
        if (searchKey === 'packagingType') {
          if (!aValue) return 1;
          if (!bValue) return -1;
          return i18n.t('products.' + aValue) > i18n.t('products.' + bValue) ? 1 : -1;
        }
        if (searchKey === 'state') {
          if (!aValue) return 1;
          if (!bValue) return -1;
          return i18n.t('orderStatus.status.' + aValue) > i18n.t('orderStatus.status.' + bValue) ? 1 : -1;
        }
        if (isCustomerSortable) {
          return aValue?.name > bValue?.name ? 1 : -1;
        }
        if (searchKey === 'paymentClearingNumber' || searchKey === 'payerProcedure' || searchKey === 'payerParticipation') {
          return parseInt(aValue) > parseInt(bValue) ? 1 : -1;
        }

        // Shipment table sorting functions
        if (searchKey === 'totalNumberOfFrankingId') {
          if (((a as any).data as any)?.quantity && ((b as any).data as any)?.quantity) {
            return +((a as any).data as any)?.quantity > +((b as any).data as any)?.quantity ? 1 : -1;
          }
          const aCount = ((a as any).data as any)?.to - ((a as any).data as any)?.from + 1;
          const bCount = ((b as any).data as any)?.to - ((b as any).data as any)?.from + 1;
          return aCount > bCount ? 1 : -1;
        }
        if (searchKey === 'shipmentFrom') {
          return ((a as any).data as any)?.from > ((b as any).data as any)?.from ? 1 : -1;
        }
        if (searchKey === 'shipmentTo') {
          return ((a as any).data as any)?.to > ((b as any).data as any)?.to ? 1 : -1;
        }
        if (searchKey === 'shipmentQty') {
          return +((a as any).data as any)?.quantity > +((b as any).data as any)?.quantity ? 1 : -1;
        }
        if (searchKey === 'zipCode') {
          return ((a as any).data as any)?.zip > ((b as any).data as any)?.zip ? 1 : -1;
        }
        if (searchKey === 'frankingIdPrefix' || searchKey === 'machineCode') {
          return ((a as any).data as any)?.frankingIdPrefix > ((b as any).data as any)?.frankingIdPrefix ? 1 : -1;
        }

        // Pallets overview table sorting functions
        if (searchKey === 'palletNumber') {
          return +((a as any).data as any)?.palletNumber > +((b as any).data as any)?.palletNumber ? 1 : -1;
        }
        if (searchKey === 'nve') {
          return BigInt(((a as any).data as any)?.nve) > BigInt(+((b as any).data as any)?.nve) ? 1 : -1;
        }
        if (searchKey === 'zipCodeRight') {
          return ((a as any).data as any)?.zipCode > ((b as any).data as any)?.zipCode ? 1 : -1;
        }
        if (searchKey === 'totalGrossWeight') {
          return ((a as any).data as any)?.totalGrossWeight > ((b as any).data as any)?.totalGrossWeight ? 1 : -1;
        }
        if (searchKey === 'totalNetWeightForPallet') {
          return ((a as any).data as any)?.totalNetWeight > ((b as any).data as any)?.totalNetWeight ? 1 : -1;
        }

        return aValue > bValue ? 1 : -1;
      }
      return 0;
    });

    sortedRows.forEach((r) => {
      if ((r as any).children && (r as any).children.length) {
        (r as any).children = sortRows(searchKey, (r as any).children, order);
      }
    });

    return sortedRows as any;
  }
  console.warn('Sorting impossible due to missing key');
  return rows;
}
