import { QtyAllocation } from 'generated';
import { determineLetterTypeByProductNumber } from '../../components/QtyAllocation/helper/determineLetterTypeByProductNumber';

export const calLetterDiscountsWithTypeShipmentFeeReduction = (qtyAllocations: (QtyAllocation | undefined)[] | undefined) => {
  const calculateIndQty = (postageType: string, containIds: boolean, flexOption: boolean, prodNum: string | undefined) => {
    let qty = 0;
    qtyAllocations?.forEach((qa) => {
      if (
        (prodNum === 'all' || qa?.productNumber === prodNum) &&
        qa?.postageType?.toLowerCase() === postageType.toLowerCase() &&
        qa?.containsIds === containIds &&
        qa?.flexOption === flexOption
      )
        qty = qty + (qa?.quantity || 0);
    });
    return qty;
  };
  const calculateProdQty = (prodNum: string | undefined) => {
    let qty = 0;
    qtyAllocations?.forEach((qa) => {
      if (prodNum === 'all' || qa?.productNumber === prodNum) qty = qty + (qa?.quantity || 0);
    });
    return qty;
  };
  let letterDiscountsWithType = {};
  qtyAllocations?.forEach((qa) => {
    letterDiscountsWithType = Object.assign(letterDiscountsWithType, {
      // [determineLetterTypeByProductNumber(qa?.productNumber)]: {
      [qa?.description || determineLetterTypeByProductNumber(qa?.productNumber)]: {
        dvFFQty: calculateIndQty('DV', false, false, qa?.productNumber),
        afmFFQty: calculateIndQty('AFM', false, false, qa?.productNumber),
        dvTFQty: calculateIndQty('DV', true, false, qa?.productNumber),
        afmTFQty: calculateIndQty('AFM', true, false, qa?.productNumber),
        dvTTQty: calculateIndQty('DV', true, true, qa?.productNumber),
        afmTTQty: calculateIndQty('AFM', true, true, qa?.productNumber),
        total: calculateProdQty(qa?.productNumber)
      }
    });
  });
  letterDiscountsWithType = Object.assign(letterDiscountsWithType, {
    ['totals']: {
      dvFFQty: calculateIndQty('DV', false, false, 'all'),
      dvTFQty: calculateIndQty('DV', true, false, 'all'),
      dvTTQty: calculateIndQty('DV', true, true, 'all'),
      afmFFQty: calculateIndQty('AFM', false, false, 'all'),
      afmTFQty: calculateIndQty('AFM', true, false, 'all'),
      afmTTQty: calculateIndQty('AFM', true, true, 'all'),
      total: calculateProdQty('all')
    }
  });
  return letterDiscountsWithType;
};
