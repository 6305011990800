import { useTranslation } from 'react-i18next';
import { Flex, Grid, List, ListItem, Text, Paper } from '@mantine/core';
import { RadioButtonCard } from '../components/RadioButtonCard/RadioButtonCard';
import { useFormContext } from '../hooks/useFormContext';
import { LetterWithoutWindowGross } from '../components/Icons/LetterWithoutWindowGross';
import { LetterWithWindowGross } from '../components/Icons/LetterWithWindowGross';
import { LetterWithoutEnvelope } from '../components/Icons/LetterWIthoutEnvelope';
import { useLetterType } from '../hooks/useLetterType';
import { letterWindowType } from 'src/api/requests';
import { LetterWithSeparator } from '../components/Icons/LetterWIthSeparator';
import { LetterWithWindowStandard } from '../components/Icons/LetterWithWindowStandard';
import { LetterWithoutWindowStandard } from '../components/Icons/LetterWithoutWindowStandard';
import { InfoIcon } from '../components/Icons/InfoIcon';
import { ImportantInstructions } from '../components/ImportantInstructions/ImportantInstructions';
import { ButtonBar } from '../components/Buttons/ButtonBar';
import { useUpdateProject } from '../hooks/useUpdateProject';
import { useEffect } from 'react';
import { useGoToDashboard } from '../hooks/useGoToDashboard';
import { useNavigateWithSearchParams } from '../hooks/useNavigateWithSearchParams';
import { useTranslatedListItems } from '../hooks/useTranslatedListItems';

export const ImportantInstructionsStepTwo = ({
  letterWindowType,
  isLargeLetter,
}: {
  letterWindowType: letterWindowType | null;
  isLargeLetter: boolean;
}) => {
  const { t } = useTranslation();
  const size = isLargeLetter ? 'big' : 'small';

  const listItems = useTranslatedListItems(`step-two.important-instructions.${size}.${letterWindowType}.list`);

  if (!letterWindowType) {
    return null;
  }

  return (
    <Flex direction="column" gap={10}>
      <ImportantInstructions
        infoText={t(`step-two.important-instructions.${size}.${letterWindowType}.title`)}
        listItems={listItems.map((value) => ({ details: value }))}
        footer={t(`step-two.important-instructions.${size}.${letterWindowType}.footer`, { defaultValue: '' })}
      />
    </Flex>
  );
};

export const InfoBoxStepTwo = ({ isLargeLetter }: { isLargeLetter: boolean }) => {
  const { t } = useTranslation();
  const size = isLargeLetter ? 'big' : 'small';

  const listItems = useTranslatedListItems(`step-two.infobox.${size}.list`);

  return (
    <Flex
      style={{
        borderLeft: '1px solid var(--GRAY-OPAC-Gray-O-400)',
        paddingLeft: '20px',
        paddingRight: '20px',
        height: '100%',
      }}
    >
      <Flex direction={'column'} gap={10}>
        <InfoIcon color="var(--BASIC-Black)"></InfoIcon>
        <Text fw={700}>{t('step-two.infobox.title')}</Text>

        <List>
          {listItems.map((item: string, index: number) => (
            <ListItem key={index}>
              <Text>{item}</Text>
            </ListItem>
          ))}
        </List>
        <Text>{t('step-two.infobox.footer')}</Text>
      </Flex>
    </Flex>
  );
};

export const StepTwo = () => {
  const { t } = useTranslation();
  const { form } = useFormContext();
  const { isLargeLetter } = useLetterType();
  const goToDashboard = useGoToDashboard();
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();
  const { updateProject, isUpdateProjectSuccess } = useUpdateProject();

  const handleRadioChangeLetterWindowType = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('letterWindowType', event.target.value as letterWindowType);
  };

  const updateProjectForStepTwo = async () => {
    if (!form.values.letterWindowType) {
      return;
    }
    updateProject(form.values.projectNumber, { letterWindowType: form.values.letterWindowType });
  };

  useEffect(() => {
    if (isUpdateProjectSuccess) {
      navigate(`/wizard/${form.values.projectNumber}/3`);
    }
  }, [isUpdateProjectSuccess]);

  const isNextButtonDisabledStepTwo = (() => {
    return !form.values.letterWindowType;
  })();

  return (
    <>
      <Paper shadow="sm" radius="md" withBorder p="xl">
        <Grid gutter={40}>
          <Grid.Col span={9}>
            <Grid>
              <Grid.Col span={12}>
                <h2>{t('step-two.specify.envelope-window')}</h2>
              </Grid.Col>
              <Grid.Col span={4}>
                <RadioButtonCard
                  data-testid="radio-button-card-without-window"
                  label={t('step-two.NO_WINDOW')}
                  image={isLargeLetter ? <LetterWithoutWindowGross /> : <LetterWithoutWindowStandard />}
                  value="NO_WINDOW"
                  checked={form.values.letterWindowType === 'NO_WINDOW'}
                  onChange={handleRadioChangeLetterWindowType}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <RadioButtonCard
                  data-testid="radio-button-card-with-window"
                  label={t('step-two.WITH_WINDOW')}
                  image={isLargeLetter ? <LetterWithWindowGross /> : <LetterWithWindowStandard />}
                  value="WITH_WINDOW"
                  checked={form.values.letterWindowType === 'WITH_WINDOW'}
                  onChange={handleRadioChangeLetterWindowType}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                {isLargeLetter ? (
                  <RadioButtonCard
                    data-testid="radio-button-card-without-envelope"
                    label={t('step-two.NO_ENVELOPE')}
                    image={<LetterWithoutEnvelope />}
                    value="NO_ENVELOPE"
                    checked={form.values.letterWindowType === 'NO_ENVELOPE'}
                    cardDisabled={form.values.weightInG === 50}
                    disabledReason={t('step-two.NO_ENVELOPE.disabled-reason')}
                    onChange={handleRadioChangeLetterWindowType}
                  />
                ) : (
                  <RadioButtonCard
                    data-testid="radio-button-card-with-hypen"
                    label={t('step-two.WITH_TRENNSTRICH')}
                    image={<LetterWithSeparator />}
                    value="WITH_TRENNSTRICH"
                    checked={form.values.letterWindowType === 'WITH_TRENNSTRICH'}
                    onChange={handleRadioChangeLetterWindowType}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <ImportantInstructionsStepTwo
                  isLargeLetter={isLargeLetter}
                  letterWindowType={form.values.letterWindowType}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={3}>
            <InfoBoxStepTwo isLargeLetter={isLargeLetter} />
          </Grid.Col>
        </Grid>
      </Paper>
      <ButtonBar
        onNext={updateProjectForStepTwo}
        onCancel={goToDashboard}
        cancelButtonLabel={t('cancel')}
        nextButtonLabel={t('next')}
        isNextButtonDisabled={isNextButtonDisabledStepTwo}
      />
    </>
  );
};
