/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLFormGroup, DHLHeadline, DHLIcon, DHLTextInput, DHLTextOutput, DHLTooltip, DialogFormContent7030 } from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ResponseplusConstants } from "../../../../constants/ResponseplusConstants";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";
import "./OrderPage2ContentDetails.scss";
import { ResponseplusContactInput } from "./ResponseplusContactInput";
import { ResponseplusReturnAddressInput } from "./ResponseplusReturnAddressInput";

export type ResponseplusOrderPage1Props = {
  orderStore: ResponseplusOrderStore;
  rightContent: React.ReactElement
}

export const OrderPage2ContentDetails = observer((
    {
      orderStore: dataStore,
      rightContent
    }:
        ResponseplusOrderPage1Props) => {

  const {resourceDataStore} = dataStore.baseStore;

  useEffect(() => {
    dataStore.loadContactPersonData();
  }, [dataStore]);

  const [customerOrderNumberTooltipOpen, setCustomerOrderNumberTooltipOpen] = useState(false);
  const toggleCustomerOrderNumberTooltip = () => setCustomerOrderNumberTooltipOpen(!customerOrderNumberTooltipOpen);

  const customerOrderNumberHeadlineWithInfoIcon =
      <>
        <DHLIcon name={"customerOrderNumberInfoButton"} icon={"alert-info"} />
        <DHLTooltip target={"customerOrderNumberInfoButton"} placement={"right"} tooltipOpen={customerOrderNumberTooltipOpen}
                    toggle={toggleCustomerOrderNumberTooltip}>
          {resourceDataStore.getLabel("order.page2-orderDetails.customerOrderNumberTooltip")}
        </DHLTooltip>
      </>;

  const customerOrderNumber =
      <DHLFormGroup>
        <DHLFormGroup>
          <DHLHeadline type={"h6"} tooltip={customerOrderNumberHeadlineWithInfoIcon}>
            {resourceDataStore.getLabel("order.page2-orderDetails.customerOrderNo")}
          </DHLHeadline>
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextOutput value={resourceDataStore.getLabel("order.page2-orderDetails.customerOrderNoText")} />
        </DHLFormGroup>
        <DHLFormGroup className="row">
          <div className="col-6">
            <DHLTextInput formField={dataStore.customerOrderNumberFormField} />
          </div>
        </DHLFormGroup>
      </DHLFormGroup>;


  const [actionTermTooltipOpen, setActionTermTooltipOpen] = useState(false);
  const toggleActionTermTooltip = () => setActionTermTooltipOpen(!actionTermTooltipOpen);

  const actionTermIcon =
      <>
        <DHLIcon name={"actionTermInfoButton"} icon={"alert-info"} />
        <DHLTooltip target={"actionTermInfoButton"} placement={"right"} tooltipOpen={actionTermTooltipOpen} toggle={toggleActionTermTooltip}>
          {resourceDataStore.getLabel("order.page2-orderDetails.actionTermTooltip")}
        </DHLTooltip>
      </>;

  const orderActionTerm =
      <DHLFormGroup>
        <DHLFormGroup>
          <DHLHeadline type={"h6"} tooltip={actionTermIcon}>
            {resourceDataStore.getLabel("order.page2-orderDetails.actionTerm")}
          </DHLHeadline>
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextOutput value={resourceDataStore.getLabel("order.page2-orderDetails.actionTermText")} />
        </DHLFormGroup>
        <DHLFormGroup className="row">
          <div className="col-6">
            <DHLTextInput formField={dataStore.actionTermFormField} />
          </div>
        </DHLFormGroup>
      </DHLFormGroup>;

  const additionalRemarks =
      <DHLFormGroup>
        <DHLFormGroup>
          <DHLHeadline type={"h6"}>
            {resourceDataStore.getLabel("order.page2-orderDetails.remarks")}
          </DHLHeadline>
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextInput
              type={"textarea"}
              rows={2}
              placeholder={resourceDataStore.getLabel("order.page2-orderDetails.remarksPlaceHolder")}
              formField={dataStore.remarksFormField}
          />
        </DHLFormGroup>
      </DHLFormGroup>;

  function renderOrderActionTerm() {
    if (dataStore.nrFrankierartFormField.value === ResponseplusConstants.nrFrankierArt.static.toString()) {
      return <>{orderActionTerm}</>;
    }
    return null;
  }

  const preamble = (<DHLHeadline type={"h5"}>{resourceDataStore.getLabel("order.page2-orderDetails.headline")}</DHLHeadline>);

  const footnote =
      <DHLFormGroup className="row">
        <div className={classNames("col-12")}>
          <DHLTextOutput name={"requiredInfo"} value={resourceDataStore.getLabel("order.requiredInfoText")}
                         footnoteIdentifier={resourceDataStore.getLabel("order.requiredInfoMarker")} />
        </div>
      </DHLFormGroup>;

  const leftContent =
      <>
        {<ResponseplusReturnAddressInput orderStore={dataStore} />}
        {customerOrderNumber}
        {renderOrderActionTerm()}
        {<ResponseplusContactInput orderStore={dataStore} />}
        {additionalRemarks}
        {footnote}
      </>;

  return (
      <DialogFormContent7030 className={"responseplus-order-page2-container"} preamble={preamble} leftContent70={leftContent}
                             rightMarginal30={rightContent} />
  );
});