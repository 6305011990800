/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { Postcard, PostcardRootProps } from "@gkuis/gkp-postcard-ui";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationPostcard() {
  Postcard.configure({
    baseUri: `${process.env.POSTCARD_BASE_URI}/v1`,
    resourceDataBaseUri: `${process.env.POSTCARD_BASE_URI}/v1`,
    validationRulesBaseUri: `${process.env.POSTCARD_BASE_URI}/v1`,
    imageUri: process.env.POSTCARD_IMAGE_BASE_URI
  });
}

export function registerApplicationPostcard(authenticationManager: AuthenticationManager, envConfig: EnvConfig) {
  const postcardRootProps: PostcardRootProps = {
    authenticationManager,
    postcardConfiguration: Postcard.configure(),
    cmsContentRoot: envConfig.cmsContentRoot
  };

  registerApplication({
    name: "@gkuis/gkp-postcard-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-postcard-ui"),
    activeWhen: ["/postcard"],
    customProps: {
      ...postcardRootProps,
      domElementGetter: reactDomElementGetter
    },
  });
}