import React, { ReactElement } from 'react';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { SFCProps } from 'common/utils/formHelpers';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { SubProductsSchema } from 'order/productGroups/dialogMarketing/components/SubProductsSection/subProductsSchema';
import { Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useDialogPostClusterCombinations } from '../../hooks/useDialogPostClusterCombinations';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useOrderContext } from 'order/common/context/order/OrderContext';

export type SubProductsSectionProps<T extends FieldValues> = SFCProps<T, SubProductsSchema> & {
  disabled?: boolean;
  productGroup?: ProductGroup;
  selectedDate?: Date;
  baseProduct?: string;
  orderMode?: OrderMode;
};

export const SubProductsSection = <T extends FieldValues>({
  productGroup,
  selectedDate,
  baseProduct,
  orderMode,
  ...props
}: SubProductsSectionProps<T>): ReactElement => {
  const { premium, deliveryAreaSelection } = useDependentProducts(productGroup, selectedDate, baseProduct);
  const { trackAndMatchAvailable } = useDialogPostClusterCombinations();
  const isPostaktuell = productGroup === ProductGroup.POSTAKTUELL;
  const { order } = useOrderContext();
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { t } = useTranslation('translation');

  return (
    <OrderStepSection headline={oc('additionalServices')}>
      {productGroup !== ProductGroup.BRIEF && !isPostaktuell && (
        <FormRow mode="checkboxes">
          {trackAndMatchAvailable && (
            <Controller
              name={`${props.name}.trackAndMatch`}
              render={({ field }) => (
                <DHLCheckbox
                  name={field.name}
                  label={'Track & Match'}
                  value={field.value}
                  onChange={() => field.onChange(!field.value)}
                  disabled={props.disabled}
                />
              )}
            />
          )}
        </FormRow>
      )}
      {isPostaktuell && (
        <FormRow mode="checkboxes">
          {/* {startPointSelection && (
            <Controller
              name={`${props.name}.startPointSelection`}
              render={({ field }) => (
                <DHLCheckbox
                  name={field.name}
                  label={t('startPointSelection')}
                  value={field.value}
                  onChange={() => field.onChange(!field.value)}
                  disabled={true}
                />
              )}
            />
          )} */}
          {deliveryAreaSelection && (
            <Controller
              name={`${props.name}.deliveryAreaSelection`}
              render={({ field }) => (
                <DHLCheckbox
                  name={field.name}
                  label={t('deliveryAreaSelection')}
                  value={field.value}
                  onChange={() => field.onChange(!field.value)}
                  disabled={true}
                />
              )}
            />
          )}
        </FormRow>
      )}

      <Controller
        name={`${props.name}.premium`}
        render={({ field: { value, onChange, name } }) => {
          if (!premium.length) {
            return <></>;
          }
          const productNumbers = premium.map(({ productNumber }) => productNumber);
          return (
            <>
              <FormRow mode="two-col">
                <AMDHLSelectSingle
                  data-testid={'premiumadressId'}
                  name={name}
                  placeholder="Premiumadress"
                  value={value as string}
                  onChange={onChange}
                  items={['', ...productNumbers]}
                  valueToString={(productNumber) => {
                    if (productNumber === '') {
                      return '';
                    }
                    return premium.find(({ productNumber: p }) => p === productNumber)?.descriptionShort || '';
                  }}
                  disabled={props.disabled}
                />
              </FormRow>
            </>
          );
        }}
      />
      <div />
    </OrderStepSection>
  );
};
