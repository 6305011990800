import { AuthUser, defaultAuthUserData } from 'common/dtos/auth';
import { defaultUserRights, UserRights } from 'common/dtos/userRights';
import { sendRequest } from '../api-adapter';
import { Whitelisting } from '../dtos/Whitelisting';
import { Method } from 'axios';

export type AuthState = {
  loading: boolean;
  user: AuthUser;
  permissions: UserRights[];
  whitelisting: Whitelisting | undefined;
  extUserId: string | undefined;
};

export enum AuthActions {
  SET_LOADING = 'SET_LOADING',
  SET_USER = 'SET_USER',
  SET_PERMISSIONS = 'SET_PERMISSIONS',
  SET_WHITELISTING = 'SET_WHITELISTING',
  SET_EXT_USER_ID = 'SET_EXT_USER_ID'
}

type Action =
  | { type: AuthActions.SET_LOADING; data: boolean }
  | { type: AuthActions.SET_USER; data: AuthUser }
  | { type: AuthActions.SET_PERMISSIONS; data: UserRights[] }
  | { type: AuthActions.SET_WHITELISTING; data: Whitelisting | undefined }
  | { type: AuthActions.SET_EXT_USER_ID; data: string | undefined };

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

export const defaultAuthState: AuthState = {
  loading: true,
  user: defaultAuthUserData,
  permissions: defaultUserRights,
  whitelisting: undefined,
  extUserId: undefined
};

export function authReducer(state: AuthState, action: Action) {
  switch (action.type) {
    case AuthActions.SET_LOADING: {
      return {
        ...state,
        loading: action.data
      };
    }
    case AuthActions.SET_USER: {
      return {
        ...state,
        user: action.data
      };
    }
    case AuthActions.SET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.data
      };
    }
    case AuthActions.SET_WHITELISTING: {
      return {
        ...state,
        whitelisting: action.data
      };
    }
    case AuthActions.SET_EXT_USER_ID: {
      return {
        ...state,
        extUserId: action.data
      };
    }
    default:
      return defaultAuthState;
  }
}

export async function fetchPermissions<T>(accessToken: string | undefined, extUserId: string | undefined, method: Method): Promise<any[]> {
  if (!accessToken) {
    console.error('Error performing request for fetching user permissions: No access token provided');
    return [];
  }

  try {
    const { data } = await sendRequest({
      url: `${BASE_URL}masterdata/user-rights`,
      method: method,
      token: accessToken,
      extUserId: extUserId
    });
    return data;
  } catch (e) {
    console.error('Error performing request for fetching user permissions', e);
    return [];
  }
}

export async function fetchWhitelisting<T>(
  accessToken: string | undefined,
  extUserId: string | undefined,
  method: Method
): Promise<Whitelisting | undefined> {
  if (!accessToken) {
    console.error('Error performing request for fetching whitelisting: No access token provided');
    return undefined;
  }

  try {
    const { data, status } = await sendRequest({
      url: `${BASE_URL}whitelist`,
      method: method,
      token: accessToken,
      extUserId: extUserId
    });

    if (status !== 200) {
      console.error('Error performing request for fetching whitelisting', status, data);
      return undefined;
    }

    return data;
  } catch (e) {
    console.error('Error performing request for fetching whitelisting', e);
    return undefined;
  }
}
