/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { EnvConfig } from "../src/types/EnvConfig";

export const envConfig: EnvConfig = {
  keycloakUrl: "/keycloak/url",
  cmsContentRoot: "/cms/content/root",
  inquiryRequestUrl: "/inquiry/request/root?formId={formId}&lang={language}&tokenIdentifier=jwt&shipmentNumber={shipmentNumber}&plz={plz}&useDB=true",
  trackingUrl: "/tracking/url",
  trackingIntegrityHash: "testTrackingIntegrityHash",
  trackingConsentScriptId: "testTrackingConsentScriptId",
  featureToggles: {
    testFeatureToggleForJestTests: true
  },
  pmpUrl: "testPmPUrl"
};