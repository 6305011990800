import { DHLCheckbox, DHLHeadline, DHLTooltip, DHLTooltipIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import iconPayer from 'assets/icon-payer.svg';
import classNames from 'classnames';
import { Button } from 'common/components/Button';
import { FormRow } from 'common/components/FormRow';
import { useBoolean } from 'common/hooks/useBoolean';
import orderClasses from 'common/styles/order.module.css';
import { useKeyPress } from 'common/utils/hooks';
import { CustomTextInput } from 'order/common/components/CustomTextInput';
import { PostageTypeDropdown } from 'order/common/components/PostageTypeDropdown';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { BillingOrPostcardNumberInput } from 'order/orderChange/common/components/BillingOrPostcardNumberInput/BillingOrPostcardNumberInput';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn';
import { PostageType } from 'order/common/dtos/PostageType';
import React, { FC, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { DialogMarketingOrderCreate, validateDpContent } from '../../schema/dialogMarketingSchema';
import { PostageInfoSection } from '../../components/PostageInfoSection';
import { TakeOriginatorAsPayer } from 'order/common/components/TakeOriginatorAsPayer/TakeOriginatorAsPayer';
import { isServiceAllowed } from '../../utils/isServiceAllowed';
import { Checkbox } from 'common/components/Checkbox/Checkbox';
import { dvDiscountFn } from 'order/common/utils/dvDiscountFn';

const inputDisabled = false;
export const DialogMarketingStep3: FC = () => {
  const { previousStep, nextStep } = useOrderStep();
  const wrapperRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step3Form' });
  const { t: postageTypeTranslate } = useTranslation('catalogValues', { keyPrefix: 'postageType.display' });
  const { control, getValues, setValue, trigger, setError } = useFormContext<DialogMarketingOrderCreate>();
  const { orderMode, order } = useOrderContext();
  const { isDirty, orderCategoryProductKey } = useOrderContext();
  const isPostwurfspezial = orderCategoryProductKey === OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL;
  const isPostaktuell = orderCategoryProductKey === OrderCategoryProductKey.POSTAKTUELL;
  const [productGroup, dialogpostBaseProduct, selectedDate, postageType, payer, additionalCharge] = useWatch({
    control,
    name: ['productGroup', 'dialogpostBaseProduct', 'details.date', 'postageType', 'payer', 'additionalCharge']
  });
  const currentPostageType = useRef(postageType);
  const [isTooltipOpen, setTooltipOpen] = useBoolean(false);
  const isPostageTypeDV = order?.postage?.type === PostageType.DV;
  const resetAdditionalFrankingOptions = () => {
    // preparations for merging components in create and change order
    if (orderMode === OrderMode.CHANGE) {
      setValue('postageAmountValue', undefined);
    }
    setValue('postageMachineCode', '');
  };

  useEffect(() => {
    if (currentPostageType.current !== postageType) {
      currentPostageType.current = postageType;
      resetAdditionalFrankingOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPostageType, postageType]);

  useKeyPress([wrapperRef], ['Enter'], async (e) => {
    await trigger('payer');
  });

  useEffect(() => {
    if (isPostwurfspezial) {
      setValue('postageType', PostageType.FV);
    }
  }, [isPostwurfspezial]);

  const dvDiscount = dvDiscountFn(order?.postage?.type, order?.dependentProducts);

  return (
    <>
      <div className={orderClasses.formContent}>
        <div className={orderClasses.rowWithSidebar}>
          <main>
            <OrderStepHeadline icon={iconPayer} alt="Payer Icon">
              {oc('paymentInformation')}
            </OrderStepHeadline>
            <section>
              <DHLHeadline type="h6">{oc('payer')}</DHLHeadline>
              {!dvDiscount && !isPostaktuell && <TakeOriginatorAsPayer hasTooltip={true} />}
              <BillingOrPostcardNumberInput name={'payer'} productGroup={productGroup} disabled={dvDiscount} />
            </section>
            <section>
              {isPostwurfspezial || isPostaktuell ? (
                <>
                  <DHLHeadline type="h6">{oc('franking')}</DHLHeadline>
                  <FormRow mode="two-col">
                    <CustomTextInput
                      type={'text'}
                      name="postageType"
                      label={oc('typeOfFranking') + '*'}
                      testid={'typeOfFranking'}
                      defaultValue={order?.postage?.type ? postageTypeTranslate(order?.postage?.type) : postageTypeTranslate('FV')}
                      disabled={true}
                    />
                  </FormRow>
                </>
              ) : (
                <>
                  <DHLHeadline type="h6">{oc('typeOfFranking')}</DHLHeadline>
                  <FormRow mode="two-col">
                    <PostageTypeDropdown<DialogMarketingOrderCreate>
                      name="postageType"
                      required
                      label={oc('typeOfFranking') + '*'}
                      disabled={isPostageTypeDV}
                      data-testid={'frankingId'}
                      date={selectedDate}
                      baseProduct={dialogpostBaseProduct}
                    />
                    {dvDiscount && (
                      <Checkbox
                        label={t('dvClearance')}
                        checked={dvDiscount}
                        datatestid="DV-Freimachung-discount"
                        name={`dvClearance`}
                        disabled={true}
                        verticalCenter={true}
                      />
                    )}
                  </FormRow>
                  {isServiceAllowed(selectedDate) && (
                    <FormRow mode="two-col">
                      <PostageTypeDropdown<DialogMarketingOrderCreate>
                        name="additionalCharge"
                        label={oc('methodOfPaymentForAdditionalCharges')}
                        disabled={isPostageTypeDV}
                        data-testid={'additionalChargeId'}
                        date={selectedDate}
                        baseProduct={dialogpostBaseProduct}
                      />
                    </FormRow>
                  )}
                </>
              )}
              {postageType === PostageType.AFM && <PostageInfoSection type={postageType} />}
            </section>
          </main>
          <MarginalColumn />
        </div>
      </div>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button style={{ marginRight: '10px' }} variant="default" onClick={() => previousStep()} type="button" label={t('buttons.back')} />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          dataTestId="btnSubmit"
          variant="primary"
          onClick={async () => {
            const isPaymentValid = await trigger();
            const areDependentFieldsValid = validateDpContent(getValues(), setError, 3);
            if (isPaymentValid && areDependentFieldsValid) {
              nextStep();
            }
          }}
          type="button"
          label={t('buttons.continue')}
        />
      </footer>
    </>
  );
};
