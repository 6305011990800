import { DHLButton, DHLButtonGroup } from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";

export type OrderConfirmActionbarProps = {
  orderStore: ResponseplusOrderStore;
}

export const OrderConfirmActionbar = observer(({orderStore}: OrderConfirmActionbarProps) => {
  const {resourceDataStore} = orderStore.baseStore;
  const backButton = (
    <DHLButton
      type="default"
      name="back"
      label={resourceDataStore.getButton("order.back")}
      onClick={() => orderStore.backToPage2()} />
  );
  const cancelButton = (
    <DHLButton
      type="secondary"
      name="cancel"
      label={resourceDataStore.getButton("order.cancel")}
      onClick={() => orderStore.cancelOrder()}
    />
  );
  const submitButton = (
    <DHLButton
      loadingState={orderStore.orderSubmitting}
      type="primary"
      name="submit"
      label={resourceDataStore.getButton("order.page3-orderconfirm.submit")}
      onClick={() => orderStore.submitOrder()} />
  );

  return (
    <>
      <DHLButtonGroup name="orderConfirmActionbar">
        {backButton}
        {cancelButton}
      </DHLButtonGroup>
      {submitButton}
    </>
  );

});
