import { OrderPostage } from 'generated';
import i18n from 'i18n';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderOverviewWidget, OrderOverviewWidgetProps } from 'order/productGroups/common/components/molecule/OrderOverview';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './franking-display.module.css';
import translation from './translation.de.json';
import { isServiceAllowed } from 'order/productGroups/dialogMarketing/utils/isServiceAllowed';
import { Order } from 'order/common/context/order/dtos/Order';
import { dvDiscountFn } from 'order/common/utils/dvDiscountFn';

export type FrankingDisplayProps = {
  order?: Order;
  postage: OrderPostage;
  productGroup?: ProductGroup | string;
  category?: OrderCategory | string;
  orderDate?: Date;
} & Pick<OrderOverviewWidgetProps, 'width'>;

i18n.addResourceBundle('de', 'frankingDisplay', translation);
i18n.addResourceBundle('en', 'frankingDisplay', translation);

export const FrankingDisplay: React.VFC<FrankingDisplayProps> = ({ postage, width, productGroup, category, orderDate, order }) => {
  const { t } = useTranslation('frankingDisplay');
  const { t: oc } = useTranslation('orderCreate');
  const { t: postageT } = useTranslation('catalogValues', { keyPrefix: 'postageType.display' });
  const isBrief = productGroup === ProductGroup.BRIEF;
  const isDVBrief = category === OrderCategory.DV_BRIEF;
  const dvDiscount = dvDiscountFn(order?.postage?.type, order?.dependentProducts);

  return (
    <OrderOverviewWidget title={t('franking')} width={width}>
      <table className={classes.frankingTable}>
        <tbody>
          {!!postage.type && (
            <tr>
              <td>{t('type')}</td>
              <td>{postageT(postage.type)}</td>
            </tr>
          )}
          {dvDiscount && (
            <tr>
              <td>{oc('dvClearance')}:</td>
              <td>{oc('yes')}</td>
            </tr>
          )}
          {productGroup === ProductGroup.DIALOGPOST
            ? !!postage.additionalCharge &&
              isServiceAllowed(orderDate) && (
                <tr data-testid={'additional_ChargeTr'}>
                  <td>{t('additionalCharge')}</td>
                  <td>{postageT(postage.additionalCharge)}</td>
                </tr>
              )
            : !!postage.additionalCharge &&
              !isBrief && (
                <tr data-testid={'additional_ChargeTr'}>
                  <td>{t('additionalCharge')}</td>
                  <td>{postageT(postage.additionalCharge)}</td>
                </tr>
              )}
          {!!postage.paymentClearingNumber && (!isBrief || isDVBrief) && (
            <tr>
              <td data-testid={'payment_Clearing_NumberTr'}>{t('paymentClearingNumber')}</td>
              <td>{postage.paymentClearingNumber}</td>
            </tr>
          )}
          {!!postage.machineCode && (
            <tr>
              <td>{t('code')}</td>
              <td>{postage.machineCode}</td>
            </tr>
          )}
        </tbody>
      </table>
    </OrderOverviewWidget>
  );
};
