import { CapitalLetter, CompactLetter, LetterTypesWithNewProduct, MaxLetter, PostCard, StandardLetter } from '../dtos/LetterTypes';

export const determineLetterTypeByProductNumber = (productNumber?: string): LetterTypesWithNewProduct | string => {
  let letterType: string = '';
  switch (productNumber?.replace(/^0+/, '')) {
    case StandardLetter.Standardbrief:
    case StandardLetter.StandardbriefBZA:
    case StandardLetter.StandardbriefBZE:
      letterType = LetterTypesWithNewProduct.Standard;
      break;
    case StandardLetter.StandardbriefBZL:
      letterType = LetterTypesWithNewProduct.StandardBZL;
      break;
    case CompactLetter.Kompaktbrief:
    case CompactLetter.KompaktbriefBZA:
    case CompactLetter.KompaktbriefBZE:
      letterType = LetterTypesWithNewProduct.Compact;
      break;
    case CompactLetter.KompaktbriefBZL:
      letterType = LetterTypesWithNewProduct.CompactBZL;
      break;
    case CapitalLetter.Großbrief:
    case CapitalLetter.GroßbriefBZA:
    case CapitalLetter.GroßbriefBZE:
      letterType = LetterTypesWithNewProduct.Large;
      break;
    case CapitalLetter.GroßbriefBZL:
      letterType = LetterTypesWithNewProduct.LargeBZL;
      break;
    case MaxLetter.Maxibrief:
    case MaxLetter.MaxibriefBZA:
    case MaxLetter.MaxibriefBZE:
      letterType = LetterTypesWithNewProduct.Maxi;
      break;
    case MaxLetter.MaxibriefBZL:
      letterType = LetterTypesWithNewProduct.MaxiBZL;
      break;
    case MaxLetter.MaxibriefBZLPlusBis:
      letterType = LetterTypesWithNewProduct.MaxiBZLPlusBis;
      break;
    case MaxLetter.MaxibriefBZLPlusUber:
      letterType = LetterTypesWithNewProduct.MaxiBZLPlusUber;
      break;
    case PostCard.Postkarte:
    case PostCard.PostkarteBZA:
    case PostCard.PostkarteBZE:
      letterType = LetterTypesWithNewProduct.Postcard;
      break;
    case PostCard.PostkarteBZL:
      letterType = LetterTypesWithNewProduct.PostcardBZL;
      break;
    default:
      break;
  }
  return letterType;
};
