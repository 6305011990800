/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager } from "@gkuis/gkp-authentication";
import {
  BaseFormStore,
  DataFilterStore,
  dateFormatForLanguage,
  formatDate,
  logger,
  ResourceDataStore,
  SortOrder,
  TableDataStore,
  ValidationRuleDataStore,
  ErrorResponse
} from "@gkuis/gkp-base-widgets";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { ResponseplusClient } from "../clients/ResponseplusClient";
import { FORMAT_NO_ORDER_NUMBER, formatOrderNumber } from "../components/format";
import { responseplusOrderStateMap } from "../components/organisms/ResponseplusOverview/ResponseplusOrderStatusMapper";
import { RppOrder } from "../types/RppOrder";
import { RppOrderEntry, RppOrderTableEntry } from "../types/RppOrderEntry";
import { RppProduct } from "../types/RppProduct";
import { ResponseplusCustomerProfileSelectStore } from "./ResponseplusCustomerProfileSelectStore";
import { ResponseplusErrorStateStore } from "./ResponseplusErrorStateStore";
import { ResponseplusOrderDetailStore } from "./ResponseplusOrderDetailStore";
import { ResponseplusOrderStore } from "./ResponseplusOrderStore";

const LOG_MODULE = "ResponseplusStore";

export class ResponseplusOverviewStore extends BaseFormStore {

  profileSelectStore: ResponseplusCustomerProfileSelectStore;

  loading: boolean;
  tableDataStore: TableDataStore<RppOrderTableEntry>;
  dataFilterStore: DataFilterStore<RppOrderTableEntry>;
  productFilterEntries: Set<string>;
  rppOrderToCancel?: RppOrderEntry | null = null;
  bufferedSuccessMessages?: string[];

  orderCancelling: boolean;
  products?: RppProduct[];

  detailStore: ResponseplusOrderDetailStore;
  orderStore: ResponseplusOrderStore;
  errorStateStore: ResponseplusErrorStateStore;

  constructor(
      resourceDataStore: ResourceDataStore,
      validationRuleDataStore: ValidationRuleDataStore,
      readonly responseplusClient: ResponseplusClient,
      readonly authenticationManager: AuthenticationManager,
      redirectBack: () => void
  ) {
    super(resourceDataStore, validationRuleDataStore, null);
    this.profileSelectStore = new ResponseplusCustomerProfileSelectStore(this, responseplusClient, authenticationManager);
    const createFullTextsMapper = (entry: RppOrderEntry): string[] => {
      const nextStepKey = responseplusOrderStateMap.get(entry.state)!.nextStepsKey ?? "";
      return [
        formatDate(entry.createdDate, dateFormatForLanguage(authenticationManager.language)),
        formatOrderNumber(entry.orderNumber),
        entry.customerOrderID,
        entry.productName,
        this.resourceDataStore.getLabel(responseplusOrderStateMap.get(entry.state)!.orderStateKey),
        formatDate(entry.lastUpdatedDate, dateFormatForLanguage(authenticationManager.language)),
        nextStepKey
      ];
    };
    this.loading = true;
    this.orderCancelling = false;
    this.productFilterEntries = new Set();
    this.dataFilterStore = new DataFilterStore(resourceDataStore, authenticationManager);
    this.tableDataStore = new TableDataStore<RppOrderTableEntry>(
        resourceDataStore,
        validationRuleDataStore,
        this.messageDataStore,
        authenticationManager,
        computed(() => this.dataFilterStore.predicate),
        true,
        createFullTextsMapper
    );
    this.tableDataStore.sort("createdDate", SortOrder.DESC, "date");

    this.orderStore = new ResponseplusOrderStore(
        resourceDataStore,
        validationRuleDataStore,
        responseplusClient,
        authenticationManager,
        this.reset,
        () => this.navigateToOverview(redirectBack),
        ((headline: string, messages: string[]) => this.setBufferedSuccessMessage(headline, messages))
    );

    this.detailStore = new ResponseplusOrderDetailStore(
        resourceDataStore,
        validationRuleDataStore,
        responseplusClient,
        authenticationManager,
        this.orderStore);

    this.errorStateStore = new ResponseplusErrorStateStore(
        resourceDataStore,
        validationRuleDataStore,
        responseplusClient,
        authenticationManager,
        this.detailStore);

    this.bufferedSuccessMessages = undefined;

    makeObservable(this, {
      loading: observable,
      tableDataStore: observable,
      dataFilterStore: observable,
      productFilterEntries: observable,
      rppOrderToCancel: observable,
      bufferedSuccessMessages: observable,
      orderCancelling: observable,
      isLoading: computed,
      setRppOrderToCancel: action,
      reset: action,
      navigateToOverview: action,
      clearStoreMessages: action,
      loadOrderEntries: action,
      loadFilterEntries: action,
      cancelRppOrder: action,
      setBufferedSuccessMessage: action,
      hasBufferedSuccessMessages: computed,
      cancelConfirmDialogVisible: computed,
      deliverBufferedSuccessMessage: action,
      setSelectedOrderForDetailView: action,
      setSelectedOrderForCopyView: action,
    });
    reaction(() => authenticationManager.authenticatedSubject, this.reset);
  }

  get isLoading(): boolean {
    return this.resourceDataStore.isLoading;
  }

  setRppOrderToCancel = (entry: RppOrderEntry | null) => {
    this.rppOrderToCancel = entry;
  };

  get cancelConfirmDialogVisible(): boolean {
    return this.rppOrderToCancel !== null;
  }

  reset = (): void => {
    logger.log(LOG_MODULE, "Reset");
    this.loading = true;
    this.clearStoreMessages();
    this.profileSelectStore.reset();
    this.tableDataStore.setData([]);
    this.tableDataStore.switchToNotLoaded();
    this.tableDataStore.sort("createdDate", SortOrder.DESC, "date");
    this.dataFilterStore.reset();
    this.productFilterEntries.clear();
    this.rppOrderToCancel = null;
    this.orderCancelling = false;
  };

  navigateToOverview(redirectBack: () => void) {
    this.profileSelectStore.copy(this.orderStore.profileSelectStore);
    redirectBack();
  }

  clearStoreMessages = (): void => {
    this.bufferedSuccessMessages = undefined;
    this.messageDataStore.clearTemporaryMessages();
    this.messageDataStore.clearPermanentMessages();
    this.messageDataStore.clearHeadlines();
  };

  async loadOrderEntries(): Promise<void> {
    try {
      this.tableDataStore.switchToLoading();
      const entries: RppOrderEntry[] = await this.responseplusClient.loadOrderList(this.profileSelectStore.ekp);
      const tableEntries: RppOrderTableEntry[] = entries.map(e => {
        return {
          ...e,
          orderNumber: e.orderNumber ?? FORMAT_NO_ORDER_NUMBER,
          stateWithUpdateDate: {state: e.state, lastUpdatedDate: e.lastUpdatedDate}
        };
      });
      this.tableDataStore.setData(tableEntries);
      this.tableDataStore.switchToLoaded();
      runInAction(() => this.loading = false);
    } catch (error) {
      logger.error(error);
      const errorReason = error as ErrorResponse;
      this.handleError(errorReason);
    }
  }

  private handleError(error: ErrorResponse) {
    this.clearTemporaryMessages();
    logger.log(LOG_MODULE, "Failed to load responseplus orders: ", error);
    if (error.errorMessages && error.errorMessages[0].messageKey === "framework.exception.UNAUTHORIZED") {
      this.messageDataStore.addErrorMessage(this.resourceDataStore.getError("noPermissionWithChoice",
          [ResponseplusCustomerProfileSelectStore.getCustomerProfileSelectLabel(this.profileSelectStore.selectedProfile!)]));
    } else {
      this.messageDataStore.addErrorMessage(this.resourceDataStore.getError("generalErrorWithChoice",
          [ResponseplusCustomerProfileSelectStore.getCustomerProfileSelectLabel(this.profileSelectStore.selectedProfile!)]));
    }
    this.tableDataStore.setData([]);
    this.tableDataStore.switchToNotLoaded();
  }

  async loadFilterEntries(): Promise<void> {
    try {
      const productFilterEntries: string[] = await this.createProductFilterEntries();
      const filterEntries: Set<string> = new Set();
      productFilterEntries.forEach(entry => filterEntries.add(entry));
      runInAction(() => this.productFilterEntries = filterEntries);
    } catch (err) {
      logger.log(LOG_MODULE, "Error occurred retrieving products: ", err);
      const errorMessage = this.resourceDataStore.getError("generalError");
      this.messageDataStore.addErrorMessage(errorMessage);
    }
  }

  cancelRppOrder: () => Promise<void> = async () => {
    this.clearStoreMessages();
    this.orderCancelling = true;
    try {
      await this.responseplusClient.cancelOrder(this.profileSelectStore.ekp, this.rppOrderToCancel!.globalOrderID);
      const message = this.resourceDataStore.getSuccess("order.cancel", [formatOrderNumber(this.rppOrderToCancel!.orderNumber)]);
      this.messageDataStore.addSuccessMessage(message);
      await this.loadOrderEntries();
      runInAction(() => this.orderCancelling = false);
    } catch (err) {
      logger.warn(LOG_MODULE, "Error cancelling rpp order: ", err);
      const error = this.resourceDataStore.getError("order.cancel");
      this.messageDataStore.addErrorMessage(error);
    } finally {
      this.setRppOrderToCancel(null);
    }
  };

  setBufferedSuccessMessage(headline: string, messages: string[]) {
    this.bufferedSuccessMessages = [headline, ...messages];
  }

  get hasBufferedSuccessMessages(): boolean {
    return this.bufferedSuccessMessages !== undefined;
  }

  deliverBufferedSuccessMessage() {
    if (!this.bufferedSuccessMessages || this.bufferedSuccessMessages.length === 0) {
      return;
    }
    this.messageDataStore.clear();
    const [headline, ...messages] = this.bufferedSuccessMessages;
    this.messageDataStore.addSuccessMessagesWithHeadline(headline, messages);
    this.bufferedSuccessMessages = undefined;
  }

  productFilterPredicate = (values: Set<string>) => (data: RppOrderEntry) => values.size <= 0
      || [...values].some(val => data.productName === val);

  stateFilterPredicate = (values: Set<string>) => (data: RppOrderEntry) => values.size <= 0
      || [...values].some(val => responseplusOrderStateMap.get(data.state)?.orderStateKey === val);

  nextStepFilterPredicate = (values: Set<string>) => (data: RppOrderEntry) => values.size <= 0
      || [...values].some(val => responseplusOrderStateMap.get(data.state)?.nextStepsKey === val);


  private async createProductFilterEntries(): Promise<string[]> {
    this.products = await this.responseplusClient.loadProductList();
    return this.products.map(p => p.name);
  }

  async setSelectedOrderForDetailView(entry: RppOrderEntry) {
    await this.detailStore.setSelectedOrder(entry, this.profileSelectStore.selectedProfile!, this.products);
    if (entry.state === 302 || entry.state === 304) {
      await this.errorStateStore.setSelectedOrder(this.profileSelectStore.ekp, entry);
    }
  }

  async setSelectedOrderForCopyView(entry: RppOrderEntry) {
    this.orderStore.reset();
    this.orderStore.orderLoading = true;
    const profile = this.profileSelectStore.selectedProfile!;
    const order = await this.responseplusClient.loadOrderById(profile.ekp, entry.globalOrderID);

    this.orderStore.profileSelectStore.copy(this.profileSelectStore);
    this.copyProductsToOrderStore(order);
    this.fillOrderFieldsFromOrder(order);
    this.orderStore.orderLoading = false;
  }

  private fillOrderFieldsFromOrder(order: RppOrder) {
    this.orderStore.plannedShipmentQuantityFormField.updateValue(order.amount.toString());

    this.orderStore.frankingZoneDesignFormField.updateValue(order.frankingZoneType.toString());
    this.orderStore.returnAddressFormField.updateValue(order.returnAddress.addressType.toString());

    this.orderStore.addressCompanyNameFormField.updateValue(order.returnAddress.companyName);
    this.orderStore.addressAddition1FormField.updateValue(order.returnAddress.addition1);
    this.orderStore.addressAddition2FormField.updateValue(order.returnAddress.addition2);
    this.orderStore.addressAddition3FormField.updateValue(order.returnAddress.addition3 ?? "");
    this.orderStore.addressAddition4FormField.updateValue(order.returnAddress.addition4 ?? "");
    this.orderStore.addressAddition5FormField.updateValue(order.returnAddress.addition5 ?? "");
    this.orderStore.addressStreetFormField.updateValue(order.returnAddress.street ?? "");
    this.orderStore.addressHouseNumberFormField.updateValue(order.returnAddress.houseNumber ?? "");
    this.orderStore.addressPostcodeFormField.updateValue(order.returnAddress.postcode);
    this.orderStore.addressCityFormField.updateValue(order.returnAddress.city);
    this.orderStore.addressPoBoxFormField.updateValue(order.returnAddress.pOBox ?? "");
    this.orderStore.customerOrderNumberFormField.updateValue("");
    this.orderStore.actionTermFormField.updateValue(order.orderRelatedCampaignName ?? "");
    this.orderStore.remarksFormField.updateValue(order.notes);

    this.orderStore.baseStore.nameFormField.updateValue(order.contacts[0].firstName);
    this.orderStore.baseStore.surNameFormField.updateValue(order.contacts[0].lastName);
    this.orderStore.baseStore.emailFormField.updateValue(order.contacts[0].email);
    this.orderStore.baseStore.phoneFormField.updateValue(order.contacts[0].phone);

    if (order.contacts.length > 1) {
      this.orderStore.baseStore.secondContactIsActive = true;
      this.orderStore.baseStore.secondNameFormField.updateValue(order.contacts[1].firstName);
      this.orderStore.baseStore.secondSureNameFormField.updateValue(order.contacts[1].lastName);
      this.orderStore.baseStore.secondEmailFormField.updateValue(order.contacts[1].email);
      this.orderStore.baseStore.secondPhoneFormField.updateValue(order.contacts[1].phone);
    } else {
      this.orderStore.baseStore.secondContactIsActive = false;
    }
  }

  private copyProductsToOrderStore(order: RppOrder) {
    if (this.products) {
      this.orderStore.products = this.products;
      //check if product still exists (could have been removed)
      const product = this.products.filter(p => p.id === order.productId)[0];
      if (product) {
        runInAction(() => {
          this.orderStore.selectedShipmentFormatFormField.updateValue(product.id.toString() + ResponseplusOrderStore.COPIED);
          this.orderStore.nrFrankierartFormField.updateValue(product.frankingType.toString());
        });
      }
    }
  }

}
