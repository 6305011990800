import { Trans, useTranslation } from 'react-i18next';
import { Flex, Grid, List, ListItem, Text, Paper } from '@mantine/core';
import { SingleFileUpload } from '../components/SingleFileUpload/SingleFileUpload';
import './print.css';
import { InfoIcon } from '../components/Icons/InfoIcon';
import { ButtonBar } from '../components/Buttons/ButtonBar';
import { useCheckProject } from '../hooks/useCheckProject';
import { useFormContext } from '../hooks/useFormContext';
import { useEffect, useState } from 'react';
import { useGoToDashboard } from '../hooks/useGoToDashboard';
import { useNavigateWithSearchParams } from '../hooks/useNavigateWithSearchParams';
import { useUploadDocument } from '../hooks/useUploadDocument';
import { useDomain } from '../hooks/useEnv';
import { useTranslatedListItems } from '../hooks/useTranslatedListItems';

const sampleAdressUrl = '/geschaeftskunden/portal/kundendesktop/online-hilfe/automationscheck/aufschrift-j4zfg9.html';

export const InfoBoxStepFour = () => {
  const { t } = useTranslation();
  const { domain } = useDomain();
  const { i18n } = useTranslation();

  const listItems = useTranslatedListItems('step-four.infobox.list');

  return (
    <Flex
      style={{
        borderLeft: '1px solid var(--GRAY-OPAC-Gray-O-400)',
        paddingLeft: '20px',
        paddingRight: '20px',
        height: '100%',
      }}
    >
      <Flex direction={'column'} gap={10}>
        <InfoIcon color="var(--BASIC-Black)"></InfoIcon>
        <Text fw={700}>{t('step-four.infobox.title')}</Text>

        <List>
          {listItems.map((item: string, index: number) => (
            <ListItem key={index}>
              <Trans
                components={{
                  b: <b />,
                  l: (
                    <a href={`${domain}${i18n.language}${sampleAdressUrl}`} target="_blank" rel="noreferrer">
                      Automationsfähige Briefsendungen
                    </a>
                  ),
                }}
              >
                {item}
              </Trans>
            </ListItem>
          ))}
        </List>
        <Text>{t('step-four.infobox.footer')}</Text>
      </Flex>
    </Flex>
  );
};

export const MoreImportantInstructionsStep4 = () => {
  const { t } = useTranslation();
  const { domain } = useDomain();
  const { i18n } = useTranslation();
  return (
    <Flex
      direction="column"
      mt="md"
      style={{
        backgroundColor: 'var(--BRAND-DHL-Red)',
        borderRadius: '8px',
        padding: '14px 20px',
        alignItems: 'flex-start',
        color: 'var(--BASIC-White)',
      }}
    >
      <Text>
        <Trans
          components={{
            b: <b />,
            l: (
              <a
                style={{ color: 'var(--BASIC-White)' }}
                href={`${domain}${i18n.language}${sampleAdressUrl}`}
                target="_blank"
                rel="noreferrer"
              >
                Beispieladressen
              </a>
            ),
          }}
        >
          {t('step-four.important-instructions.recipientaddress')}
        </Trans>
      </Text>
      <Text>{t('step-four.important-instructions.recipientaddress2')}</Text>
    </Flex>
  );
};

export const ImportantInstructionsStep4 = () => {
  const { t } = useTranslation();
  return (
    <Flex
      direction="column"
      style={{
        backgroundColor: 'var(--BRAND-Postyellow-30p)',
        borderRadius: '8px',
        padding: '14px 20px',
        alignItems: 'flex-start',
      }}
    >
      <Flex
        direction="row"
        gap={10}
        style={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <InfoIcon color="var(--BASIC-Black)"></InfoIcon>
        <Text fw={700}>{t('step-four.important-instructions.title')}</Text>
      </Flex>
      <Flex direction="row" style={{ padding: '10px 25px' }}>
        <List listStyleType="none">
          <ListItem key={0}>
            <Text style={{ fontWeight: 700 }}>{t('step-four.important-instructions.dataprotection.title')}</Text>
            <List listStyleType="initial">
              <ListItem key={2}>
                <Text>{t('step-four.important-instructions.dataprotection.details.0')}</Text>
              </ListItem>
              <ListItem key={3}>
                <Text>{t('step-four.important-instructions.dataprotection.details.1')}</Text>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Flex>
    </Flex>
  );
};

export const StepFour = () => {
  const { t } = useTranslation();
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();
  const { form } = useFormContext();
  const [checkClicked, setCheckClicked] = useState(false);
  const { checkProject, isSuccess, isChecking } = useCheckProject();
  const goToDashboard = useGoToDashboard();
  const { uploadDocument, isUploadDocumentPending } = useUploadDocument();

  useEffect(() => {
    if (isSuccess && checkClicked) {
      setCheckClicked(false);
      navigate(`/wizard/${form.values.projectNumber}/5`);
    }
  }, [isSuccess, checkClicked]);

  const hasUserFile = form.values.userFile !== undefined;

  const isNextButtonEnabled = hasUserFile && !isUploadDocumentPending;

  return (
    <>
      <Paper shadow="sm" radius="md" withBorder p="xl">
        <Grid gutter={40}>
          <Grid.Col span={9}>
            <Grid>
              <Grid.Col>
                <h2>{t('step-four.specify.select-pdf')}</h2>
              </Grid.Col>
              <Grid.Col>
                <ImportantInstructionsStep4 />
                <MoreImportantInstructionsStep4 />
              </Grid.Col>
              <Grid.Col>
                <SingleFileUpload
                  uploadDocument={(projectNumber: string, userFile: File) => {
                    uploadDocument(projectNumber, userFile);
                  }}
                  isUploadDocumentPending={isUploadDocumentPending}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={3}>
            <InfoBoxStepFour />
          </Grid.Col>
        </Grid>
      </Paper>
      <ButtonBar
        onNext={() => {
          checkProject();
          setCheckClicked(true);
        }}
        onCancel={goToDashboard}
        cancelButtonLabel={t('cancel')}
        nextButtonLabel={t('next')}
        isLoading={isChecking}
        isNextButtonDisabled={!isNextButtonEnabled}
      />
    </>
  );
};
