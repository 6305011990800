import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import cn from 'classnames';

import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { Accordion } from 'common/components/Accordion';
import { Button } from 'common/components/Button';
import { useTranslation } from 'i18n';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { CustomerSection } from 'order/productGroups/common/components/molecule/CustomerSection';
import { JobTitleSection } from 'order/productGroups/common/components/molecule/JobTitleSection';
import { TimeCitySection } from 'order/productGroups/common/components/molecule/TimeCitySection';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { AccordionItemOtherPartners } from 'order/orderChange/common/components/AccordionItems/OtherPartners';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn/MarginalColumn';
import iconAccountDelivery from 'assets/icon-account-delivery.svg';
import orderClasses from 'common/styles/order.module.css';
import { useBoolean } from 'common/hooks/useBoolean';
import { ContactSection } from 'order/productGroups/common/components/molecule/ContactSection';
import { LetterBaseProduct, OrderResponse } from 'order/common/context/order/dtos/GetOrder';
import { deepClone } from 'common/utils/deepClone';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode } from 'order/common/dtos/OrderMode';

import {
  DeliveryOption,
  labelForDeliveryOption,
  mapDeliveryOptionToOtherAttribute,
  mapOtherAttributeToDeliverOption
} from 'common/dtos/DeliveryOption';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import useError from 'common/hooks/useError';
import { PackagingSection } from 'order/productGroups/common/components/molecule/PackagingSection';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { useBaseProducts } from '../../../../common/hooks/useBaseProducts';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { useAlerts } from 'common/AlertContext';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';

import PlusIcon from 'assets/icons/plus.svg';
import styles from './LetterStep2.module.css';
import { sortLetterTypeList } from 'order/common/context/order/dtos/Order';
import { SubProductsSection } from 'order/productGroups/dialogMarketing/components/SubProductsSection';
import { BaseProductsSection } from '../../components/BaseProductsSection/BaseProductsSection';
import { LetterOrderCreate, validateLetterOrderCreate } from '../../schemea/LetterSchema';
import { MachineCodeInput } from '../../components/MachineCodeInput/MachineCodeInput';
import { validateDestinationsInBaseProducts } from '../../utils/validateDestinationsInBaseProducts';
import { useDVBriefProducts } from '../../utils/useDVBriefProducts';

const inputDisabled = false;
const defaultLetterBaseProduct: LetterBaseProduct = {
  id: new Date().getTime(),
  baseProduct: {
    id: new Date().getTime(),
    productNumber: '',
    quantity: 0
  },
  destinations: [
    {
      from: 0,
      to: 0,
      zip: 'DE',
      quantity: 0
    }
  ]
};

export const LetterStep2: FC = () => {
  const { previousStep, nextStep } = useOrderStep();
  const { addAlert } = useAlerts();
  const { setOrder, order, orderMode, isDirty, setDirty, orderCategory, upsertMetaData, meta } = useOrderContext();
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const language = useAuthContext().user.language;
  const { getValues, setValue, trigger, control, setError } = useFormContext<LetterOrderCreate>();
  const [_, setUpdated] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [isMachineCodeInvalid, setIsMachineCodeInvalid] = useState(false);
  const [triggerTS, setTriggerTS] = useState(0);
  const currentOriginator = useRef<string | undefined>(undefined);
  const currentOrder = useRef<OrderResponse | undefined>(undefined);
  const currentSelectedProducts = useRef<string[]>([]);
  const disabledByDeliveryPlan = useRef<boolean>(true);
  const currentDeliveryOption = useRef<DeliveryOption | undefined>(DeliveryOption.EMPTY);
  const currentLetterBaseProducts = useRef<LetterBaseProduct[]>([
    {
      ...deepClone(defaultLetterBaseProduct),
      id: new Date().getTime()
    }
  ]);
  const currentMachineCode = useRef('');
  const initialMachineCode = useRef('');

  const [productGroup, postageMachineCode, orderLabel, selectedDate, takeOriginatorAsSubmitter, originatorUcp] = useWatch({
    control,
    name: ['productGroup', 'postageMachineCode', 'orderLabel', 'details.date', 'takeOriginatorAsSubmitter', 'originator']
  });
  const { setErrors, getError, clearError } = useError();
  const isDVBrief = order?.orderCategory === OrderCategory.DV_BRIEF;
  const orderModeChange = orderMode === OrderMode.CHANGE;
  const baseProducts = useBaseProducts(order?.letterProductGroup || ProductGroup.BRIEF, selectedDate);
  const {
    dvBrief,
    dvInternational,
    dvPZA,
    dvEPostBrief,
    dvStrip,
    dvBook,
    dvBlind,
    dvDVD,
    dvEPostBriefWithPartialService,
    dvInternationalList,
    dvBriefList,
    dvEPostBriefList,
    dvBlindList,
    dvDVDList
  } = useDVBriefProducts(baseProducts, order?.letterBaseProducts, isDVBrief);

  // Delivery Option Handling - Should only be shown if the order is a DV Brief or if the order is a E-PostBrief with a partial service capable base product
  const isDeliveryOptionAvailable = dvEPostBriefWithPartialService || dvBrief || !isDVBrief;

  // Aditional Services Handling - Should not be shown if the order is not PZA or Book or not a DV Brief
  const isAdditionalServiceAvailable = (!dvPZA && !dvBook) || !isDVBrief;

  useEffect(() => {
    upsertMetaData('isDeliveryOptionAvailable', isDeliveryOptionAvailable);
    upsertMetaData('isAdditionalServiceAvailable', isAdditionalServiceAvailable);
  }, [isAdditionalServiceAvailable, isDeliveryOptionAvailable]);

  useEffect(() => {
    if (order?.pendingIds) {
      addAlert({
        type: AlertTypes.Warning,
        description: t(`notifications.pendingIdsWarning`),
        title: t('notifications.warning')
      });
    }
  }, []);

  useEffect(() => {
    setDirty(true);
  }, []);

  useEffect(() => {
    if (!loaded) {
      currentOrder.current = order;
      if (order) {
        if (orderMode === OrderMode.CHANGE) {
          if (!meta?.baseProductChange) {
            const sortLetterBaseProducts = sortLetterTypeList.map((item) => item.toLowerCase());
            order.letterBaseProducts?.sort((a, b) => {
              const aIndex = sortLetterBaseProducts.indexOf(a.baseProduct?.description?.toLowerCase() || '');
              const bIndex = sortLetterBaseProducts.indexOf(b.baseProduct?.description?.toLowerCase() || '');
              return aIndex - bIndex;
            });
          } else {
            order.letterBaseProducts?.sort(
              (a: string | any, b: string | any) => sortLetterTypeList.indexOf(a.id || '') - sortLetterTypeList.indexOf(b.id || '')
            );
          }
        }

        currentLetterBaseProducts.current =
          order.letterBaseProducts?.map((product, i) => ({
            ...product,
            destinations: product?.destinations?.map((destination, j) => ({
              id: new Date().getTime() + i * 10 + j,
              ...destination,
              zip: destination.zip === 'Q' ? 'DE' : destination.zip
            }))
          })) ?? [];
        currentMachineCode.current = order.postage?.machineCode ?? '';
        initialMachineCode.current = order.postage?.machineCode ?? '';
        currentDeliveryOption.current = order.otherAttributes?.ZOP
          ? mapOtherAttributeToDeliverOption(order.otherAttributes?.ZOP)
          : DeliveryOption.EMPTY;
        setLoaded(true);
      }
      currentSelectedProducts.current = currentLetterBaseProducts.current.map((b) => b.baseProduct?.productNumber || '');
      if (orderMode !== OrderMode.CHANGE && orderMode !== OrderMode.COPY) setLoaded(true);
    }
    if (currentLetterBaseProducts.current) {
      currentLetterBaseProducts.current.forEach((l, i) => {
        l.id = l.id ?? new Date().getTime() + i;
        if (l.destinations?.length) {
          l.destinations.forEach((d) => {
            d.quantity = d.quantity ? +d.quantity : (d.to ?? 0) - (d.from ?? 0) + 1;
            d.zip = d.zip === 'Q' ? 'DE' : d.zip;
            // TODO ugly quick fix since d.quantity is always at least one (see above)
            if (d.quantity > 1) {
              disabledByDeliveryPlan.current = false;
            }
          });
        }
      });
    }
    setValue('deliveryOption', currentDeliveryOption.current);
    setValue('letterBaseProducts', currentLetterBaseProducts.current);
  }, [order, loaded]);

  useEffect(() => {
    if (currentOriginator.current !== originatorUcp) {
      currentOriginator.current = originatorUcp;
      if (takeOriginatorAsSubmitter) {
        setValue('submitter', originatorUcp);
      }
    }
  }, [originatorUcp, takeOriginatorAsSubmitter]);

  const checked = takeOriginatorAsSubmitter;
  const [originatorAsSubmitter, setOriginatorAsSubmitter] = useBoolean(takeOriginatorAsSubmitter);
  const originatorCheckboxConfig = {
    controlName: 'takeOriginatorAsSubmitter',
    name: 'einliefererUbernehmen',
    label: `${oc('alsoAcceptAsConsignor')}`,
    value: originatorAsSubmitter || checked,
    disabled: inputDisabled,
    onChange: () => {
      setOriginatorAsSubmitter.toggle();
      setValue('submitter', originatorAsSubmitter ? '' : getValues('originator'), { shouldValidate: true });
      setValue('takeOriginatorAsSubmitter', !originatorAsSubmitter);
    }
  };

  const handleLetterBaseProductChange = (id: number | string, baseProduct: LetterBaseProduct, index: number) => {
    currentLetterBaseProducts.current.map((b) => {
      if (b.id === id) {
        if (b.destinations?.length) {
          b.destinations.forEach((d) => {
            if (d.quantity) {
              disabledByDeliveryPlan.current = false;
            }
          });
        }
        return baseProduct;
      }
      return b;
    });
    currentSelectedProducts.current = currentLetterBaseProducts.current.map((b) => b.baseProduct?.productNumber || '');

    currentOrder.current = {
      ...(currentOrder.current ?? {}),
      letterBaseProducts: currentLetterBaseProducts.current
    } as any;
    setValue('letterBaseProducts', currentLetterBaseProducts.current);
    setOrder(currentOrder.current);
    clearError(index);
  };

  const handleBaseProductDelete = (id: number | string) => {
    currentLetterBaseProducts.current = currentLetterBaseProducts.current.filter((b) => b.id !== id);
    if (!currentLetterBaseProducts.current.length) {
      disabledByDeliveryPlan.current = true;
      handleAddClick();
    }
    currentSelectedProducts.current = currentLetterBaseProducts.current.map((b) => b.baseProduct?.productNumber || '');

    currentOrder.current = {
      ...(currentOrder.current ?? {}),
      letterBaseProducts: currentLetterBaseProducts.current
    } as any;
    setValue('letterBaseProducts', currentLetterBaseProducts.current);
    setOrder(currentOrder.current);
  };

  const handleAddClick = () => {
    const newFrankItBaseProduct = { ...deepClone(defaultLetterBaseProduct), id: new Date().getTime() };
    currentLetterBaseProducts.current.push(newFrankItBaseProduct);
    setValue('letterBaseProducts', currentLetterBaseProducts.current);
    setUpdated(new Date().getTime());
  };

  const handleMachineCodeChange = (code: string) => {
    currentMachineCode.current = code;
    currentOrder.current = {
      ...(currentOrder.current ?? {}),
      postage: {
        ...(currentOrder.current?.postage ?? {}),
        machineCode: code
      }
    } as any;
    setValue('postageMachineCode', code);
    setOrder(currentOrder.current);
    setUpdated(new Date().getTime());
  };

  const handleDeliveryOptionChange = (o: DeliveryOption | undefined) => {
    currentDeliveryOption.current = o;
    currentOrder.current = {
      ...(currentOrder.current ?? {}),
      otherAttributes: {
        ZOP: mapDeliveryOptionToOtherAttribute(o)
      }
    } as any;
    setValue('deliveryOption', o);
    setOrder(currentOrder.current);
    setUpdated(new Date().getTime());
  };

  const deleteShippingPlans = () => {
    currentLetterBaseProducts.current.forEach((b) => {
      b.destinations = [];
    });
    setValue('letterBaseProducts', currentLetterBaseProducts.current);
  };

  const validateRanges = () => {
    const { valid, validityRange } = validateDestinationsInBaseProducts(currentLetterBaseProducts.current, order?.postage?.frankingIdEncoding);
    validityRange.forEach((validity, i) => setErrors(i, [['OVERLAP_ERROR', validity?.some(Boolean) ?? false]]));
    return valid;
  };

  const validateBaseProducts = () => {
    setTriggerTS(Date.now());
    return !currentLetterBaseProducts.current.some((bP) => !bP.baseProduct?.quantity || bP.baseProduct.quantity > 1e9 - 1);
  };

  return loaded ? (
    <div className={orderClasses.partialServiceWrapper}>
      <div className={orderClasses.formContent}>
        <div className={orderClasses.rowWithSidebar}>
          <main>
            <OrderStepHeadline icon={iconAccountDelivery} alt="Icon Auftragsinhalt">
              {oc('title')}
            </OrderStepHeadline>
            {!isDVBrief && (
              <MachineCodeInput
                required={false}
                onChange={handleMachineCodeChange}
                name="postageMachineCode"
                postageMachineCode={postageMachineCode}
                editing={orderModeChange || orderMode === OrderMode.COPY}
                controlledErrorState={setIsMachineCodeInvalid}
                disabled={!!order?.pendingIds}
              />
            )}
            {!isDVBrief &&
              !postageMachineCode &&
              currentLetterBaseProducts.current.some(
                (base) => base.destinations && base.destinations?.length > 0 && base.destinations[0].from !== 0
              ) && (
                <p className={styles.errorMessage} data-testid="empty-code-warning">
                  {oc('emptyCodeWithShippingPlanError')}
                </p>
              )}
            <OrderStepSection headline={oc('product')}>
              {currentLetterBaseProducts.current.map((b, i) => (
                <React.Fragment key={i}>
                  <BaseProductsSection
                    name="letterBaseProducts"
                    productGroup={productGroup}
                    disabled={order?.pendingIds || isMachineCodeInvalid || (!currentMachineCode.current.length && !postageMachineCode)}
                    selectedDate={selectedDate}
                    machineCode={currentMachineCode.current || postageMachineCode}
                    key={b.id}
                    id={`${b?.baseProduct?.productNumber}${i}`}
                    baseProduct={b}
                    number={i}
                    baseProducts={baseProducts}
                    selectedProducts={currentSelectedProducts.current}
                    onChange={() => handleLetterBaseProductChange(b.id ?? i, b, i)}
                    onDelete={handleBaseProductDelete}
                    disabledByMode={orderModeChange && isDVBrief}
                    isDVBrief={isDVBrief}
                    triggerValidation={triggerTS}
                    postageFrankingIdPrefix={order?.postage?.frankingIdPrefix}
                    meta={{ language }}
                    frankingIdEncoding={order?.postage?.frankingIdEncoding}
                    dvBriefList={dvBriefList}
                    dvEPostBriefList={dvEPostBriefList}
                    dvBlindList={dvBlindList}
                    dvDVDList={dvDVDList}
                    dvInternationalList={dvInternationalList}
                  />
                  {getError(i, 'OVERLAP_ERROR') && <p className={styles.errorMessage}>{oc('overlapErrorMessage')}</p>}
                </React.Fragment>
              ))}
            </OrderStepSection>
            {!isDVBrief && (
              <div className={styles.actionBar}>
                <div className={styles.actionLeft}>
                  <Button
                    label={t('addFurtherBaseProduct')}
                    icon={PlusIcon}
                    sizing="xs"
                    onClick={handleAddClick}
                    className={cn(styles.addDeleteAssignmentButton)}
                    dataTestId={`btn-add-assignment-baseProduct`}
                    disabled={
                      order?.pendingIds ||
                      currentSelectedProducts.current.length === baseProducts.filter((a) => a.partialServiceCapable === true).length
                    }
                  />
                </div>
              </div>
            )}
            {isDeliveryOptionAvailable && (
              <OrderStepSection headline={t('deliveryOption')}>
                <FormRow mode="two-col">
                  <AMDHLSelectSingle
                    name="deliveryOption"
                    data-testid={'deliveryOptionId'}
                    items={[DeliveryOption.EMPTY, DeliveryOption.STANDARD, DeliveryOption.FLEX]}
                    label={t('deliveryOption')}
                    valueToString={labelForDeliveryOption}
                    emptyValue={DeliveryOption.EMPTY}
                    value={currentDeliveryOption.current}
                    onChange={handleDeliveryOptionChange}
                    disabled={order?.pendingIds || (orderMode === OrderMode.CREATE && disabledByDeliveryPlan.current)}
                  />
                </FormRow>
              </OrderStepSection>
            )}
            {currentLetterBaseProducts.current.length !== 0 && isAdditionalServiceAvailable && (
              <SubProductsSection<LetterOrderCreate>
                name="subProducts"
                disabled={order?.pendingIds || isDVBrief || inputDisabled}
                productGroup={ProductGroup.BRIEF}
                selectedDate={new Date() || selectedDate}
                baseProduct={currentLetterBaseProducts.current[0].baseProduct?.productNumber}
              />
            )}
            <JobTitleSection<LetterOrderCreate>
              disabled={order?.pendingIds || inputDisabled}
              hasTargetGroup={false}
              product={ProductGroup.BRIEF}
              orderLabel={orderLabel}
            />
            {/* {isDVBrief && <PackagingSection<LetterOrderCreate> name="packaging" disabled={order?.pendingIds || inputDisabled} />} */}
            <CustomerSection<LetterOrderCreate>
              headline={oc('sender')}
              inputName="originator"
              placeholder={`${oc('customerNumber')}`}
              disabled={order?.pendingIds || orderModeChange || isDVBrief || inputDisabled}
              customerRole={OrderSearchKey.Originator}
              errorMessage={t('error.customerIdUnknown')}
              checkboxConfig={originatorCheckboxConfig}
            />
            <CustomerSection<LetterOrderCreate>
              headline={oc('consignor')}
              inputName="submitter"
              disabled={inputDisabled || (takeOriginatorAsSubmitter && !isDVBrief)}
              customerRole={OrderSearchKey.Submitter}
              errorMessage={t('error.customerIdUnknown')}
            />
            <ContactSection<LetterOrderCreate> name="contactForQuestions" disabled={order?.pendingIds || inputDisabled} />
            <TimeCitySection<LetterOrderCreate>
              name="details"
              disabled={order?.pendingIds || inputDisabled}
              selectedProductGroup={ProductGroup.BRIEF}
              disabledDate={false}
              disabledTime={isDVBrief}
              disabledPlace={isDVBrief && order.productionState !== 'PLA'}
            />
            <OrderStepSection>
              <Accordion allowMultiple={true}>
                {(register) => (
                  <>
                    <AccordionItemOtherPartners name={'otherPartners'} id={1} register={register} disabled={order?.pendingIds} />
                  </>
                )}
              </Accordion>
            </OrderStepSection>
          </main>
          <MarginalColumn orderCategory={order?.orderCategory} />
        </div>
      </div>
      <footer className={cn('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            dataTestId="btnBack"
            label={t('buttons.back')}
            type="button"
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          variant="primary"
          dataTestId="btnSubmit"
          disabled={order?.pendingIds}
          onClick={async () => {
            // Werden Sendungsmengen im Versandplan geändert, so wird die Gesamtsumme in Sendungsmenge eingetragen. Ist dort ein Wert vorhanden, wird dieser überschrieben.
            // Pflichtfelder müssen ausgefüllt sein (Basisprodukt, Sendungsmenge, Einlieferer, Einlieferungsdatum, Einlieferungsstelle, Bei Eingabe eines weiteren Partners muss die Kundennummer hinterlegt werden) um in den nächsten WFS zu springen
            const isContentValid = await trigger([
              'submitter',
              'details.date',
              'details.productionPlantId',
              'details.time',
              'packaging.type',
              'packaging.quantity'
            ]);
            const isBaseProductValid = validateBaseProducts();
            const areDependentFieldsValid = validateLetterOrderCreate(getValues(), setError, 2, isDVBrief);
            const isRangeValid = validateRanges();
            const isValidMachineCode = !isMachineCodeInvalid || currentMachineCode.current === '';
            const isOrderLabelValid = orderLabel ? orderLabel?.length <= 80 : true;
            if (isValidMachineCode && isContentValid && areDependentFieldsValid && isRangeValid && isBaseProductValid && isOrderLabelValid) {
              if (!isDVBrief && currentMachineCode.current === '') deleteShippingPlans();
              nextStep();
            }
          }}
          label={t('buttons.continue')}
          type="button"
        />
      </footer>
    </div>
  ) : null;
};
