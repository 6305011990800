/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLFormGroup, DHLHeadline, DHLTextOutput } from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { ResponseplusConstants } from "../../../../constants/ResponseplusConstants";
import { ResponseplusOrderDetailStore } from "../../../../stores/ResponseplusOrderDetailStore";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";
import { getValue } from "./values";

export type ResponseplusReturnAddressPanelProps = {
  orderStore: ResponseplusOrderStore | ResponseplusOrderDetailStore;
}

export const ResponseplusReturnAddressPanel = observer(({orderStore}: ResponseplusReturnAddressPanelProps) => {
  const {resourceDataStore} = orderStore.baseStore;

  function printConditionalAddressData() {
    switch (getValue(orderStore.returnAddressFormField)) {
      case (ResponseplusConstants.addressType.regular.toString()):
        return <>
          <DHLTextOutput value={getValue(orderStore.addressStreetFormField) + " " + getValue(orderStore.addressHouseNumberFormField)} />
          <DHLTextOutput value={getValue(orderStore.addressPostcodeFormField) + " " + getValue(orderStore.addressCityFormField)} />
        </>;
      case (ResponseplusConstants.addressType.freeInput.toString()):
        return <>
          <DHLTextOutput value={getValue(orderStore.addressAddition3FormField)} />
          <DHLTextOutput value={getValue(orderStore.addressAddition4FormField)} />
          <DHLTextOutput value={getValue(orderStore.addressAddition5FormField)} />
          <DHLTextOutput value={getValue(orderStore.addressPostcodeFormField) + " " + getValue(orderStore.addressCityFormField)} />
        </>;
      case (ResponseplusConstants.addressType.largeReceiver.toString()):
        return <>
          <DHLTextOutput value={getValue(orderStore.addressPostcodeFormField) + " " + getValue(orderStore.addressCityFormField)} />
        </>;
      case (ResponseplusConstants.addressType.poBox.toString()):
        return <>
          <DHLTextOutput value={resourceDataStore.getLabel("order.page3-orderconfirm.poBox") + getValue(orderStore.addressPoBoxFormField)} />
          <DHLTextOutput value={getValue(orderStore.addressPostcodeFormField) + " " + getValue(orderStore.addressCityFormField)} />
        </>;
      default:
        return <></>;
    }
  }

  return (
      <DHLFormGroup>
        <DHLFormGroup>
          <DHLHeadline type={"h6"}>
            {resourceDataStore.getLabel("order.page3-orderconfirm.returnAddress")}
          </DHLHeadline>
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextOutput value={getValue(orderStore.addressCompanyNameFormField)} />
          <DHLTextOutput value={getValue(orderStore.addressAddition1FormField)} />
          <DHLTextOutput value={getValue(orderStore.addressAddition2FormField)} />
          {printConditionalAddressData()}
        </DHLFormGroup>
      </DHLFormGroup>
  );
});