/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { DateRangePickerDataStore, DHLDateRangeInputField } from "../../..";
import { DHLDateRangePicker } from "../DHLDateRangePicker/DHLDateRangePicker";
import "./DHLDateRangeInput.scss";
import { useInRouterContext } from "react-router-dom";
import { reaction } from "mobx";
import { useSharedSearchParams } from "../../../utils/useSharedSearchParams";

export type DHLDateRangeInputProps = {
  /** Name des Eingabefeldes. Sollte mit dem Namen des Properties für den Eingabewert übereinstimmen, um einen
   *  einzigen Change-Handler verwenden zu können. Wird auch für die Generierung der Test-ID ververwendet. */
  name: string;

  /** CSS-Klassen für das Eingabefeld. */
  className?: string;

  /** Label mit Pflichtfeldmarkierung versehen? */
  required?: boolean;

  /** Callback für den Übernehmen-Button des DHLDateRangePicker */
  onSubmit?: () => void;

  /** Callback für den Abbrechen-Button und andere Abbrechen Methoden des DHLDateRangePicker */
  onCancel?: () => void;

  dataStore: DateRangePickerDataStore;

  /** If set to true no picker panels will be shown and only the keyboard can be used to set dates */
  keyboardOnlyMode?: boolean;

  /**
   * Prädikat, dass ein anzuzeigendes Datum übergeben bekommt und zurückgibt, ob der Tag im
   * DatePicker deaktiviert/inaktiv
   * angezeigt werden soll, d.h. auch nicht auswählbar ist.
   * Daten ausserhalb von startDate und endDate sind automatisch nicht auswählbar.
   * Hat kein Einfluss auf die manuelle (textuelle) Eingabe.
   */
  disabledDatePredicate?: (day: moment.Moment) => boolean;

  searchParamNames?: {
    from: string,
    until: string
  }
};

/** Datumseingabe mit integriertem Label. */
export const DHLDateRangeInput = observer(({
                                             name,
                                             className,
                                             required,
                                             dataStore,
                                             onSubmit,
                                             onCancel,
                                             keyboardOnlyMode,
                                             searchParamNames
                                           }: DHLDateRangeInputProps
) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  if (useInRouterContext()) {
    const [searchParams, setSearchParams] = useSharedSearchParams();
    //effect for reading search params
    useEffect(() => {
          if (searchParamNames === undefined) {
            return;
          }
          let searchParamsChanged = false;
          if (searchParams.has(searchParamNames.from)) {
            const startDate = moment(searchParams.get(searchParamNames.from), "YYYY-MM-DD", true);
            if (!startDate.isValid()) {
              searchParams.delete(searchParamNames.from);
              searchParamsChanged = true;
            } else {
              dataStore.setSelectedStartDate(startDate);
            }
          }
          if (searchParams.has(searchParamNames.until)) {
            const endDate = moment(searchParams.get(searchParamNames.until), "YYYY-MM-DD", true);
            if (!endDate.isValid()) {
              searchParams.delete(searchParamNames.until);
              searchParamsChanged = true;
            } else {
              dataStore.setSelectedEndDate(endDate);
            }
          }
          if (searchParamsChanged) {
            setSearchParams(searchParams);
          }
          dataStore.commitSelectedDateAsActive();
        },
        [dataStore, searchParamNames, searchParams, setSearchParams]
    );
    //effect for writing search params
    useEffect(
        () => reaction(
            () => [dataStore.activeStartDate, dataStore.activeEndDate],
            ([activeStartDate, activeEndDate]) => {
              const fromFormatted = activeStartDate.format("YYYY-MM-DD");
              const untilFormatted = activeEndDate.format("YYYY-MM-DD");
              if (searchParamNames !== undefined
                  && (searchParams.get(searchParamNames.from) !== fromFormatted || searchParams.get(searchParamNames.until) !== untilFormatted)
              ) {
                searchParams.set(searchParamNames.from, activeStartDate.format("YYYY-MM-DD"));
                searchParams.set(searchParamNames.until, activeEndDate.format("YYYY-MM-DD"));
                setSearchParams(searchParams);
              }
            }
        ),
        [dataStore, searchParamNames, searchParams, setSearchParams]
    );
  }

  const wrapperRef: MutableRefObject<any> = useRef(null);

  const onDateRangePickerCancel = useCallback(() => {
    setShowDateRangePicker(false);
    dataStore.setValidationError(undefined);
    dataStore.adjustPickerToActiveDates();
    dataStore.setIsSubmitActive(false);
    onCancel?.();
  }, [dataStore, setShowDateRangePicker, onCancel]);

  const onDateRangePickerSubmit = useCallback(() => {
    setShowDateRangePicker(false);
    dataStore.commitSelectedDateAsActive();
    if (onSubmit) {
      onSubmit();
    }
  }, [dataStore, setShowDateRangePicker, onSubmit]);

  const _onEscapePressed = useCallback((event: KeyboardEvent) => {
    if (showDateRangePicker && event.key === "Escape") {
      onDateRangePickerCancel();
    }
  }, [showDateRangePicker, onDateRangePickerCancel]);

  const _onClickOutside = useCallback((event: MouseEvent) => {
    if (showDateRangePicker && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onDateRangePickerCancel();
    }
  }, [showDateRangePicker, onDateRangePickerCancel, wrapperRef]);

  useEffect(() => {
    document.addEventListener("keydown", _onEscapePressed);
    document.addEventListener("mousedown", _onClickOutside);

    return () => {
      document.removeEventListener("keydown", _onEscapePressed);
      document.removeEventListener("mousedown", _onClickOutside);
    };
  }, [_onClickOutside, _onEscapePressed]);

  const openPicker = () => {
    dataStore.adjustPickerToActiveDates();
    setShowDateRangePicker(true);
  };

  return (
      <div data-testid={"date-range-input-" + name} ref={wrapperRef} className={classNames("date-range-input-container")}>
        <DHLDateRangeInputField name={name}
                                dataStore={dataStore}
                                disabled={false}
                                className={classNames(className, showDateRangePicker ? "pickerOpen" : null)}
                                label={dataStore.resourceDataStore.getLabel("dateRangePicker.period")}
                                onFocus={openPicker}
                                required={required}
                                onCalendarInputFieldClick={openPicker}
                                onChangeStartDate={dataStore.onChangeStartDate}
                                onChangeEndDate={dataStore.onChangeEndDate}
        />
        {showDateRangePicker &&
            <div className={classNames("dhl-date-range-input-picker-anchorpoint")}>
              <DHLDateRangePicker
                  dataStore={dataStore}
                  name={name}
                  onCancel={onDateRangePickerCancel}
                  onSubmit={onDateRangePickerSubmit}
                  keyboardOnlyMode={keyboardOnlyMode}
              />
            </div>}
      </div>
  );
});
