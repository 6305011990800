import React, { useLayoutEffect, useState, VFC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CustomTextInput } from '../CustomTextInput';
import { determineLetterTypeByFormat } from './helper/determineLetterTypeByFormat';
import { Button } from 'common/components/Button/Button';
import { QtyAllocation } from '../../context/order/dtos/Order';
import { OrderReference } from '../../context/order/dtos/OrderReference';
import { OrderMode } from '../../dtos/OrderMode';
import i18n from 'i18n';
import styles from './qty-allocation.module.css';

import transDE from './i18n/translate.de.json';
import transEN from './i18n/translate.en.json';
import { LetterTypes } from './dtos/LetterTypes';
import { maxiBriefDiscountAvailable } from 'order/productGroups/additionalOrders/utils/maxiBriefDeliveryDiscountDiscontinuedDate';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { ExpandedAllocation } from './ExpandedAllocation';
import { DeliveryOptionZATL } from 'common/dtos/DeliveryOption';

interface Props {
  data: QtyAllocation;
  orderReference: OrderReference | null;
  language: string;
  id: string;
  onChange: (productNumber?: string, qty?: number) => void;
  onError: (format: string, error: boolean) => void;
  onIdAssignmentClick: (productNumber: string, format: string, transitTime: string[]) => void;
  orderMode?: OrderMode;
  disabled?: boolean;
  readOnly?: boolean;
  selectedDate?: Date;
  frankingIdVisible?: boolean;
  setFrankingIdVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewQtyAllocationRow: VFC<Props> = (props) => {
  const { meta, order, orderMode, allocatedDiscounts, allocationsOrders } = useOrderContext();
  const { t } = useTranslation('shipmentAssignment');
  const [disabled, setDisabled] = useState(!props.data.reducible);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(props.data.initialQty ? props.data.quantity : 0);
  i18n.addResourceBundle('de', 'shipmentAssignment', transDE);
  i18n.addResourceBundle('en', 'shipmentAssignment', transEN);
  const letterType = determineLetterTypeByFormat(props.data.format);

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredValue = e.target.value;
    const qty = props.data.initialQty;

    if (isNaN(parseInt(enteredValue)) || enteredValue === '') {
      setValue(0);
      setError(false);
      setDisabled(true);
      props.onError?.(props.data.format, false);
      props.onChange(props.data.productNumber, 0);
    } else if (parseInt(enteredValue) <= 0) {
      setValue(0);
      setError(false);
      setDisabled(true);
      props.onError?.(props.data.format, false);
      props.onChange(props.data.productNumber, 0);
    } else if (qty && parseInt(enteredValue) > qty) {
      setValue(qty);
      setError(true);
      setDisabled(true);
      props.onError?.(props.data.format, true);
    } else {
      setValue(parseInt(enteredValue));
      setError(false);
      setDisabled(false);
      props.onError?.(props.data.format, false);
      props.onChange(props.data.productNumber, parseInt(enteredValue));
    }
  };

  const orderDateForOrderview = order?.orderDetail?.date ? new Date(order?.orderDetail?.date) : undefined;
  const disableAssignIdButton = props.data.multipleContainsIds || disabled || !value;

  const currentAllocatedOrderId = allocatedDiscounts.find((a) => a.orderId === props.orderReference?.orderId)?.qtyAllocation;
  const allocatedOrderIdRefShipmentId = currentAllocatedOrderId?.filter((a) => a.refShipmentId.toString() === props.data.refShipmentId.toString());
  const DeliveryOptionList: string[] = [];

  if (allocatedOrderIdRefShipmentId) {
    if (allocatedOrderIdRefShipmentId.find((q) => q.containsIds === false && q.flexOption === false && q.quantity)) {
      DeliveryOptionList.push(DeliveryOptionZATL.STANDARD);
    }

    if (allocatedOrderIdRefShipmentId.find((q) => q.containsIds === true && q.flexOption === true && q.quantity)) {
      DeliveryOptionList.push(DeliveryOptionZATL.FLEX);
    }

    if (allocatedOrderIdRefShipmentId.find((q) => q.containsIds === true && q.flexOption === false && q.quantity)) {
      DeliveryOptionList.push(DeliveryOptionZATL.SCHNELL);
    }
  }

  const handleOnClick = () => {
    props.onIdAssignmentClick(props.data.productNumber || '', props.data.format, DeliveryOptionList);
  };

  const getByOrderID = allocationsOrders.find((a) => a.data.orderNumber === props.orderReference?.orderId);

  const getFormatName = getByOrderID?.data.letterBaseProducts?.find(
    (a) => a.baseProduct?.referenceShipmentId?.toString() === props.data.refShipmentId.toString()
  )?.baseProduct?.description;

  return (
    <>
      <tr id={props.id} data-testid={`TableRow-${props.id}`} key={props.id}>
        <td className={classNames(styles.letterTypes, styles.borderLeft)}>
          {orderMode === OrderMode.VIEW ? (
            <p>
              {letterType === LetterTypes.Maxi && !maxiBriefDiscountAvailable(orderDateForOrderview) ? 'Maxibrief (Laufzeit E+1)' : getFormatName}
            </p>
          ) : (
            <p>{letterType === LetterTypes.Maxi && !maxiBriefDiscountAvailable(meta?.pickUpDate) ? 'Maxibrief (Laufzeit E+1)' : getFormatName}</p>
          )}
        </td>
        <td className={classNames(styles.qtyTD)}>
          {DeliveryOptionList?.length
            ? Array.from(new Set(DeliveryOptionList.map((a) => t(`${a.toLocaleLowerCase()}`)).sort())).join('/')
            : t(`${DeliveryOptionZATL.NONE.toLocaleLowerCase()}`)}
        </td>
        <td className={classNames(styles.qtyTD)}>{props.data.initialQty ? props.data.initialQty.toLocaleString(props.language) : '0'}</td>
        {props.readOnly || props.disabled ? (
          <>
            <td className={classNames(styles.qtyTD, styles.borderRight)}>
              {props.data.quantity ? props.data.quantity.toLocaleString(props.language) : '0'}
            </td>
            {/* eslint-disable */}
            <td
              className={classNames(styles.width35, styles.borderRight)}
              onClick={() => {
                props.setFrankingIdVisible((prevState) => !prevState);
              }}
              style={{ cursor: 'pointer', borderBottom: 'none' }}
            ></td>

            {props.frankingIdVisible && (
              <>
                <ExpandedAllocation currentAllocation={allocatedOrderIdRefShipmentId} language={props.language} />
              </>
            )}
          </>
        ) : (
          <>
            <td className={styles.borderRight}>
              <CustomTextInput
                key={props.id}
                value={props.data.quantity ? props.data.quantity : value ? value : '0'}
                onBlur={handleOnBlur}
                type={'text'}
                id={`input-${props.id}-${letterType}`}
                min={'0'}
                testid={`input-${props.id}`}
                disabled={!props.data.initialQty}
                rules={{ regex: /^[0-9]+$/ }}
                className={styles.qtyAllocationInput}
              />
            </td>
            {/* eslint-disable */}
            <td
              className={classNames(styles.width35, styles.borderRight)}
              onClick={() => {
                props.setFrankingIdVisible((prevState) => !prevState);
              }}
              style={{ cursor: 'pointer', borderBottom: 'none' }}
            ></td>

            {props.frankingIdVisible && (
              <>
                <ExpandedAllocation currentAllocation={allocatedOrderIdRefShipmentId} language={props.language} />
              </>
            )}
            <td className={classNames('pr-0', styles.whitesmokeBGColor, styles.borderNone, styles.assignIdButton)}>
              <Button
                key={props.id}
                sizing="md"
                onClick={handleOnClick}
                disabled={disableAssignIdButton}
                dataTestId={`btn-${props.id}`}
                JSXIcon={
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" fill="none" stroke={disableAssignIdButton ? '#cdcdcd' : '#D40511'} strokeWidth="2" />
                    <circle cx="6" cy="12" r="1.5" fill={disableAssignIdButton ? '#cdcdcd' : '#D40511'} />
                    <circle cx="12" cy="12" r="1.5" fill={disableAssignIdButton ? '#cdcdcd' : '#D40511'} />
                    <circle cx="18" cy="12" r="1.5" fill={disableAssignIdButton ? '#cdcdcd' : '#D40511'} />
                  </svg>
                }
              />
            </td>
          </>
        )}
      </tr>
      {error && (
        <tr className={classNames(styles.whitesmokeBGColor, styles.errorRow)} data-testid={'qar-exceeded-qty-error'}>
          <td colSpan={4} className={styles.errorMessage}>
            {t('error.exceededQtyError')}
          </td>
        </tr>
      )}
    </>
  );
};
