// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShipmentQuantityFeeReductionFourColumn_spinner__s8zmq{
    padding: 25px;
}

.ShipmentQuantityFeeReductionFourColumn_borderRight__U0bwH{
    border-right: 1px solid #d1d1d1;
}

.ShipmentQuantityFeeReductionFourColumn_textRight__U5IP\\+{
    text-align: right;
}

.ShipmentQuantityFeeReductionFourColumn_borderTop__OCGPr{
    border-top: 2px solid #d1d1d1;
}

.ShipmentQuantityFeeReductionFourColumn_borderBottom__KxoU9{
    border-bottom: 1px solid #d1d1d1;
}

.ShipmentQuantityFeeReductionFourColumn_alignDvColumns__VhV60{
    flex: 4 1;
    border-top: 0 !important;
    text-align: right;
    padding: 0 !important;
}
.ShipmentQuantityFeeReductionFourColumn_alignAfmColumns__7qsDb{
    flex: 6 1;
    border-top: 0 !important;
    text-align: right;
    padding: 0 !important;
}
.ShipmentQuantityFeeReductionFourColumn_alignDvHeader__U8hUQ{
    padding-left: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/order/productGroups/additionalOrders/components/molecule/ShipmentQuantityFeeReductionFourColumn/ShipmentQuantityFeeReductionFourColumn.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,SAAO;IACP,wBAAwB;IACxB,iBAAiB;IACjB,qBAAqB;AACzB;AACA;IACI,SAAO;IACP,wBAAwB;IACxB,iBAAiB;IACjB,qBAAqB;AACzB;AACA;IACI,6BAA6B;AACjC","sourcesContent":[".spinner{\n    padding: 25px;\n}\n\n.borderRight{\n    border-right: 1px solid #d1d1d1;\n}\n\n.textRight{\n    text-align: right;\n}\n\n.borderTop{\n    border-top: 2px solid #d1d1d1;\n}\n\n.borderBottom{\n    border-bottom: 1px solid #d1d1d1;\n}\n\n.alignDvColumns{\n    flex: 4;\n    border-top: 0 !important;\n    text-align: right;\n    padding: 0 !important;\n}\n.alignAfmColumns{\n    flex: 6;\n    border-top: 0 !important;\n    text-align: right;\n    padding: 0 !important;\n}\n.alignDvHeader{\n    padding-left: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `ShipmentQuantityFeeReductionFourColumn_spinner__s8zmq`,
	"borderRight": `ShipmentQuantityFeeReductionFourColumn_borderRight__U0bwH`,
	"textRight": `ShipmentQuantityFeeReductionFourColumn_textRight__U5IP+`,
	"borderTop": `ShipmentQuantityFeeReductionFourColumn_borderTop__OCGPr`,
	"borderBottom": `ShipmentQuantityFeeReductionFourColumn_borderBottom__KxoU9`,
	"alignDvColumns": `ShipmentQuantityFeeReductionFourColumn_alignDvColumns__VhV60`,
	"alignAfmColumns": `ShipmentQuantityFeeReductionFourColumn_alignAfmColumns__7qsDb`,
	"alignDvHeader": `ShipmentQuantityFeeReductionFourColumn_alignDvHeader__U8hUQ`
};
export default ___CSS_LOADER_EXPORT___;
