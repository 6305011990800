import React, { ReactElement } from 'react';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { useDialogPostClusterCombinations } from 'order/productGroups/dialogMarketing/hooks/useDialogPostClusterCombinations';

export interface SurchargeSectionProps {
  name: string;
  disabled?: boolean;
  productGroup?: ProductGroup;
  selectedDate?: Date;
  baseProduct?: string;
  orderMode?: OrderMode;
}

export const SurchargeSection = ({ productGroup, selectedDate, baseProduct, orderMode, ...props }: SurchargeSectionProps): ReactElement => {
  const { seasonalSurcharge } = useDependentProducts(productGroup, selectedDate, baseProduct);
  const { easyAvailable, nichtAutomationsfaehigAvailable } = useDialogPostClusterCombinations();
  const { order } = useOrderContext();
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { t } = useTranslation('translation');

  return (
    <>
      <OrderStepSection headline={oc('surcharge')}>
        <FormRow mode="checkboxes">
          {productGroup !== ProductGroup.POSTAKTUELL ? (
            <>
              {easyAvailable && (
                <Controller
                  name={`${props.name}.easy`}
                  render={({ field }) => (
                    <DHLCheckbox
                      name={field.name}
                      label={'Easy'}
                      value={field.value}
                      onChange={() => field.onChange(!field.value)}
                      disabled={props.disabled}
                    />
                  )}
                />
              )}
              {nichtAutomationsfaehigAvailable && (
                <Controller
                  name={`${props.name}.nichtAutomationsfaehig`}
                  render={({ field }) => (
                    <DHLCheckbox
                      name={field.name}
                      label={oc('unsuitableForAutomaticProcessing')}
                      value={field.value}
                      onChange={() => field.onChange(!field.value)}
                      disabled={props.disabled}
                    />
                  )}
                />
              )}
            </>
          ) : (
            <></>
          )}
          {orderMode === OrderMode.CHANGE &&
            seasonalSurcharge?.find((ss) => order?.dependentProducts?.some((p) => p.productNumber == ss.productNumber)) && (
              <Controller
                name={`${props.name}.seasonalSurcharge`}
                render={({ field }) => (
                  <DHLCheckbox
                    name={field.name}
                    label={t('seasonalSurcharge')}
                    value={field.value}
                    onChange={() => field.onChange(!field.value)}
                    disabled={true}
                  />
                )}
              />
            )}
        </FormRow>
      </OrderStepSection>
    </>
  );
};
