import { LetterTypes, LetterTypesList } from '../dtos/LetterTypes';

export const determineLetterTypeByFormat = (format: string | undefined): LetterTypes | string => {
  let letterType: string = '';
  switch (format) {
    case 'S':
      letterType = LetterTypes.Standard;
      break;
    case 'K':
      letterType = LetterTypes.Compact;
      break;
    case 'G':
      letterType = LetterTypes.Large;
      break;
    case 'M':
      letterType = LetterTypes.Maxi;
      break;
    case 'P':
      letterType = LetterTypes.Postcard;
      break;
    default:
      break;
  }
  return letterType;
};

const getLetterType = (format: string, formatName: (string | undefined)[], baseType: LetterTypesList, bzlType: LetterTypesList): LetterTypesList => {
  return formatName.some((a) => a?.charAt(0).toUpperCase() === format && a?.endsWith('BZL GK')) ? bzlType : baseType;
};

export const determineLetterTypeByFormatCount = (format: string | undefined, formatName?: (string | undefined)[]): LetterTypesList | string => {
  if (!format || !formatName) return 'Unknown format';

  switch (format) {
    case 'S':
      return getLetterType(format, formatName, LetterTypesList.Standard, LetterTypesList.StandardBZL);
    case 'K':
      return getLetterType(format, formatName, LetterTypesList.Compact, LetterTypesList.CompactBZL);
    case 'G':
      return getLetterType(format, formatName, LetterTypesList.Large, LetterTypesList.LargeBZL);
    case 'M':
      return getLetterType(format, formatName, LetterTypesList.Maxi, LetterTypesList.MaxiBZL);
    case 'P':
      return getLetterType(format, formatName, LetterTypesList.Postcard, LetterTypesList.PostcardBZL);
    default:
      return 'Unknown format';
  }
};
