import styles from './pslbilling-table.module.css';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { MoneyDT } from 'generated';
import { useAuthContext } from '../../../../common/context/auth/AuthContext';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';
import { useOrderContext } from 'order/common/context/order/OrderContext';
type PSLBillingTableSumRowProps = {
  partialTotalQty: number;
  partialTotalPrice: number;
  idTotalQty: number;
  idTotalPrice: number;
  termTotalQty: number;
  termTotalPrice: number;
  unitAmountCol: boolean;
  currency: string;
  totalFeeReductionWithoutVAT?: MoneyDT;
  totalFeeReductionWithVAT?: MoneyDT;
};
export const PSLBillingTableSumRow: VFC<PSLBillingTableSumRowProps> = (props) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  const { order } = useOrderContext();
  const { user } = useAuthContext();

  const isFirstOfJan25 = order?.orderDetail?.date ? isAvailableFromJan25(new Date(order?.orderDetail?.date)) : false;

  return (
    <>
      <tr key={'row-data-summary'} className={classNames(styles.parentRow, 'dhlTable-row dhlTable-row-default leaf', styles.lastNoPadding)}>
        <td></td>
        <td className={props.unitAmountCol ? '' : styles.addBorderRight}>{t('total')}</td>
        {props.unitAmountCol && <td className={styles.addBorderRight}></td>}
        <td className={styles.rightAlign}>{props.partialTotalQty.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
        <td></td>
        <td className={classNames(styles.rightAlign, styles.addBorderRight)}>
          {props.partialTotalPrice.toLocaleString(user.language, {
            style: 'currency',
            currency: props.currency || 'EUR',
            maximumFractionDigits: 3
          })}
        </td>
        <td className={styles.rightAlign}>{props.idTotalQty.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
        <td></td>
        <td className={classNames(styles.rightAlign, styles.addBorderRight)}>
          {props.idTotalPrice.toLocaleString(user.language, {
            style: 'currency',
            currency: props.currency || 'EUR',
            maximumFractionDigits: 3
          })}
        </td>
        <td className={styles.rightAlign}>{props.termTotalQty.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
        <td></td>
        <td className={classNames(styles.rightAlign, styles.addBorderRight)}>
          {props.termTotalPrice.toLocaleString(user.language, {
            style: 'currency',
            currency: props.currency || 'EUR',
            maximumFractionDigits: 3
          })}
        </td>
        <td className={styles.rightAlign}>
          {(props.partialTotalPrice + props.idTotalPrice + props.termTotalPrice).toLocaleString(user.language, {
            style: 'currency',
            currency: props.currency || 'EUR',
            maximumFractionDigits: 3
          })}
        </td>
      </tr>

      {!isFirstOfJan25 ? (
        <>
          <tr className={styles.parentRow}>
            <td
              className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight, styles.addBorderRight)}
              colSpan={props.unitAmountCol ? 12 : 11}
            >
              {t('totalPartialServiceDiscountWithoutVAT')}
            </td>
            <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)}>
              {(props.totalFeeReductionWithoutVAT?.value as number).toLocaleString(user.language, {
                style: 'currency',
                currency: props.totalFeeReductionWithoutVAT?.currency || 'EUR',
                maximumFractionDigits: 3
              })}
            </td>
          </tr>{' '}
          <tr className={styles.parentRow}>
            <td
              className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight, styles.addBorderRight)}
              colSpan={props.unitAmountCol ? 12 : 11}
            >
              {t('VAT')}
            </td>
            <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)}>
              {((props.totalFeeReductionWithVAT?.value as number) - (props.totalFeeReductionWithoutVAT?.value as number)).toLocaleString(
                user.language,
                {
                  style: 'currency',
                  currency: props.totalFeeReductionWithoutVAT?.currency || 'EUR',
                  maximumFractionDigits: 3
                }
              )}
            </td>
          </tr>
        </>
      ) : (
        <></>
      )}
      <tr className={styles.parentRow}>
        <th
          className={classNames(styles.rightAlign, styles.removeBorder, styles.addBorderBottom, styles.summaryRowHeight, styles.addBorderRight)}
          colSpan={props.unitAmountCol ? 12 : 11}
        >
          {isFirstOfJan25 ? t('totalPartialServiceDiscount') : t('totalPartialServiceDiscountWithVAT')}
        </th>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.addBorderBottom, styles.summaryRowHeight)}>
          {(props.totalFeeReductionWithVAT?.value as number).toLocaleString(user.language, {
            style: 'currency',
            currency: props.totalFeeReductionWithVAT?.currency || 'EUR',
            maximumFractionDigits: 3
          })}
        </td>
      </tr>
    </>
  );
};
