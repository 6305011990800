/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ReportingProactive, ReportingProactiveRootProps } from "@gkuis/gkp-reportingproactive-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationReportingProactive(envConfig: EnvConfig) {
  ReportingProactive.config = {
    baseUri: `${process.env.REPORTINGPROACTIVE_BASE_URI}`,
    cmsContentRoot: envConfig.cmsContentRoot
  };
}

export function registerApplicationReportingProactive(authenticationManager: AuthenticationManager) {
  const reportingProactiveRootProps: ReportingProactiveRootProps = {
    authenticationManager,
  };

  registerApplication({
    name: "@gkuis/gkp-reportingproactive-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-reportingproactive-ui"),
    activeWhen: ["/reportingproactive"],
    customProps: {
      ...reportingProactiveRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}