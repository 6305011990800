import { OrderReference } from './OrderReference';
import { Order, QtyAllocation } from './Order';
import { BaseProduct, LetterBaseProduct, OrderDetail, Packaging, Party, Postage } from './GetOrder';
import { Occurrence } from 'common/dtos/Occurrence';
import { OrderCategoryProductKey } from './OrderCategoryProductKey';
import { OrderDestination, ShipmentData } from 'generated';
import { MergeOrderVariant } from 'order/orderView/components/OrderMerge/schema/OrderMergeSchema';

export enum Age {
  NEW = 'new',
  OLD = 'old'
}

export interface AllocatedOrderData {
  customerOrderId?: string;
  orderNumber?: string; // orderId
  orderCategory?: string;
  orderCategoryProductKey?: OrderCategoryProductKey;
  includesDestinations?: boolean;
  containsIds?: boolean | Occurrence;
  otherAttributes?: Record<string, string>;
  flexOption?: boolean | Occurrence;
  firstInductionDate?: string;
  productGroup?: string;
  orderLabel?: string;
  payerParticipation?: string;
  product?: string;
  payerProcedure?: string;
  originator?: Party;
  frankierung?: string; // postage
  frankingIdPrefix?: string; // postage
  machineCode?: string;
  totalQty: number;
  initialTotalQty?: number;
  idsQty: number;
  allocatedQty: number;
  includeTotalQty?: Occurrence;
  paymentClearingNumber?: string;
  productionState?: string;
  totalNetWeight?: number;
  totalGrossWeight?: number;
  packagingQty?: number;
  orderType?: string;
  stackable?: boolean;
  parties?: Party[];
  baseProduct?: BaseProduct;
  packaging?: Packaging;
  postage?: Postage;
  orderDetail?: OrderDetail;
  orderReferences?: OrderReference[];
  qtyAllocation?: QtyAllocation[];
  age?: Age;
  totalAmountValue?: number;
  totalAmountCurrency?: string;
  state?: string;
  procedure?: string;
  shipmentData?: ShipmentData[];
  mergeOrderVariants?: MergeOrderVariant[];
  lastChangedOn?: string;
  destinations?: OrderDestination[];
  letterBaseProducts?: LetterBaseProduct[];
}

export interface AllocatedOrder {
  data: AllocatedOrderData;
  age?: Age;
  newlyAdded?: boolean;
  editable?: boolean;
  highlight?: boolean;
  id: number;
  parentOrderDate?: Date;
}
