import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DHLButton, DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';

import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { AvailableProductGroup } from 'order/productGroups/common/utils/orderCreateSchema';
import { Discount, LetterBaseProduct } from 'order/common/context/order/dtos/GetOrder';
import { Product } from 'order/common/context/order/dtos/Product';
import { IdAssignment } from 'order/common/components/QtyAllocation/IdAssignment';
import { CustomTextInput, regexValidator } from 'order/common/components/CustomTextInput';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { qtySumCalculator } from 'order/common/context/order/utils/qtySumCalculator';
import { AdditionalService } from '../AdditionalService/AdditionalService';

import styles from './baseProductsSection.module.css';
import { useBaseProducts } from 'order/common/hooks/useBaseProducts';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useAdditionalServices } from 'order/common/hooks/useAdditionalServices';
import { IdAssignmentReadOnlyTable } from 'order/common/components/QtyAllocation/IdAssignmentReadOnlyTable';
import { isServiceAllowed } from 'order/productGroups/dialogMarketing/utils/isServiceAllowed';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { AFMBaseProduct } from 'order/common/context/order/dtos/Order';

export interface Props {
  id?: string | number;
  number: number;
  disabled?: boolean;
  name?: string;
  productGroup?: AvailableProductGroup;
  selectedDate?: Date;
  baseProduct: LetterBaseProduct;
  selectedProducts: string[];
  onChange?: (id: number | string, baseProduct: LetterBaseProduct) => void;
  onDelete?: (id: number | string) => void;
  machineCode?: string;
  postageFrankingIdPrefix?: string;
  frankingIdEncoding?: string;
  disabledByMode?: boolean;
  isDVBrief?: boolean;
  triggerValidation?: number;
  baseProducts: Product[];
  meta: { language: string };
  dvBriefList?: Product[];
  dvEPostBriefList?: Product[];
  dvBlindList?: Product[];
  dvDVDList?: Product[];
  dvInternationalList?: Product[];
}
export const BaseProductsSection = <T,>({
  disabled,
  productGroup,
  name,
  number,
  isDVBrief,
  selectedDate = new Date(),
  ...props
}: Props): ReactElement | null => {
  const [_, setUpdateAt] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [quantityError, setQuantityError] = useState<string | undefined>(undefined);
  const { orderMode, order, upsertMetaData, orderCategory } = useOrderContext();
  const selectableBaseProducts = useRef<Product[]>([]);
  const { baseProduct, selectedProducts, id } = props;
  const currentBaseProduct = useRef<LetterBaseProduct>(baseProduct);
  const selectedProduct = useRef<Product>({});
  const totalQty = useRef<number>(0);
  const digitalCopy = useRef<boolean>(false);
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { t } = useTranslation('orderCreate');
  const { t: trans } = useTranslation('translation');

  const baseProductOthers = useBaseProducts('OTHER', selectedDate, 'DEPENDENT');
  const digital = baseProductOthers.find((x) => x.variant === 'DIGITAL_COPY_NOTICE' && x.basicFormat === '');
  const discounts = useRef<Discount[]>([...(currentBaseProduct.current.discounts?.map((data) => data) ?? [])]);
  currentBaseProduct.current.discounts = discounts.current;

  const showDigitalCopy =
    props.dvBriefList?.map((a) => a.productNumber).includes(currentBaseProduct.current.baseProduct?.productNumber) ||
    props.dvEPostBriefList?.map((a) => a.productNumber).includes(currentBaseProduct.current.baseProduct?.productNumber) ||
    !isDVBrief;

  const showAdditionalLetterServices =
    props.dvBriefList?.map((a) => a.productNumber).includes(currentBaseProduct.current.baseProduct?.productNumber) ||
    props.dvInternationalList?.map((a) => a.productNumber).includes(currentBaseProduct.current.baseProduct?.productNumber) ||
    props.dvBlindList?.map((a) => a.productNumber).includes(currentBaseProduct.current.baseProduct?.productNumber) ||
    props.dvDVDList?.map((a) => a.productNumber).includes(currentBaseProduct.current.baseProduct?.productNumber);

  useEffect(() => {
    if (!loaded && baseProduct && props.baseProducts.length) {
      // AFM Brief baseProduct are hard coded should be changed soon after getting the data from OTC to differentiate the product and filter out those special product(BZL)
      if (orderCategory === OrderCategory.AFM_BRIEF) {
        selectableBaseProducts.current = props.baseProducts.filter(
          (b) =>
            b.partialServiceCapable === true && AFMBaseProduct.includes(b.productNumber || '') && !selectedProducts.includes(b.productNumber || '')
        );
      } else {
        selectableBaseProducts.current = props.baseProducts.filter(
          (b) => b.partialServiceCapable === true && !selectedProducts.includes(b.productNumber || '')
        );
      }
      if (currentBaseProduct.current.baseProduct && !currentBaseProduct.current.baseProduct?.productNumber.length) {
        currentBaseProduct.current.baseProduct.productNumber = selectableBaseProducts.current[0]?.productNumber ?? '';
        currentBaseProduct.current.baseProduct.description = selectableBaseProducts.current[0]?.descriptionShort ?? '';
        selectedProduct.current = selectableBaseProducts.current[0];
      } else {
        selectedProduct.current = props.baseProducts.find((b) => b.productNumber === currentBaseProduct.current.baseProduct?.productNumber) as any;
      }
      props.onChange?.(props.id ?? 0, currentBaseProduct.current);
      totalQty.current =
        currentBaseProduct.current.baseProduct?.quantity ??
        (qtySumCalculator((currentBaseProduct.current.destinations as any) ?? [], 'quantity') as number);
      setLoaded(true);
    }
  }, [props.baseProducts, id, loaded]);

  useEffect(() => {
    if (currentBaseProduct.current.baseProduct?.productNumber.length) {
      // AFM Brief baseProduct are hard coded should be changed soon after getting the data from OTC to differentiate the product and filter out those special product(BZL)
      if (orderCategory === OrderCategory.AFM_BRIEF) {
        selectableBaseProducts.current = props.baseProducts.filter(
          (b) =>
            b.partialServiceCapable === true && AFMBaseProduct.includes(b.productNumber || '') && !selectedProducts.includes(b.productNumber || '')
        );
      } else {
        selectableBaseProducts.current = props.baseProducts.filter(
          (b) => b.partialServiceCapable === true && !selectedProducts.includes(b.productNumber || '')
        );
      }
      selectedProduct.current = props.baseProducts.find((b) => b.productNumber === currentBaseProduct.current.baseProduct?.productNumber) as any;
      digitalCopy.current = currentBaseProduct.current?.discounts?.some((x) => x.productNumber === digital?.productNumber) ?? false;
    }

    if (order?.letterBaseProducts) {
      if (orderMode === OrderMode.COPY || orderMode === OrderMode.CHANGE) {
        order.letterBaseProducts.forEach((p) => {
          if (p.discounts) {
            p.discounts.some((x) => {
              if (x.productNumber === digital?.productNumber) {
                p.digitalCopy = true;
              }
            });
          }
        });
      }
    }

    setUpdateAt(new Date().getTime());
  }, [selectedProducts]);

  useEffect(() => {
    if (props.triggerValidation) {
      handleQuantityChange({ target: { value: totalQty.current.toString() } } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  }, [props.triggerValidation]);

  const handleSelectionChange = (item: Product | undefined) => {
    if (currentBaseProduct.current.baseProduct) {
      currentBaseProduct.current.baseProduct.productNumber = item?.productNumber ?? '';
      currentBaseProduct.current.baseProduct.description = item?.descriptionShort ?? '';
      selectedProduct.current = props.baseProducts.find((b) => b.productNumber === item?.productNumber) ?? {};
      props.onChange?.(props.id ?? 0, currentBaseProduct.current);
      setUpdateAt(new Date().getTime());
      upsertMetaData('baseProductChange', true);
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^\d+$/;
    if (isNaN(parseInt(value))) {
      setQuantityError(t('error.baseProductInput.quantity.empty'));
    } else if (!regexValidator(value, regex)) {
      setQuantityError(t('error.baseProductInput.quantity.empty'));
    } else if (parseInt(value) === 0) {
      setQuantityError(t('error.baseProductInput.quantity.zero'));
    } else if (parseInt(value) > 1e9 - 1) {
      setQuantityError(t('error.baseProductInput.quantity.tooBig'));
    } else if (parseInt(value) < totalQty.current) {
      setQuantityError(t('error.baseProductInput.quantity.belowIds'));
    } else {
      setQuantityError(undefined);
    }
    if (currentBaseProduct.current.baseProduct) {
      currentBaseProduct.current.baseProduct.quantity = parseInt(e.target.value);
    }
    totalQty.current = parseInt(e.target.value);
    props.onChange?.(props.id ?? 0, currentBaseProduct.current);
    setUpdateAt(new Date().getTime());
  };

  const handleDigitalCopyChange = () => {
    currentBaseProduct.current.digitalCopy = !currentBaseProduct.current.digitalCopy;
    digitalCopy.current = !digitalCopy.current;
    if (digitalCopy.current) {
      if (!discounts.current.includes({ productNumber: digital?.productNumber })) {
        discounts.current?.push({ productNumber: digital?.productNumber });
      }
    } else if (!digitalCopy.current) {
      const index = discounts.current.findIndex((a) => a.productNumber === digital?.productNumber);
      discounts.current.splice(index, 1);
    }
    props.onChange?.(props.id ?? 0, currentBaseProduct.current);
    setUpdateAt(new Date().getTime());
  };
  const formattedSelectedDate = new Date(selectedDate).toLocaleDateString();
  useEffect(() => {
    if (!isServiceAllowed(selectedDate)) {
      currentBaseProduct.current.digitalCopy = false;
      digitalCopy.current = false;
      const index = discounts.current.findIndex((a) => a.productNumber === digital?.productNumber);
      if (index !== -1) {
        discounts.current.splice(index, 1);
      }
      props.onChange?.(props.id ?? 0, currentBaseProduct.current);
      setUpdateAt(new Date().getTime());
    }
  }, [formattedSelectedDate]);

  const handleDestinationChange = (ranges: any[], _: number) => {
    let includesRange = false;
    let deletedRange = false;
    ranges.forEach((r) => {
      if (r.from && r.to) {
        r.quantity = r.to - r.from + 1;
        includesRange = true;
      } else if (r.from === 0 && r.to === 0) {
        r.quantity = 0;
        deletedRange = true;
      }
    });
    if (includesRange || deletedRange) {
      currentBaseProduct.current.destinations = ranges;
      const totalRangeQty = qtySumCalculator(ranges, 'quantity') as number;
      if (totalRangeQty > 0) {
        totalQty.current = totalRangeQty;
        if (currentBaseProduct.current.baseProduct) {
          currentBaseProduct.current.baseProduct.quantity = totalRangeQty;
        }
        props.onChange?.(props.id ?? 0, currentBaseProduct.current);
        setUpdateAt(new Date().getTime());
      }
    }
  };

  const handleDeleteBaseProduct = () => props.onDelete?.(currentBaseProduct.current.id || '');
  const additionalLetterServicesNumbers = useAdditionalServices(ProductGroup.BRIEF, selectedDate, 'SPECIAL_LETTER', false);

  const renderAdditionalLetterServices = (id?: string) => {
    const filteredProducts = currentBaseProduct.current?.discounts
      ?.filter((dP) => {
        if (id) return dP.referenceShipmentId === id;
        return !dP.referenceShipmentId;
      })
      .filter((dP) => additionalLetterServicesNumbers?.map((service) => service.productNumber).includes(dP.productNumber || ''));

    return (
      <>
        {filteredProducts?.map((fP, i) => (
          <div key={i}>
            {i === 0 ? (
              <div className="row">
                <div className="col-1" />
                <p className={styles.subHeading}>{trans('additionalService')}</p>
              </div>
            ) : (
              ''
            )}
            <AdditionalService
              descriptionShort={trans(
                additionalLetterServicesNumbers?.find((service) => service.productNumber === fP.productNumber)?.descriptionShort ?? ''
              )}
              shipmentQuantity={fP.shipmentQuantity}
              name={`${name}.${number}.additionalService.${i}`}
            />
          </div>
        ))}
      </>
    );
  };

  return loaded ? (
    <div className={styles.wrapper} data-testid={`base-product-section-${currentBaseProduct.current.baseProduct?.productNumber}`}>
      {
        <div className={styles.delete} data-testid={`delete-base-product-${number}`}>
          <DHLButton
            name={'delete-base-product'}
            type="default"
            icon="delete"
            className={styles.addDeleteAssignmentButton}
            onClick={handleDeleteBaseProduct}
            iconPosition="icon"
            disabled={props.disabledByMode}
          />
        </div>
      }
      <p className={styles.subHeading}>
        {oc('basProduct')} {selectedProducts.length > 1 ? number + 1 : ''}
      </p>
      <FormRow mode="two-col">
        <AMDHLSelectSingle<Product>
          data-testid={'base-product'}
          name={`${name}.${number}.baseProduct`}
          placeholder={oc('basicProduct')}
          items={selectableBaseProducts.current}
          value={props.baseProducts.find(({ productNumber }) => productNumber === currentBaseProduct.current.baseProduct?.productNumber)}
          onChange={(item) => {
            handleSelectionChange(item);
          }}
          valueToString={(item) => item.descriptionShort || ''}
          disabled={props.disabledByMode}
        />
        <CustomTextInput
          name={`${name}.${number}.quantity`}
          label={oc('shipmentVolume')}
          defaultValue={totalQty.current ?? ''}
          onChange={handleQuantityChange}
          testid={`base-product-quantity`}
          error={quantityError}
          disabled={props.disabledByMode}
          rules={{ regex: /^[0-9\b]+$/g }}
          required={true}
          formatNumbers={true}
          meta={{ language: props.meta.language }}
        />
      </FormRow>
      <FormRow mode="checkboxes">
        {digital && showDigitalCopy && isServiceAllowed(selectedDate) && (
          <DHLCheckbox
            name={`${name}.${number}.digitalCopy`}
            label={oc('digitalCopy')}
            value={
              orderMode === OrderMode.CHANGE || orderMode === OrderMode.COPY
                ? currentBaseProduct.current.discounts.some((x) => x.productNumber === digital?.productNumber)
                : digitalCopy.current
            }
            onChange={handleDigitalCopyChange}
            disabled={props.disabledByMode || disabled}
          />
        )}
      </FormRow>
      {showAdditionalLetterServices && renderAdditionalLetterServices(currentBaseProduct.current.baseProduct?.referenceShipmentId?.toString())}
      <div>
        <p className={styles.subHeading}>{t('deliveryPlan')}</p>
        {isDVBrief ? (
          <IdAssignmentReadOnlyTable
            header={'frankingIdPrefix'}
            ranges={(currentBaseProduct.current.destinations as any) || []}
            machineCode={props.machineCode}
            frankingIdEncoding={props.frankingIdEncoding}
            postageFrankingIdPrefix={props.postageFrankingIdPrefix}
            onChange={handleDestinationChange}
            isLetter={true}
            disabled={disabled}
            readOnly={true}
            meta={{ language: props.meta.language }}
            id={props?.id ? `${props.id}` : `${props.baseProduct.baseProduct?.productNumber}`}
          />
        ) : (
          <IdAssignment
            header={isDVBrief ? 'frankingIdPrefix' : 'machineCode'}
            ranges={(currentBaseProduct.current.destinations as any) || []}
            machineCode={props.machineCode}
            frankingIdEncoding={props.frankingIdEncoding}
            postageFrankingIdPrefix={props.postageFrankingIdPrefix}
            onChange={handleDestinationChange}
            isLetter={true}
            disabled={disabled}
            readOnly={false}
            meta={{ language: props.meta.language }}
            id={props?.id ? `${props.id}` : `${props.baseProduct.baseProduct?.productNumber}`}
          />
        )}
      </div>
    </div>
  ) : null;
};
