/*
* Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
* This code is licensed and the sole property of Deutsche Post DHL Group.
*/

import {
  createMessageWithSpecialTags,
  DHLButton,
  DHLButtonGroup,
  DHLButtonLink,
  DHLCheckbox,
  DHLContextmenu,
  DHLDialogNonModal,
  DHLFormGroup,
  DHLHeadline,
  DHLHelpButton,
  DHLIcon,
  DHLMessages,
  DHLModal,
  DHLPagetemplate,
  DHLSelectOne,
  DHLTextOutput,
  DialogFormContent7030,
  logger, ResourceDataStore, ErrorResponse
} from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { ResponseplusOverviewStore } from "../../../index";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import "./ResponseplusOrderDetails.scss";
import { ResponseplusConstants } from "../../../constants/ResponseplusConstants";
import { ResponseplusOrderDetailStore } from "../../../stores/ResponseplusOrderDetailStore";
import { ResponseplusUris } from "../../../types/ResponseplusUris";
import { OrderConfirmSidePanel } from "../ResponseplusOrder/Page3-OrderConfirm/OrderConfirmSidePanel";
import { OrderContentPanel } from "../ResponseplusOrder/Page3-OrderConfirm/OrderContentPanel";
import { ErrorInfoDialog } from "./ErrorInfoDialog";
import { RPOrderStatus } from "./RPOrderStatus";
import { formatOrderNumber } from "../../format";

export type OrderDetailProps = {
  responseplusStore: ResponseplusOverviewStore;
  uris: ResponseplusUris;
  handleRedirect: (uri: string) => void;
}

export const ResponseplusOrderDetails = observer(({
                                                    responseplusStore,
                                                    uris,
                                                    handleRedirect
                                                  }: OrderDetailProps) => {
    const {resourceDataStore, detailStore} = responseplusStore;
    const [showFrankingMarkCm, setShowFrankingMarkCm] = useState(false);
    const toggleFrankingMarkCm = () => setShowFrankingMarkCm(!showFrankingMarkCm);

    if (responseplusStore.detailStore.isLoading) {
      return null;
    }

    const onCancellationCanceled = () => responseplusStore.setRppOrderToCancel(null);
    const onCancellationConfirmed = () => {
      responseplusStore.cancelRppOrder()
        .then(() => {
          logger.log("Selected rpp order canceled");
          handleRedirect(uris.responseplusOverview);
        }).catch((errors: ErrorResponse) => logger.log(`Selected rpp order cancellation failed with ${errors}`));
    };

    const frankingMarkFormatsMenu = (
      <FrankingMarkFormatsMenu
        detailStore={detailStore}
        resourceDataStore={resourceDataStore}
        showFrankingMarkCm={showFrankingMarkCm}
        setShowFrankingMarkCm={setShowFrankingMarkCm} />
    );

    const frankingMarkLink =
      <>
        <DHLIcon name={"frankiervermerk"} icon={"download-file"} />
        <DHLButtonLink
          onClick={toggleFrankingMarkCm}
          className={"buttonLink"}
          label={resourceDataStore.getLabel("orderDetails.frankingzoneLink")} />
        {frankingMarkFormatsMenu}
      </>;

    const buttons =
      <>
        <DHLButtonGroup name={"secondary Buttons"} alignment={"left"}>
          <DHLButton
            name={"cancelOrderDetail"}
            label={resourceDataStore.getButton("orderDetails.cancel")}
            onClick={() => {
              responseplusStore.orderStore.reset();
              detailStore.reset();
              handleRedirect(uris.responseplusOverview);
            }}
            type={"default"} />
          <DHLButton
            name={"cancelOrder"}
            label={resourceDataStore.getButton("orderDetails.cancelOrder")}
            onClick={() => {
              responseplusStore.setRppOrderToCancel(responseplusStore.detailStore.order!);
            }}
            type={"secondary"}
            disabled={ResponseplusConstants.NON_CANCELABLE_STATES.includes(responseplusStore.detailStore.order!.state)}
          />
        </DHLButtonGroup>
        <DHLButton
          name={"copyOrder"}
          label={resourceDataStore.getButton("orderDetails.copyOrder")}
          onClick={() => {
            void responseplusStore.setSelectedOrderForCopyView(responseplusStore.detailStore.order!);
            handleRedirect(uris.responseplusOrder);
          }}
          type={"primary"} />
      </>;

    const createOrderButton =
      <DHLButton
        name="createNewOrder"
        label={resourceDataStore.getButton("orderDetails.create")}
        onClick={() => {
          detailStore.reset();
          handleRedirect(uris.responseplusOrder);
        }}
        type="default"
        size={"sm"} />;
    const helpButton = (
      <DHLHelpButton
        url={resourceDataStore.getHelp("manageOrderDetail")}
        tooltipText={resourceDataStore.getTooltip("helpButton")}
        type={"pagetemplateWithHeaderbutton"}
      />
    );

    const statusCode = responseplusStore.detailStore.order?.state!;
    const upperLeftContent = <RPOrderStatus
      statusType={responseplusStore.detailStore.getStatusFromOrder(statusCode)}
      iconHeadline={responseplusStore.detailStore.getIconHeadlineFromOrder(statusCode)}
      headline={responseplusStore.detailStore.getHeadlineFromOrder(statusCode)}
      bulletItems={responseplusStore.detailStore.getItemsFromOrder(statusCode)}
      onErrorInfoClick={() => responseplusStore.errorStateStore.showErrorInfoDialog = true}
      errorLinkText={resourceDataStore.getLabel("orderDetails.errorLinkText")}
      statusCode={statusCode} />;
    const upperRightContent = <>
      <DHLHeadline type={"h6"} className={"decreaseBottomMargin"}>{resourceDataStore.getLabel("orderDetails.topRightHeadline")}</DHLHeadline>
      <DHLFormGroup className={"row"}>
        {frankingMarkLink}
      </DHLFormGroup>
      <DHLFormGroup className={"row"}>
        <DHLIcon name={"auftragsbestätigung"} icon={"download-file"} />
        <DHLButtonLink
          onClick={() => detailStore.downloadOrderConfirmation()}
          className={"buttonLink"}
          label={resourceDataStore.getLabel("orderDetails.orderConfirmLink")} />
      </DHLFormGroup>
      <DHLFormGroup className={"row"}>
        <DHLIcon name={"brochure"} icon={"download-file"} />
        <DHLTextOutput value={createMessageWithSpecialTags(resourceDataStore.getLabel("orderDetails.flyerLink"))} />
      </DHLFormGroup>
    </>;

    const lowerRightContent =
      <OrderConfirmSidePanel orderStore={responseplusStore.detailStore} />;

    const upperDialog7030 =
      <DialogFormContent7030
        className={"order-detail-upper"}
        leftContent70={upperLeftContent}
        rightMarginal30={upperRightContent}
      />;

    const lowerHeadline =
      <DHLHeadline type={"h4"}>
        {resourceDataStore.getLabel("orderDetails.lowerHeadline")}
      </DHLHeadline>;

    const lowerDialog7030 =
      <DialogFormContent7030
        className={"order-detail-lower"}
        preamble={lowerHeadline}
        leftContent70={<OrderContentPanel
          orderStore={responseplusStore.detailStore}
          showFrankingMarkPreview={false} />}
        rightMarginal30={lowerRightContent} />;

    const cancelCancellationButton = (
      <DHLButton
        type="secondary"
        name="cancel"
        label={resourceDataStore.getButton("order.cancel")}
        onClick={onCancellationCanceled}
        disabled={responseplusStore.orderCancelling}
      />
    );
    const confirmCancellationButton = (
      <DHLButton
        loadingState={responseplusStore.orderCancelling}
        disabled={responseplusStore.orderCancelling}
        type="primary"
        name="confirm"
        label={resourceDataStore.getButton("confirm.cancellation.yes")}
        onClick={onCancellationConfirmed} />
    );

    const actionBar = <>
      {cancelCancellationButton}
      {confirmCancellationButton}
    </>;

    const cancelConfirmDialog = (
      <DHLModal
        name="rppOrder-cancel"
        show={responseplusStore.cancelConfirmDialogVisible}
        title={resourceDataStore.getMsg("confirm.cancellation.title")}
        message={resourceDataStore.getMsg("confirm.cancellation.confirmCancellationMsg")}
        onClickClose={() => onCancellationCanceled()}
        actionbar={actionBar} />
    );

    const errorStateDialog = responseplusStore.errorStateStore.showErrorInfoDialog &&
        <>
          <ErrorInfoDialog
              errorStore={responseplusStore.errorStateStore}
              showErrorInfoDialog={responseplusStore.errorStateStore.showErrorInfoDialog} />
        </>
    ;
    const messages =
      <DHLMessages
        msgStore={detailStore.baseStore.messageDataStore}
        name="messages" />;

    return (
      <DHLPagetemplate headerButtons={<>
        {createOrderButton}
        {helpButton}
      </>
      }
                       headlineText={resourceDataStore.getLabel("orderDetails.headline", [formatOrderNumber(detailStore.order?.orderNumber!)])}
                       dhlMessage={messages}
      className={classNames("responseplus-order-details-page")}>
        <DHLDialogNonModal actionbar={buttons}>
          {upperDialog7030}
          {lowerDialog7030}
          {cancelConfirmDialog}
          {errorStateDialog}
        </DHLDialogNonModal>
      </DHLPagetemplate>
    );
  }
);

type FrankingMarkFormatsMenuProps = {
  detailStore: ResponseplusOrderDetailStore
  resourceDataStore: ResourceDataStore
  showFrankingMarkCm: boolean
  setShowFrankingMarkCm: (value: boolean) => void
}

const FrankingMarkFormatsMenu = ({
                                   detailStore,
                                   resourceDataStore,
                                   showFrankingMarkCm,
                                   setShowFrankingMarkCm
                                 }: FrankingMarkFormatsMenuProps) => {
  const clickOutsideDetectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (!clickOutsideDetectionRef?.current?.contains(event.target as Node)) {
        setShowFrankingMarkCm(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setShowFrankingMarkCm, clickOutsideDetectionRef]);

  return (
    <div
      className={"frankingMarkCm"}
      ref={clickOutsideDetectionRef}>
      <DHLContextmenu visible={showFrankingMarkCm}>
        <div className={"frankingMarkCm-body"}>
          <div className={"frankingMarkCm-item"}>
            <DHLSelectOne
              disabled={false}
              data={detailStore.frankingMarkFormatOptions}
              formField={detailStore.frankingMarkFormat} />
          </div>
          <div className={"frankingMarkCm-item"}>
            <DHLCheckbox
              name={"return"}
              disabled={false}
              label={resourceDataStore.getLabel("orderDetails.withReturnAddress")}
              formField={detailStore.withReturnAddress} />
          </div>
          <div className={"frankingMarkCm-item"}>
            <DHLButton
              label={resourceDataStore.getLabel("orderDetails.downloadFrankingMark")}
              size={"sm"}
              type={"primary"}
              onClick={() => {
                setShowFrankingMarkCm(false);
                detailStore.downloadFrankingMark()
                  .catch(err => logger.warn(`Error downloading franking mark ${err}`));
              }} />
          </div>
        </div>
      </DHLContextmenu>
    </div>
  );
};