// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { ProductsProxyControllerService, ProjectControllerService } from "../requests/services.gen";
import { ProjectCopyRequest, ProjectCreateRequest, ProjectUpdateRequest } from "../requests/types.gen";
import * as Common from "./common";
/**
* Get Project data
* @param data The data for the request.
* @param data.sortBy
* @param data.page
* @param data.pageSize
* @param data.sortDirection
* @param data.active
* @param data.state
* @param data.attestationState
* @param data.name
* @param data.projectNumber
* @param data.createdByFamilyName
* @param data.productType
* @param data.updatedBefore
* @param data.updatedAfter
* @param data.xDelegatedEkp
* @returns PageVOProjectVO OK
* @throws ApiError
*/
export const useProjectControllerServiceGetProjects = <TData = Common.ProjectControllerServiceGetProjectsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ active, attestationState, createdByFamilyName, name, page, pageSize, productType, projectNumber, sortBy, sortDirection, state, updatedAfter, updatedBefore, xDelegatedEkp }: {
  active?: boolean;
  attestationState?: "INITIAL" | "FAILED" | "MALICIOUS" | "SUCCEEDED";
  createdByFamilyName?: string;
  name?: string;
  page?: number;
  pageSize?: number;
  productType?: "DIALOGPOST" | "POSTWURFSPEZIAL" | "BRIEF" | "POSTKARTE" | "WERBEANTWORT" | "RESPONSEPLUS" | "WARENSENDUNG";
  projectNumber?: string;
  sortBy: "CREATED" | "NAME" | "PRODUCT_TYPE" | "PROJECT_STATE" | "UPDATED";
  sortDirection?: "ASC" | "DESC";
  state?: "FAILED" | "PENDING" | "RUNNING" | "DONE" | "CLOSED" | "EXPIRED";
  updatedAfter?: string;
  updatedBefore?: string;
  xDelegatedEkp?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseProjectControllerServiceGetProjectsKeyFn({ active, attestationState, createdByFamilyName, name, page, pageSize, productType, projectNumber, sortBy, sortDirection, state, updatedAfter, updatedBefore, xDelegatedEkp }, queryKey), queryFn: () => ProjectControllerService.getProjects({ active, attestationState, createdByFamilyName, name, page, pageSize, productType, projectNumber, sortBy, sortDirection, state, updatedAfter, updatedBefore, xDelegatedEkp }) as TData, ...options });
/**
* Get Project data
* @param data The data for the request.
* @param data.projectNumber
* @param data.xDelegatedEkp
* @returns ProjectDetailVO OK
* @throws ApiError
*/
export const useProjectControllerServiceGetProject = <TData = Common.ProjectControllerServiceGetProjectDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ projectNumber, xDelegatedEkp }: {
  projectNumber: string;
  xDelegatedEkp?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseProjectControllerServiceGetProjectKeyFn({ projectNumber, xDelegatedEkp }, queryKey), queryFn: () => ProjectControllerService.getProject({ projectNumber, xDelegatedEkp }) as TData, ...options });
/**
* Get JPEG preview
* @param data The data for the request.
* @param data.projectNumber
* @param data.quality
* @param data.xDelegatedEkp
* @returns string OK
* @throws ApiError
*/
export const useProjectControllerServiceGetPreview = <TData = Common.ProjectControllerServiceGetPreviewDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ projectNumber, quality, xDelegatedEkp }: {
  projectNumber: string;
  quality?: number;
  xDelegatedEkp?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseProjectControllerServiceGetPreviewKeyFn({ projectNumber, quality, xDelegatedEkp }, queryKey), queryFn: () => ProjectControllerService.getPreview({ projectNumber, quality, xDelegatedEkp }) as TData, ...options });
/**
* Get Project data
* @param data The data for the request.
* @param data.projectNumber
* @param data.xDelegatedEkp
* @returns AnalysisResult OK
* @throws ApiError
*/
export const useProjectControllerServiceGetLatestAnalyzeResults = <TData = Common.ProjectControllerServiceGetLatestAnalyzeResultsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ projectNumber, xDelegatedEkp }: {
  projectNumber: string;
  xDelegatedEkp?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseProjectControllerServiceGetLatestAnalyzeResultsKeyFn({ projectNumber, xDelegatedEkp }, queryKey), queryFn: () => ProjectControllerService.getLatestAnalyzeResults({ projectNumber, xDelegatedEkp }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.projectNumber
* @param data.xDelegatedEkp
* @returns StreamingResponseBody OK
* @throws ApiError
*/
export const useProjectControllerServiceGetAttestation = <TData = Common.ProjectControllerServiceGetAttestationDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ projectNumber, xDelegatedEkp }: {
  projectNumber: string;
  xDelegatedEkp?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseProjectControllerServiceGetAttestationKeyFn({ projectNumber, xDelegatedEkp }, queryKey), queryFn: () => ProjectControllerService.getAttestation({ projectNumber, xDelegatedEkp }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.xDelegatedEkp
* @returns unknown OK
* @throws ApiError
*/
export const useProductsProxyControllerServiceGetProductInfo = <TData = Common.ProductsProxyControllerServiceGetProductInfoDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDelegatedEkp }: {
  xDelegatedEkp?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseProductsProxyControllerServiceGetProductInfoKeyFn({ xDelegatedEkp }, queryKey), queryFn: () => ProductsProxyControllerService.getProductInfo({ xDelegatedEkp }) as TData, ...options });
/**
* Create a project
* Creates a project
* @param data The data for the request.
* @param data.requestBody
* @returns ProjectVO Created
* @throws ApiError
*/
export const useProjectControllerServiceCreateProject = <TData = Common.ProjectControllerServiceCreateProjectMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ProjectCreateRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ProjectCreateRequest;
}, TContext>({ mutationFn: ({ requestBody }) => ProjectControllerService.createProject({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Updates a project
* Updates a project
* @param data The data for the request.
* @param data.projectNumber
* @param data.requestBody
* @returns unknown Created
* @throws ApiError
*/
export const useProjectControllerServiceUpdateProject = <TData = Common.ProjectControllerServiceUpdateProjectMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  projectNumber: string;
  requestBody: ProjectUpdateRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  projectNumber: string;
  requestBody: ProjectUpdateRequest;
}, TContext>({ mutationFn: ({ projectNumber, requestBody }) => ProjectControllerService.updateProject({ projectNumber, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Uploads a document
* Uploads a document
* @param data The data for the request.
* @param data.projectNumber
* @param data.formData
* @returns unknown No Content
* @throws ApiError
*/
export const useProjectControllerServiceUploadDocument = <TData = Common.ProjectControllerServiceUploadDocumentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData?: { file: Blob | File; };
  projectNumber: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData?: { file: Blob | File; };
  projectNumber: string;
}, TContext>({ mutationFn: ({ formData, projectNumber }) => ProjectControllerService.uploadDocument({ formData, projectNumber }) as unknown as Promise<TData>, ...options });
/**
* Create a copy from an existing project
* Create a copy from an existing project
* @param data The data for the request.
* @param data.projectNumber
* @param data.requestBody
* @returns ProjectVO Created
* @throws ApiError
*/
export const useProjectControllerServiceCopyProject = <TData = Common.ProjectControllerServiceCopyProjectMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  projectNumber: string;
  requestBody: ProjectCopyRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  projectNumber: string;
  requestBody: ProjectCopyRequest;
}, TContext>({ mutationFn: ({ projectNumber, requestBody }) => ProjectControllerService.copyProject({ projectNumber, requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.projectNumber
* @returns unknown OK
* @throws ApiError
*/
export const useProjectControllerServiceCloseProject = <TData = Common.ProjectControllerServiceCloseProjectMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  projectNumber: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  projectNumber: string;
}, TContext>({ mutationFn: ({ projectNumber }) => ProjectControllerService.closeProject({ projectNumber }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.projectNumber
* @returns unknown OK
* @throws ApiError
*/
export const useProjectControllerServicePerformCheck = <TData = Common.ProjectControllerServicePerformCheckMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  projectNumber: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  projectNumber: string;
}, TContext>({ mutationFn: ({ projectNumber }) => ProjectControllerService.performCheck({ projectNumber }) as unknown as Promise<TData>, ...options });
/**
* Archives a project
* Archives a project
* @param data The data for the request.
* @param data.projectNumber
* @returns unknown Created
* @throws ApiError
*/
export const useProjectControllerServiceArchiveProject = <TData = Common.ProjectControllerServiceArchiveProjectMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  projectNumber: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  projectNumber: string;
}, TContext>({ mutationFn: ({ projectNumber }) => ProjectControllerService.archiveProject({ projectNumber }) as unknown as Promise<TData>, ...options });
