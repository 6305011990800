import styles from './pslbilling-table.module.css';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
type PSLBillingTableHeadRowProps = {
  unitAmountCol: boolean;
};
export const PSLBillingTableHeadRow: VFC<PSLBillingTableHeadRowProps> = ({ unitAmountCol }) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  return (
    <>
      <tr>
        <th colSpan={unitAmountCol ? 3 : 2} className={classNames(styles.addBorderRight, styles.addBorderBottom)} />
        <th colSpan={3} className={classNames(styles.addBorderBottom, styles.addBorderRight, styles.centerAlign)}>
          {t('PartialServiceBasic')}
        </th>
        <th colSpan={3} className={classNames(styles.addBorderBottom, styles.addBorderRight, styles.centerAlign)}>
          {t('PartialServiceID')}
        </th>
        <th colSpan={3} className={classNames(styles.addBorderBottom, styles.addBorderRight, styles.centerAlign)}>
          {t('PartialServiceDelivery')}
        </th>
        {unitAmountCol ? '' : <th className={styles.addBorderBottom} />}
      </tr>
      <tr>
        <th className={styles.removeWhiteSpace}>{t('position')}</th>
        <th className={unitAmountCol ? styles.removeWhiteSpace : classNames(styles.removeWhiteSpace, styles.addBorderRight)}>{t('format')}</th>
        {unitAmountCol && (
          <th data-testid="psl-billing-unitAmount" className={classNames(styles.rightAlign, styles.removeWhiteSpace, styles.addBorderRight)}>
            {t('basicFeePerPiece')}
          </th>
        )}
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('numberOfShipments')}</th>
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('DiscountInPercent')}</th>
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace, styles.addBorderRight)}>{t('discount')}</th>
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('numberOfShipments')}</th>
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('DiscountInPercent')}</th>
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace, styles.addBorderRight)}>{t('discount')}</th>
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('numberOfShipments')}</th>
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('DiscountInPercent')}</th>
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace, styles.addBorderRight)}>{t('discount')}</th>
        <th className={classNames(styles.rightAlign, styles.removeWhiteSpace)}>{t('totalPartialServiceDiscountWithoutVAT')}</th>
      </tr>
    </>
  );
};
