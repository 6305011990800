/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { Order, OrderRootProps } from "@gkuis/gkp-order-ui";

export function configureApplicationOrder() {
  Order.config = {
    recentOrderUri: `${process.env.ORDER_BASE_URI}/v1/recentorder`,
    catalogUri: `${process.env.ORDER_BASE_URI}/v1/catalog`,
    addressUri: `${process.env.ORDER_BASE_URI}/v1/address`,
    shoppingCartUri: `${process.env.ORDER_BASE_URI}/v1/shoppingcart`,
    templateOrderUri: `${process.env.ORDER_BASE_URI}/v1/templateorder`,
    resourceDataBaseUri: `${process.env.ORDER_BASE_URI}/v1`,
    validationRulesBaseUri: `${process.env.ORDER_BASE_URI}/v1`,
    catalogImageUri: process.env.ORDER_CATALOG_IMAGE_BASE_URI,
    catalogDocumentUri: process.env.ORDER_CATALOG_DOCUMENT_BASE_URI,
    editedStockItemUri: ""
  };
}

export function registerApplicationOrder(authenticationManager: AuthenticationManager) {
  const orderRootProps: OrderRootProps = {
    authenticationManager,
    orderConfiguration: Order.config
  };

  registerApplication({
    name: "@gkuis/gkp-order-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-order-ui"),
    activeWhen: ["/order"],
    customProps: {
      ...orderRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}