/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
import { DHLFormGroup, DHLHeadline, DHLTextOutput } from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { ResponseplusOrderDetailStore } from "../../../../stores/ResponseplusOrderDetailStore";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";

export type OrderConfirmCustomerDataProps = {
  orderStore: ResponseplusOrderStore | ResponseplusOrderDetailStore;
}

export const OrderConfirmSidePanel = observer(({orderStore}: OrderConfirmCustomerDataProps) => {

  const {resourceDataStore} = orderStore.baseStore;
  const headline = (
      <DHLHeadline type={"h6"}>
        {resourceDataStore.getLabel("order.page3-orderconfirm.customerdata")}
      </DHLHeadline>
  );

  const ekpPanel = (
      <DHLFormGroup>
        <DHLHeadline type={"h6"}>
          {resourceDataStore.getLabel("order.page3-orderconfirm.ekp")}
        </DHLHeadline>
        <DHLFormGroup>
          <DHLTextOutput value={orderStore.customerProfile?.ekp} />
        </DHLFormGroup>
      </DHLFormGroup>
  );
  const addressPanel = (
      <DHLFormGroup>
        <DHLFormGroup>
          <DHLHeadline type={"h6"}>
            {resourceDataStore.getLabel("order.page3-orderconfirm.customer")}
          </DHLHeadline>
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextOutput value={orderStore.customerProfile?.name1} />
          <DHLTextOutput value={orderStore.customerProfile?.name2} />
          <DHLTextOutput value={orderStore.customerProfile?.name3} />
          <DHLTextOutput value={orderStore.customerProfile?.street + " " + orderStore.customerProfile?.houseNumber} />
          <DHLTextOutput value={orderStore.customerProfile?.postcode + " " + orderStore.customerProfile?.city} />
        </DHLFormGroup>
      </DHLFormGroup>
  );

  return (
      <>
        {headline}
        {ekpPanel}
        {addressPanel}
      </>
  );

});