import { AccessToken } from '@gkuis/gkp-authentication';
import { AuthenticationManager } from '@gkuis/gkp-authentication/dist/AuthenticationManager';
import { AuthUser, defaultAuthUserData } from 'common/dtos/auth';

import { AuthActions, authReducer, defaultAuthState, fetchWhitelisting, fetchPermissions } from 'common/reducers/auth';
import { now } from 'common/utils/timeAndDate';
import * as React from 'react';
import { FC, useCallback, useEffect, useReducer, useRef } from 'react';
import { UserRights } from '../../dtos/userRights';
import { AuthContext } from './AuthContext';
import { AuthManager } from './authManager';
import { IdTokenUnsafe } from './unsafeJwtToken';
import { envValues } from 'common/environment/env';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { WhitelistMethod } from 'order/common/dtos/OrderMode';

// Currently, necessary until rework of api call functionality
let globalUser = defaultAuthUserData;

export function getUser(): AuthUser {
  return globalUser;
}

export const AuthProvider: FC = (props) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [auth, authDispatch] = useReducer(authReducer, defaultAuthState);
  const doTrace = true;
  const authMgrRef = useRef<AuthenticationManager | AuthManager>();

  const getUserPermissions = async (token: string | undefined, extUserId: string | undefined) => {
    if (!token) return;
    const data = await fetchPermissions(token, extUserId, 'GET');
    authDispatch({ type: AuthActions.SET_PERMISSIONS, data });
    getWhitelisting(auth.user._accessToken, auth.extUserId);
  };

  const getWhitelisting = async (token: string | undefined, extUserId: string | undefined) => {
    if (!token) return;
    const data = await fetchWhitelisting(token, extUserId, 'GET');
    authDispatch({ type: AuthActions.SET_WHITELISTING, data });
    authDispatch({ type: AuthActions.SET_LOADING, data: false });
    setLoading(false);
  };

  useEffect(() => {
    if (auth.user._accessToken && loading) {
      getUserPermissions(auth.user._accessToken, auth.extUserId);
    }
  }, [auth.loading, auth.user._accessToken, auth.extUserId, loading]);

  const resetUser = () => authDispatch({ type: AuthActions.SET_USER, data: defaultAuthUserData });

  const setAuthMgr = (authMgr: AuthenticationManager | AuthManager): void => {
    if (authMgr !== authMgrRef.current) {
      console.debug(`${now()} am-portal-frontend / authProvider: set new authMgr.`);
      if (doTrace) {
        console.debug(`${now()} am-portal-frontend / authProvider: old: ${JSON.stringify(authMgrRef)}, new ${JSON.stringify(authMgr)}`);
      }

      // release event handler on old authMgr
      removeEventHandlers(authMgrRef.current);

      // set new authMgr
      authMgrRef.current = authMgr;
      if (authMgrRef.current === undefined || authMgrRef.current.getAccessTokenParsed === undefined) {
        throw new TypeError(`${now()} am-portal-frontend / authProvider: authMgr is invalid.`);
      }
      addEventHandlers(authMgrRef.current);

      updateAccessToken();
    }
  };

  const updateAccessToken = async (minValiditySeconds: number = 10): Promise<void> => {
    await authMgrRef.current
      ?.getAccessTokenParsed(minValiditySeconds)
      .then((accessToken) => {
        if (accessToken) {
          authMgrRef.current?.getAccessToken().then((sAccessToken) => {
            updateClaimValues(accessToken, sAccessToken);
          });
        }
      })
      .catch(() => {
        logout();
      });
  };

  const logout = async (): Promise<void> => {
    if (authMgrRef.current?.authenticated) {
      await authMgrRef.current
        ?.logout('')
        .then((value) => {
          console.debug(`${now()} am-portal-frontend / authProvider: logged out. value ${value}`);
        })
        .catch(() => {
          logoutInternal();
        });
    } else {
      logoutInternal();
    }
  };

  const addEventHandlers = (authMgr: AuthenticationManager | AuthManager | undefined) => {
    if (authMgr) {
      authMgr.addEventListener('onAccessTokenExpired', onAccessTokenExpired);
      authMgr.addEventListener('onAuthRefreshError', onAuthRefreshError);
      authMgr.addEventListener('onAuthLogout', onLogout);
      if (doTrace) {
        console.debug(`${now()} am-portal-frontend / authProvider: event handlers added to `, authMgr);
      }
    }
  };

  const removeEventHandlers = (authMgr: AuthenticationManager | AuthManager | undefined) => {
    if (authMgr) {
      authMgr.removeEventListener('onAccessTokenExpired', onAccessTokenExpired);
      authMgr.removeEventListener('onAuthRefreshError', onAuthRefreshError);
      authMgr.removeEventListener('onAuthLogout', onLogout);
      if (doTrace) {
        console.debug(`${now()} am-portal-frontend / authProvider: event handlers removed from `, authMgr);
      }
    }
  };

  const onAccessTokenExpired = (): void => {
    if (doTrace) console.debug(`${now()} am-portal-frontend / authProvider: auth event onAccessTokenExpired fired.`);
    updateAccessToken(-1);
  };

  // const onAuthRefreshSuccess = (): void => {
  //   if (doTrace) console.debug(`${now()} am-portal-frontend / authProvider: auth event onAuthRefreshSuccess fired.`);
  //   updateAccessToken(60);
  // };

  const onAuthRefreshError = (): void => {
    if (doTrace) console.debug(`${now()} am-portal-frontend / authProvider: auth event onAuthRefreshError fired.`);
    updateAccessToken(-1);
  };

  const onLogout = (): void => {
    if (doTrace) console.debug(`${now()} am-portal-frontend / authProvider: auth event onLogout fired.`);
    logoutInternal();
  };

  const logoutInternal = () => {
    updateClaimValues(undefined, undefined);
    removeEventHandlers(authMgrRef.current);
    if (doTrace) console.debug(`${now()} am-portal-frontend / authProvider: logged out internal.`);
  };

  const updateClaimValues = (accessToken: AccessToken | IdTokenUnsafe | undefined, sAccessToken: string | undefined): void => {
    if (auth.user.isAuthenticated !== authMgrRef.current?.authenticated || auth.user._accessToken != sAccessToken) {
      const data: {
        _accessToken: string | undefined;
        userEkp: string | undefined;
        language: 'de' | 'en';
        isAuthenticated: boolean;
        userName: string | undefined;
        userId: string | undefined;
      } = {
        isAuthenticated: authMgrRef.current?.authenticated || false,
        userEkp: accessToken?.ekp,
        userName: accessToken?.username,
        language: accessToken?.language || 'de',
        _accessToken: sAccessToken,
        userId: accessToken?.user_id
      };
      authDispatch({ type: AuthActions.SET_USER, data });
      globalUser = { ...globalUser, ...data };
      if (doTrace) {
        console.debug(
          `${now()} am-portal-frontend / authProvider: updated claim values: authenticated ${
            data.isAuthenticated
          } / expired ${authMgrRef.current?.isTokenExpired()} / userName ${data.userName} / ekp ${data.userEkp} / language ${data.language}.`
        );
        console.debug(`${now()} am-portal-frontend / authProvider: access token ${data._accessToken}`);
      }
    }
  };

  const traceInitEventListenerLogging = (): void => {
    if (doTrace && authMgrRef.current) {
      authMgrRef.current.addEventListener('onReady', (authenticated) => {
        console.debug(`${now()} am-portal-frontend / authProvider: auth event onReady: authenticated ${authenticated}.`);
      });
      authMgrRef.current.addEventListener('onAuthSuccess', () => {
        console.debug(`${now()} am-portal-frontend / authProvider: auth event onAuthSuccess.`);
      });
      authMgrRef.current.addEventListener('onAuthError', () => {
        console.debug(`${now()} am-portal-frontend / authProvider: auth event onAuthError.`);
      });
      authMgrRef.current.addEventListener('onAuthRefreshSuccess', () => {
        console.debug(`${now()} am-portal-frontend / authProvider: auth event onAuthRefreshSuccess.`);
      });
      authMgrRef.current.addEventListener('onAuthRefreshError', () => {
        console.debug(`${now()} am-portal-frontend / authProvider: auth event onAuthRefreshError.`);
      });
      authMgrRef.current.addEventListener('onAuthLogout', () => {
        console.debug(`${now()} am-portal-frontend / authProvider: auth event onAuthLogout.`);
      });
      authMgrRef.current.addEventListener('onAccessTokenExpired', () => {
        console.debug(`${now()} am-portal-frontend / authProvider: auth event onAccessTokenExpired.`);
      });
    }
  };

  const hasPermission = useCallback((permission: UserRights) => auth.permissions.includes(permission), [auth.permissions]);

  const checkWhitelistingKey = useCallback(
    (key: OrderCategoryProductKey, method: WhitelistMethod) => {
      return !envValues().whitelistingEnabled || (auth.whitelisting?.[method].categories.includes(key) ?? false);
    },
    [auth.whitelisting]
  );
  const checkProductionStateWhitelistingChange = useCallback(
    (productionState: ProductionState) => !envValues().whitelistingEnabled || (auth.whitelisting?.change.status.includes(productionState) ?? false),
    [auth.whitelisting]
  );
  const checkProductionStateWhitelistingGet = useCallback(
    (productionState: ProductionState) => !envValues().whitelistingEnabled || (auth.whitelisting?.get.status.includes(productionState) ?? false),
    [auth.whitelisting]
  );
  const checkProductionStateWhitelistingCopy = useCallback(
    (productionState: ProductionState) => !envValues().whitelistingEnabled || (auth.whitelisting?.copy.status.includes(productionState) ?? false),
    [auth.whitelisting]
  );
  const checkProductionStateWhitelistingDelete = useCallback(
    (productionState: ProductionState) => !envValues().whitelistingEnabled || (auth.whitelisting?.delete.status.includes(productionState) ?? false),
    [auth.whitelisting]
  );
  const checkWhitelistingWithProductionState = useCallback(
    (key: OrderCategoryProductKey, productionState: ProductionState, method: WhitelistMethod) =>
      !envValues().whitelistingEnabled ||
      ((auth.whitelisting?.[method].categories.includes(key) ?? false) && (auth.whitelisting?.[method].status.includes(productionState) ?? false)),
    [auth.whitelisting]
  );

  const triggerTestActions = (authManager: AuthenticationManager | AuthManager | undefined) => {
    authMgrRef.current = authManager;
    logoutInternal();
    traceInitEventListenerLogging();
  };

  const setExtUserId = (extUserId: string | undefined) => {
    const extUserIdOld = globalUser.extUserId;
    if (extUserId !== extUserIdOld) {
      authDispatch({ type: AuthActions.SET_EXT_USER_ID, data: extUserId });
      globalUser = {
        ...globalUser,
        extUserId
      };
      console.debug(`${now()} am-portal-frontend / authProvider - extUserId: old: ${extUserIdOld}, new ${extUserId}`);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        addEventHandlers,
        hasPermission,
        checkWhitelistingKey,
        checkProductionStateWhitelistingChange,
        checkProductionStateWhitelistingGet,
        checkProductionStateWhitelistingCopy,
        checkProductionStateWhitelistingDelete,
        checkWhitelistingWithProductionState,
        loading: auth.loading,
        logout,
        permissions: auth.permissions,
        whitelisting: auth.whitelisting,
        removeEventHandlers,
        resetUser,
        setAuthMgr,
        user: auth.user,
        // only used for testing
        triggerTestActions,
        setExtUserId,
        extUserId: auth.extUserId ?? ''
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
