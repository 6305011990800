import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from '../hooks/useFormContext';
import { Flex, Grid, List, ListItem, Paper, Text } from '@mantine/core';
import { RadioButtonCard } from '../components/RadioButtonCard/RadioButtonCard';
import { WithinFrankingZoneGross } from '../components/Icons/WithinFrankingZoneGross';
import { WithinInscriptionGross } from '../components/Icons/WithinInscriptionGross';
import { WithinBothZonesGross } from '../components/Icons/WithinBothZonesGross';
import { WithinSenderZoneGross } from '../components/Icons/WithinSenderZoneGross';
import { AboveRecipientGross } from '../components/Icons/AboveRecipientGross';
import { WithoutSenderGross } from '../components/Icons/WithoutSenderGross';
import { letterFrankingPlacementType, letterSenderPlacementType } from 'src/api/requests';
import { InfoIcon } from '../components/Icons/InfoIcon';
import { ImportantInstructions } from '../components/ImportantInstructions/ImportantInstructions';
import { WithinFrankingZoneStandard } from '../components/Icons/WithinFrankingZoneStandard';
import { WithinInscriptionStandard } from '../components/Icons/WithinInscriptionStandard';
import { WithinBothZonesStandard } from '../components/Icons/WithinBothZonesStandard';
import { WithinSenderZoneStandard } from '../components/Icons/WithinSenderZoneStandard';
import { AboveRecipientStandard } from '../components/Icons/AboveRecipientStandard';
import { ButtonBar } from '../components/Buttons/ButtonBar';
import { useUpdateProject } from '../hooks/useUpdateProject';
import { useEffect } from 'react';
import { useGoToDashboard } from '../hooks/useGoToDashboard';
import { WithoutSenderStandard } from '../components/Icons/WithoutSenderStandard';
import { useLetterType } from '../hooks/useLetterType';
import { useNavigateWithSearchParams } from '../hooks/useNavigateWithSearchParams';
import { useTranslatedListItems } from '../hooks/useTranslatedListItems';

export const ImportantInstructionsStepThree = ({
  letterFrankingPlacementType,
}: {
  letterFrankingPlacementType:
    | letterFrankingPlacementType
    | 'NONE_SENDER'
    | 'AUFSCHRIFT_SENDER'
    | 'SENDER_ZONE_SENDER'
    | null;
}) => {
  const { t } = useTranslation();

  const listItems = useTranslatedListItems(`step-three.important-instructions.${letterFrankingPlacementType}.list`);

  if (!letterFrankingPlacementType || !listItems || listItems.length === 0) {
    return null;
  }
  return (
    <Flex direction="column" gap={10}>
      <ImportantInstructions
        infoText={t(`step-three.important-instructions.${letterFrankingPlacementType}.title`)}
        listItems={listItems.map((value) => ({ details: value }))}
      />
    </Flex>
  );
};

export const InfoBoxStepThree = () => {
  const { t } = useTranslation();

  const listItems = useTranslatedListItems('step-three.infobox.list');

  return (
    <Flex
      style={{
        borderLeft: '1px solid var(--GRAY-OPAC-Gray-O-400)',
        paddingLeft: '20px',
        paddingRight: '20px',
        height: '100%',
      }}
    >
      <Flex direction={'column'} gap={10}>
        <InfoIcon color="var(--BASIC-Black)"></InfoIcon>
        <Text fw={700}>{t('step-three.infobox.title')}</Text>

        <List>
          {listItems.map((item: string, index: number) => (
            <ListItem key={index}>
              <Text>
                <Trans
                  components={[
                    <a href="http://www.deutschepost.de/automation" target="_blank" rel="noreferrer">
                      Downloads Print-Mailing
                    </a>,
                    <a
                      href="https://www.deutschepost.de/de/f/frankierwelle/downloads.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FRANKIERWELLE – Downloads
                    </a>,
                  ]}
                >
                  {item}
                </Trans>
              </Text>
            </ListItem>
          ))}
        </List>
        <Text>{t('step-two.infobox.footer')}</Text>
      </Flex>
    </Flex>
  );
};

export const StepThree = () => {
  const { t } = useTranslation();
  const { form } = useFormContext();
  const { isLargeLetter } = useLetterType();
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();
  const goToDashboard = useGoToDashboard();
  const { updateProject, isUpdateProjectSuccess } = useUpdateProject();

  const handleRadioChangeFrankingPlacement = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('letterFrankingPlacementType', event.target.value as letterFrankingPlacementType);
  };

  const handleRadioChangeSenderPlacement = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('letterSenderPlacementType', event.target.value as letterSenderPlacementType);
  };

  const updateProjectForStepThree = async () => {
    if (!form.values.letterFrankingPlacementType || !form.values.letterSenderPlacementType) {
      return;
    }
    updateProject(form.values.projectNumber, {
      letterFrankingPlacementType: form.values.letterFrankingPlacementType,
      letterSenderPlacementType: form.values.letterSenderPlacementType,
    });
  };

  useEffect(() => {
    if (isUpdateProjectSuccess) {
      navigate(`/wizard/${form.values.projectNumber}/4`);
    }
  }, [isUpdateProjectSuccess]);

  const isNextButtonDisabledStepThree = (() => {
    const frankingPlacementIsNotSelected = !form.values.letterFrankingPlacementType;
    const senderPlacementIsNotSelected = !form.values.letterSenderPlacementType;
    return frankingPlacementIsNotSelected || senderPlacementIsNotSelected;
  })();

  return (
    <>
      <Paper shadow="sm" radius="md" withBorder p="xl">
        <Grid gutter={40}>
          <Grid.Col span={9}>
            <Grid>
              <Grid.Col span={12}>
                <h2>{t('step-three.specify.franking-placement')}</h2>
              </Grid.Col>
              <Grid.Col span={4}>
                <RadioButtonCard
                  data-testid="radio-button-card-within-franking-zone"
                  label={t('step-three.FRANKING_ZONE')}
                  image={isLargeLetter ? <WithinFrankingZoneGross /> : <WithinFrankingZoneStandard />}
                  value="FRANKING_ZONE"
                  checked={form.values.letterFrankingPlacementType === 'FRANKING_ZONE'}
                  onChange={handleRadioChangeFrankingPlacement}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <RadioButtonCard
                  data-testid="radio-button-card-within-inscription"
                  label={t('step-three.AUFSCHRIFT')}
                  image={isLargeLetter ? <WithinInscriptionGross /> : <WithinInscriptionStandard />}
                  value="AUFSCHRIFT"
                  checked={form.values.letterFrankingPlacementType === 'AUFSCHRIFT'}
                  onChange={handleRadioChangeFrankingPlacement}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <RadioButtonCard
                  data-testid="radio-button-card-within-both-zones"
                  label={t('step-three.FRANKING_ZONE_AND_AUFSCHRIFT')}
                  image={isLargeLetter ? <WithinBothZonesGross /> : <WithinBothZonesStandard />}
                  value="FRANKING_ZONE_AND_AUFSCHRIFT"
                  checked={form.values.letterFrankingPlacementType === 'FRANKING_ZONE_AND_AUFSCHRIFT'}
                  onChange={handleRadioChangeFrankingPlacement}
                />
              </Grid.Col>
              {form.values.letterFrankingPlacementType && (
                <Grid.Col span={12}>
                  <ImportantInstructionsStepThree
                    letterFrankingPlacementType={form.values.letterFrankingPlacementType}
                  />
                </Grid.Col>
              )}
            </Grid>
            {form.values.letterFrankingPlacementType && (
              <Grid>
                <Grid.Col span={12} style={{ marginTop: '20px' }}>
                  <h2>{t('step-three.specify.sender-placement')}</h2>
                </Grid.Col>
                <Grid.Col span={4}>
                  <RadioButtonCard
                    data-testid="radio-button-card-within-sender-zone"
                    label={t('step-three.SENDER_ZONE')}
                    image={isLargeLetter ? <WithinSenderZoneGross /> : <WithinSenderZoneStandard />}
                    value="SENDER_ZONE"
                    checked={form.values.letterSenderPlacementType === 'SENDER_ZONE'}
                    onChange={handleRadioChangeSenderPlacement}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <RadioButtonCard
                    data-testid="radio-button-card-above-recipient"
                    label={t('step-three.AUFSCHRIFT_SENDER')}
                    image={isLargeLetter ? <AboveRecipientGross /> : <AboveRecipientStandard />}
                    value="AUFSCHRIFT"
                    checked={form.values.letterSenderPlacementType === 'AUFSCHRIFT'}
                    onChange={handleRadioChangeSenderPlacement}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <RadioButtonCard
                    data-testid="radio-button-card-without-sender"
                    label={t('step-three.NONE')}
                    image={isLargeLetter ? <WithoutSenderGross /> : <WithoutSenderStandard />}
                    value="NONE"
                    checked={form.values.letterSenderPlacementType === 'NONE'}
                    onChange={handleRadioChangeSenderPlacement}
                  />
                </Grid.Col>
                {form.values.letterSenderPlacementType && (
                  <Grid.Col span={12}>
                    <ImportantInstructionsStepThree
                      letterFrankingPlacementType={`${form.values.letterSenderPlacementType}_SENDER`}
                    />
                  </Grid.Col>
                )}
              </Grid>
            )}
          </Grid.Col>
          <Grid.Col span={3}>
            <InfoBoxStepThree />
          </Grid.Col>
        </Grid>
      </Paper>
      <ButtonBar
        onNext={updateProjectForStepThree}
        onCancel={goToDashboard}
        cancelButtonLabel={t('cancel')}
        nextButtonLabel={t('next')}
        isNextButtonDisabled={isNextButtonDisabledStepThree}
      />
    </>
  );
};
