/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  createMessageWithSpecialTags,
  DHLFieldNote,
  DHLFormGroup,
  DHLHeadline,
  DHLIcon,
  DHLRadioButton,
  DHLTextOutput,
  DHLTooltip,
} from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ResponseplusConstants } from "../../../../constants/ResponseplusConstants";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";
import "../ResponseplusOrder.scss";
import "./OrderPage1ContentData.scss";

export type ResponseplusMatrixCodeSelectionProps = {
  orderStore: ResponseplusOrderStore;
}

export const ResponseplusMatrixCodeSelection = observer((
    {
      orderStore: dataStore
    }: ResponseplusMatrixCodeSelectionProps) => {
  const {resourceDataStore} = dataStore.baseStore;

  const [matrixCodeTooltipOpen, setMatrixCodeTooltipOpen] = useState(false);
  const toggleMatrixCodeTooltip = () => setMatrixCodeTooltipOpen(!matrixCodeTooltipOpen);

  const matrixCodeTextLink =
      <span className={"text-link"} onClick={() => dataStore.openMatrixCodeDialog()}>
      <DHLTextOutput
          value={resourceDataStore.getLabel("order.page1-orderData.matrixCodeTextLink")} />
    </span>;

  const matrixCodeHeadlineWithInfoIcon = <>
    <DHLIcon name={"matrixCodeInfoButton"} icon={"alert-info"} />
    <DHLTooltip target={"matrixCodeInfoButton"} placement={"right"} tooltipOpen={matrixCodeTooltipOpen} toggle={toggleMatrixCodeTooltip}>
      {createMessageWithSpecialTags(resourceDataStore.getLabel("order.page1-orderData.matrixCodeTooltip"))}
    </DHLTooltip>
  </>;

  return <DHLFormGroup>
    <DHLFormGroup className="row">
      <div className="col-12">
        <DHLHeadline type={"h6"} tooltip={matrixCodeHeadlineWithInfoIcon}>
          {resourceDataStore.getLabel("order.page1-orderData.matrixCodeHeadline")}
          {resourceDataStore.getLabel("order.requiredInfoMarker")}
        </DHLHeadline>
      </div>
    </DHLFormGroup>
    <div className="col-12">
      <DHLFormGroup className="row">
        <DHLTextOutput value={createMessageWithSpecialTags(resourceDataStore.getLabel("order.page1-orderData.matrixCodeTextPrefix") + "[nbsp]")} />
        {matrixCodeTextLink}
        <DHLTextOutput
            value={createMessageWithSpecialTags("[nbsp]" + resourceDataStore.getLabel("order.page1-orderData.matrixCodeTextSuffix"))} />
      </DHLFormGroup>
    </div>
    {dataStore.noMatrixCodeIsSelectedErrorShouldBeShown &&
        <DHLFormGroup className={"row"}>
          <div className="col-12">
            <DHLFieldNote name={"noMatrixCodeIsSelectedError"} type={"error"} classname={"responseplus-local-group-error-message"}
                          notes={resourceDataStore.getLabel("order.page1-orderData.noMatrixCodeErrorText")} />
          </div>
        </DHLFormGroup>}
    <DHLFormGroup checkboxGroup className={"form-group"}>
      <DHLRadioButton selectValue={ResponseplusConstants.nrFrankierArt.static.toString()}
                      label={resourceDataStore.getLabel("order.page1-orderData.matrixCodeStatic")}
                      required={false}
                      formField={dataStore.nrFrankierartFormField}
                      name={"matrixCodeStatic"}
                      errorMarkerOnly={true}
                      error={dataStore.noMatrixCodeIsSelectedErrorShouldBeShown ? "error" : null}
      />
      <DHLRadioButton selectValue={ResponseplusConstants.nrFrankierArt.individual.toString()}
                      label={resourceDataStore.getLabel("order.page1-orderData.matrixCodeIndividual")}
                      required={false}
                      formField={dataStore.nrFrankierartFormField}
                      name={"matrixCodeIndividual"}
                      errorMarkerOnly={true}
                      error={dataStore.noMatrixCodeIsSelectedErrorShouldBeShown ? "error" : null}
      />
    </DHLFormGroup>
  </DHLFormGroup>;

});
