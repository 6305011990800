/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  createMessageWithSpecialTags,
  DHLDateInputGroup,
  DHLFieldNote,
  DHLFormGroup,
  DHLHeadline,
  DHLIcon,
  DHLRadioButton,
  DHLSelectOne,
  DHLTextInput,
  DHLTextOutput,
  DHLTooltip,
  DialogFormContent7030,
} from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ResponseplusConstants } from "../../../../constants/ResponseplusConstants";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";
import "../ResponseplusOrder.scss";
import moment from "moment";
import "./OrderPage1ContentData.scss";
import { FrankingZoneDialog } from "./dialogs/FrankingZoneDialog";
import { MatrixCodeDialog } from "./dialogs/MatrixCodeDialog";
import { ResponseplusMatrixCodeSelection } from "./ResponseplusMatrixCodeSelection";

export type ResponseplusOrderPage1Props = {
  orderStore: ResponseplusOrderStore;
  rightContent: React.ReactElement
}

export const OrderPage1ContentData = observer((
  {
    orderStore: dataStore,
    rightContent
  }:
    ResponseplusOrderPage1Props) => {
  const {resourceDataStore} = dataStore.baseStore;

  const [sendingFormatTooltipOpen, setSendingFormatTooltipOpen] = useState(false);
  const toggleSendingFormatTooltip = () => setSendingFormatTooltipOpen(!sendingFormatTooltipOpen);

  const frankingZoneTextLink =
    <span className={"text-link"} onClick={() => {
      dataStore.openFrankingZoneDialog();
    }}>
      <DHLTextOutput
        value={resourceDataStore.getLabel("order.page1-orderData.designOfFrankingZoneTextLink")} />
    </span>;

  const sendingFormatHeadlineWithInfoIcon = <>
    <DHLIcon name={"sendingFormatInfoButton"} icon={"alert-info"} />
    <DHLTooltip target={"sendingFormatInfoButton"} placement={"right"} tooltipOpen={sendingFormatTooltipOpen} toggle={toggleSendingFormatTooltip}>
      {resourceDataStore.getLabel("order.page1-orderData.sendingFormatTooltip")}
    </DHLTooltip>
  </>;

  const shipmentContent =
    <DHLFormGroup>
      <DHLFormGroup className="row">
        <div className="col-8">
          <DHLHeadline type={"h6"} tooltip={sendingFormatHeadlineWithInfoIcon}>
            {resourceDataStore.getLabel("order.page1-orderData.shipmentFormat")}
          </DHLHeadline>
        </div>
        <div className="col-4">
          <DHLHeadline type={"h6"}>
            {resourceDataStore.getLabel("order.page1-orderData.plannedShipmentQuantity")}
          </DHLHeadline>
        </div>
      </DHLFormGroup>
      <DHLFormGroup className="row">
        <div className="col-8">
          <DHLSelectOne
            name="shipmentFormatSelectOne"
            required={true}
            placeholder={resourceDataStore.getLabel("order.page1-orderData.shipmentFormatPlaceholder")}
            data={dataStore.shipmentFormats}
            disabled={dataStore.shipmentFormats.length === 0}
            formField={dataStore.selectedShipmentFormatFormField}
          />
        </div>
        <div className="col-4">
          <DHLTextInput
            formField={dataStore.plannedShipmentQuantityFormField}
          />
        </div>
      </DHLFormGroup>
    </DHLFormGroup>;

  const dateInputConfig = {
    pickerStart: {
      formField: dataStore.plannedStartDate,
      autoFocus: false,
      minDate: moment(),
    },
    pickerEnd: {
      formField: dataStore.plannedEndDate,
      autoFocus: false,
      minDate: moment().add(1, "day"),
    },
  };

  const plannedPeriodContent =
    <DHLFormGroup>
      <DHLFormGroup className="row">
        <div className="col-12">
          <DHLHeadline type={"h6"}>
            {resourceDataStore.getLabel("order.page1-orderData.plannedPeriod")}
          </DHLHeadline>
        </div>
      </DHLFormGroup>
      <DHLFormGroup className="row">
        <div className="col-12">
          <DHLTextOutput value={resourceDataStore.getLabel("order.page1-orderData.plannedPeriodText")} />
        </div>
      </DHLFormGroup>
      <DHLFormGroup className={"row"}>
        <div className={"col-8"}>
          <DHLDateInputGroup {...dateInputConfig} />
        </div>
      </DHLFormGroup>
    </DHLFormGroup>;


  const matrixCodeDialog = (
    <MatrixCodeDialog
      show={dataStore.showMatrixCodeInfoDialog}
      onClickClose={() => dataStore.closeMatrixCodeDialog()}
      resourceDataStore={resourceDataStore} />
  );

  const frankingZoneDialog = (
    <FrankingZoneDialog
      show={dataStore.showFrankingZoneInfoDialog}
      onClickClose={() => dataStore.closeFrankingZoneDialog()}
      resourceDataStore={resourceDataStore} />
  );

  const frankingZoneContent =
    <DHLFormGroup>
      <DHLFormGroup className="row">
        <div className="col-12">
          <DHLHeadline type={"h6"}>
            {resourceDataStore.getLabel("order.page1-orderData.designOfFrankingZone")}
            {resourceDataStore.getLabel("order.requiredInfoMarker")}
          </DHLHeadline>
        </div>
      </DHLFormGroup>
      {dataStore.noFrankingZoneIsSelectedErrorShouldBeShown &&
          <DHLFormGroup className={"row"}>
            <div className="col-12">
              <DHLFieldNote
                  name={"noFrankingZoneIsSelectedError"}
                  type={"error"}
                  classname={"responseplus-local-group-error-message"}
                  notes={resourceDataStore.getLabel("order.page1-orderData.noDesignOfFrankingZoneErrorText")} />
            </div>
          </DHLFormGroup>}
      <div className="col-12">
        <DHLFormGroup className="row">
          <DHLTextOutput
            value={createMessageWithSpecialTags(
              resourceDataStore.getLabel("order.page1-orderData.designOfFrankingZoneTextPrefix") + "[nbsp]"
            )} />
          {frankingZoneTextLink}
          <DHLTextOutput
            value={createMessageWithSpecialTags("[nbsp]" + resourceDataStore.getLabel("order.page1-orderData.designOfFrankingZoneTextSuffix"))} />
        </DHLFormGroup>
      </div>
      <DHLFormGroup className="row">
        <div className="col-12">
          <DHLTextOutput value={createMessageWithSpecialTags(resourceDataStore.getLabel("order.page1-orderData.companyPayedFrankingText"))} />
        </div>
      </DHLFormGroup>
      <DHLFormGroup checkboxGroup className={"form-group"}>
        <DHLRadioButton
          selectValue={ResponseplusConstants.frankingZoneDesign.rectangleNoteWithText.toString()}
          label={resourceDataStore.getLabel("order.page1-orderData.rectangleNoteWithText")}
          required={false}
          formField={dataStore.frankingZoneDesignFormField} name={"rectangleNoteWithText"}
          errorMarkerOnly={true}
          error={dataStore.noFrankingZoneIsSelectedErrorShouldBeShown ? "error" : null} />
        <DHLRadioButton
          selectValue={ResponseplusConstants.frankingZoneDesign.customerImageWithWave.toString()}
          label={resourceDataStore.getLabel("order.page1-orderData.customerImageWithWave")}
          required={false}
          formField={dataStore.frankingZoneDesignFormField} name={"customerImageWithWave"}
          errorMarkerOnly={true}
          error={dataStore.noFrankingZoneIsSelectedErrorShouldBeShown ? "error" : null} />
        <DHLRadioButton
          selectValue={ResponseplusConstants.frankingZoneDesign.emptyZone.toString()}
          label={resourceDataStore.getLabel("order.page1-orderData.emptyZone")}
          required={false}
          formField={dataStore.frankingZoneDesignFormField} name={"emptyZone"}
          errorMarkerOnly={true}
          error={dataStore.noFrankingZoneIsSelectedErrorShouldBeShown ? "error" : null} />
      </DHLFormGroup>
      <DHLFormGroup className="row">
        <div className="col-12">
          <DHLTextOutput value={createMessageWithSpecialTags(resourceDataStore.getLabel("order.page1-orderData.customerPayedFrankingText"))} />
        </div>
      </DHLFormGroup>
      <DHLFormGroup checkboxGroup className={"form-group"}>
        <DHLRadioButton
          selectValue={ResponseplusConstants.frankingZoneDesign.rectangleNoteWithMatrix.toString()}
          label={resourceDataStore.getLabel("order.page1-orderData.rectangleNoteWithMatrix")}
          required={false}
          formField={dataStore.frankingZoneDesignFormField} name={"rectangleNoteWithMatrix"}
          errorMarkerOnly={true}
          error={dataStore.noFrankingZoneIsSelectedErrorShouldBeShown ? "error" : null} />
      </DHLFormGroup>
    </DHLFormGroup>;

  const footnote =
    <DHLFormGroup className="row">
      <div className={classNames("col-12")}>
        <DHLTextOutput
          name={"requiredInfo"} value={resourceDataStore.getLabel("order.requiredInfoText")}
          footnoteIdentifier={resourceDataStore.getLabel("order.requiredInfoMarker")} />
      </div>
    </DHLFormGroup>;

  const preamble = (<DHLHeadline type={"h5"}>{resourceDataStore.getLabel("order.page1-orderData.headline")}</DHLHeadline>);

  const leftContent = <>
    {<ResponseplusMatrixCodeSelection orderStore={dataStore} />}
    {shipmentContent}
    {plannedPeriodContent}
    {frankingZoneContent}
    {footnote}
    {matrixCodeDialog}
    {frankingZoneDialog}
  </>;

  return (
    <DialogFormContent7030
      className={"responseplus-order-page1-container"}
      preamble={preamble}
      leftContent70={leftContent}
      rightMarginal30={rightContent} />
  );
});
