export enum StandardLetter {
  Standardbrief = '102100001',
  StandardbriefBZE = '1102122',
  StandardbriefBZA = '1102130',
  StandardbriefBZL = '102100012'
}

export enum CompactLetter {
  Kompaktbrief = '102200001',
  KompaktbriefBZE = '1102222',
  KompaktbriefBZA = '1102230',
  KompaktbriefBZL = '102200014'
}

export enum CapitalLetter {
  Großbrief = '102300001',
  GroßbriefBZE = '1102322',
  GroßbriefBZA = '1102330',
  GroßbriefBZL = '102300016'
}

export enum MaxLetter {
  Maxibrief = '102400001',
  MaxibriefBZE = '1102422',
  MaxibriefBZA = '1102430',
  MaxibriefBZL = '102400015',
  MaxibriefBZLPlusBis = '102400016',
  MaxibriefBZLPlusUber = '102400017'
}

export enum PostCard {
  Postkarte = '102500001',
  PostkarteBZE = '1102522',
  PostkarteBZA = '1102530',
  PostkarteBZL = '102500012'
}

export enum LetterTypes {
  Standard = 'Standardbrief',
  Compact = 'Kompaktbrief',
  Large = 'Großbrief',
  Maxi = 'Maxibrief',
  Postcard = 'Postkarte'
}

export enum LetterTypesWithNewProduct {
  Standard = 'Standardbrief',
  Compact = 'Kompaktbrief',
  Large = 'Großbrief',
  Maxi = 'Maxibrief',
  Postcard = 'Postkarte',
  StandardBZL = 'Standardbrief BZL GK',
  CompactBZL = 'Kompaktbrief BZL GK',
  LargeBZL = 'Großbrief BZL GK',
  MaxiBZL = 'Maxibrief BZL GK',
  MaxiBZLPlusBis = 'Maxibrief plus bis 1.000g BZL GK',
  MaxiBZLPlusUber = 'Maxibrief plus über 1.000g BZL GK',
  PostcardBZL = 'Postkarte BZL GK'
}

export enum LetterTypesList {
  Standard = 'Standardbrief',
  Compact = 'Kompaktbrief',
  Large = 'Großbrief',
  Maxi = 'Maxibrief',
  Postcard = 'Postkarte',
  StandardBZL = 'Standardbrief mit BZL GK',
  CompactBZL = 'Kompaktbrief mit BZL GK',
  LargeBZL = 'Großbrief mit BZL GK',
  MaxiBZL = 'Maxibrief mit BZL GK',
  PostcardBZL = 'Postkarte mit BZL GK'
}
