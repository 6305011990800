import React, { useEffect, useState, VFC } from 'react';
import iconPayer from 'assets/icon-payer.svg';
import classNames from 'classnames';
import { Button } from 'common/components/Button';
import orderClasses from 'common/styles/order.module.css';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn';
import { useTranslation } from 'react-i18next';
import { Payer } from '../../components/Payer/Payer';
import { useFormContext, useWatch } from 'react-hook-form';
import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import { CustomTextInput } from '../../../../common/components/CustomTextInput';
import { FormRow } from '../../../../../common/components/FormRow';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { BillingOrPostcardNumberInput } from 'order/orderChange/common/components/BillingOrPostcardNumberInput/BillingOrPostcardNumberInput';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { LetterOrderCreate, validateLetterOrderCreate } from '../../schemea/LetterSchema';
import { Checkbox } from 'common/components/Checkbox/Checkbox';
import { PostageType } from 'order/common/dtos/PostageType';
import { dvDiscountFn } from 'order/common/utils/dvDiscountFn';

export interface LetterStep3Props {
  edit?: boolean;
}

export const LetterStep3: VFC<LetterStep3Props> = () => {
  const { previousStep, nextStep } = useOrderStep();
  const { t } = useTranslation('orderCreate');
  const { order } = useOrderContext();
  const { isDirty } = useOrderContext();
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step3Form' });
  const { t: oc4 } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const { t: postageTypeTranslate } = useTranslation('catalogValues', { keyPrefix: 'postageType.display' });
  const { getValues, setError, control } = useFormContext<LetterOrderCreate>();
  const [payer, postageMachineCode] = useWatch({
    control,
    name: ['payer', 'postageMachineCode']
  });
  const isDVBrief = order?.orderCategory === OrderCategory.DV_BRIEF;

  const dvDiscount = dvDiscountFn(order?.postage?.type, order?.dependentProducts);

  return (
    <>
      <div className={orderClasses.formContent}>
        <div className={orderClasses.rowWithSidebar}>
          <main>
            <OrderStepHeadline icon={iconPayer} alt="Payer Icon">
              {oc('paymentInformation')}
            </OrderStepHeadline>
            {!isDVBrief ? (
              <Payer />
            ) : (
              <>
                <DHLHeadline type="h6">{oc('payer')}</DHLHeadline>
                <BillingOrPostcardNumberInput name={'payer'} disabled={true} readOnly={true} />
              </>
            )}
            <DHLHeadline type="h6">{oc('franking')}</DHLHeadline>
            <FormRow mode="two-col">
              <CustomTextInput
                type={'text'}
                name={'typeOfFranking'}
                label={oc('typeOfFranking')}
                testid={'typeOfFranking'}
                defaultValue={
                  order?.postage?.type
                    ? postageTypeTranslate(order?.postage?.type)
                    : isDVBrief
                    ? postageTypeTranslate('DV')
                    : postageTypeTranslate('AFM')
                }
                disabled={true}
              />
              {dvDiscount && (
                <Checkbox
                  label={t('dvClearance')}
                  checked={dvDiscount}
                  datatestid="DV-Freimachung-discount"
                  name={`dvClearance`}
                  disabled={true}
                  verticalCenter={true}
                />
              )}
            </FormRow>
            <FormRow mode="two-col">
              {!isDVBrief ? (
                <CustomTextInput
                  type={'text'}
                  name={'postageMachineCode'}
                  label={oc4('machineIdentifier')}
                  testid={'postageMachineCode'}
                  defaultValue={postageMachineCode}
                  disabled={true}
                />
              ) : (
                <CustomTextInput
                  type={'text'}
                  name={'postingReceiptNumber'}
                  label={oc('postingReceiptNumber')}
                  testid={'postingReceiptNumber'}
                  defaultValue={order.postage?.paymentClearingNumber}
                  disabled={true}
                />
              )}
            </FormRow>
          </main>
          <MarginalColumn orderCategory={order?.orderCategory} />
        </div>
      </div>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            dataTestId={'frankitStep3PrevBtn'}
            onClick={() => previousStep()}
            type="button"
            label={t('buttons.back')}
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          dataTestId="btnSubmit"
          variant="primary"
          onClick={async () => {
            const areDependentFieldsValid = validateLetterOrderCreate(getValues(), setError, 3, isDVBrief);
            if (areDependentFieldsValid) nextStep();
          }}
          type="button"
          label={t('buttons.continue')}
        />
      </footer>
    </>
  );
};
