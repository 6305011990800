/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLButton, DHLFormGroup, DHLModal, DHLTextInput, DialogFormContent, FormField } from "@gkuis/gkp-base-widgets";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ResponseplusOrderDetailStore } from "../../../stores/ResponseplusOrderDetailStore";
import { ResponseplusOrderStore } from "../../../stores/ResponseplusOrderStore";


export type ContactPersonDialogProps = {
  detailStore: ResponseplusOrderStore | ResponseplusOrderDetailStore;
  showContactDialog: boolean;
  onClickClose: () => void;
  onSubmit: () => void;
  editingSecondContact: boolean;
  editingMode: "not selected" | "addContact" | "editContact";
}


export const ContactPersonDialog = observer(({
                                               detailStore,
                                               showContactDialog,
                                               onSubmit,
                                               onClickClose,
                                               editingSecondContact,
                                               editingMode
                                             }: ContactPersonDialogProps) => {
  const {resourceDataStore, validationRuleDataStore} = detailStore.baseStore;

  if (detailStore.isLoading) {
    return null;
  }

  const nameFormField =
      new FormField("order.page2-orderDetails.namePlaceHolder", editingSecondContact ? detailStore.baseStore.secondNameFormField.value
              : detailStore.baseStore.nameFormField.value,
          resourceDataStore, validationRuleDataStore, undefined, "firstName");
  const surNameFormField = new FormField("order.page2-orderDetails.sureNamePlaceHolder", editingSecondContact
          ? detailStore.baseStore.secondSureNameFormField.value : detailStore.baseStore.surNameFormField.value,
      resourceDataStore, validationRuleDataStore, undefined, "lastName");
  const emailFormField = new FormField("order.page2-orderDetails.emailPlaceHolder", editingSecondContact
          ? detailStore.baseStore.secondEmailFormField.value : detailStore.baseStore.emailFormField.value,
      resourceDataStore, validationRuleDataStore, undefined, "email");
  const phoneFormField = new FormField("order.page2-orderDetails.phonePlaceHolder", editingSecondContact
          ? detailStore.baseStore.secondPhoneFormField.value : detailStore.baseStore.phoneFormField.value,
      resourceDataStore, validationRuleDataStore, undefined, "phone");
  const inputFields = [nameFormField, surNameFormField, emailFormField, phoneFormField];

  const dialogContent =
      <DialogFormContent>
        <DHLFormGroup className="row">
          <div className="col-6">
            <DHLTextInput formField={nameFormField} />
          </div>
          <div className="col-6">
            <DHLTextInput formField={surNameFormField} />
          </div>
        </DHLFormGroup>
        <DHLFormGroup className="row">
          <DHLTextInput className="col-12" formField={emailFormField} />
        </DHLFormGroup>
        <DHLFormGroup className="row">
          <DHLTextInput className="col-12" formField={phoneFormField} />
        </DHLFormGroup>

      </DialogFormContent>;

  const dialogActionBar = <>
    <DHLButton
        type="default"
        name="cancel"
        label={resourceDataStore.getButton("orderDetails.cancel")}
        disabled={detailStore.baseStore.runningContactUpdate}
        onClick={() => {
          onClickClose();
          runInAction(() => inputFields.forEach(f => f.value = ""));
        }}
    />
    <DHLButton
        type="primary"
        name="submit"
        label={resourceDataStore.getButton("orderDetails.save")}
        onClick={() => runInAction(() => {
          if (!detailStore.baseStore.contactHasLocalFieldError(inputFields)) {
            if (editingSecondContact) {
              detailStore.baseStore.secondNameFormField.value = nameFormField.value;
              detailStore.baseStore.secondSureNameFormField.value = surNameFormField.value;
              detailStore.baseStore.secondEmailFormField.value = emailFormField.value.trim();
              detailStore.baseStore.secondPhoneFormField.value = phoneFormField.value;
            } else {
              detailStore.baseStore.nameFormField.value = nameFormField.value;
              detailStore.baseStore.surNameFormField.value = surNameFormField.value;
              detailStore.baseStore.emailFormField.value = emailFormField.value.trim();
              detailStore.baseStore.phoneFormField.value = phoneFormField.value;
            }
            onSubmit();
            runInAction(() => inputFields.forEach(f => f.value = ""));
          }
        })}
        loadingState={detailStore.baseStore.runningContactUpdate}
        disabled={detailStore.baseStore.runningContactUpdate}
    />
  </>;
  return (
      <DHLModal
          name="contactDialog"
          className={"addContact"}
          show={showContactDialog}
          title={resourceDataStore.getLabel(editingMode)}
          onClickClose={onClickClose}
          actionbar={dialogActionBar}
          content={dialogContent}
      />
  );
});